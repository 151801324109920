import React, { useEffect, useState, useRef, useCallback } from "react";
import LikeNotific from "../../assets/images/LikeIcon.png";
import CartIcon from "../../assets/images/cartBag.png";
import SearchIcon from "../../assets/images/searchWeb.svg";
import CrossIcon from "../../assets/images/crossIcons.svg";
import SignupBackImg from "../../assets/icons/SignUpBackIcon";
import UserIcon from "../../assets/icons/UserIcon";
import AppoinmentIcon from "../../assets/icons/AppoinmentIcon";
import PurchaseChart from "../../assets/icons/CartIcon";
import ChangePasswordIcon from "../../assets/icons/ChangePasswordIcon";
import "./header.scss";
import Login from "../../auth/Login";
import Register from "../../auth/Register";
import { Link } from "react-router-dom";
import EmailVerify from "../../auth/EmailVerify";
import ForgetPassword from "../../auth/ForgetPassword";
import ResetPassword from "../../auth/ResetPassword";
import SetNewPassword from "../../auth/SetNewPassword";
import { _Api } from "../../../common/api";
import { useTranslation } from "react-i18next";
import RightSideArrow from "../../assets/icons/RightSideArrow";
import HeaderToggleIcon from "../../assets/images/headerToggle.png";
import SearchPopup from "../../components/SearchPopup/SearchPopup";
import { useSelector } from 'react-redux';
const Header = (props) => {
  const dropdownRef = useRef(null);
  const {
    websiteinfo,
    setOpenmodal,
    openmodal,
    SetNewpassworddata,
    newpassworddata,
    setForgetemail,
    forgetemail,
    setEmailVerifydata,
    emailverifydata,
  } = props;
  const { i18n, t } = useTranslation();
  let cartdata = JSON.parse(localStorage.getItem("CartItems"));
  let localmywishlist = localStorage.getItem('mywishlist') ? JSON.parse(localStorage.getItem('mywishlist')) : [];
  let localmywishlistremove = localStorage.getItem('mywishlistremove') ? JSON.parse(localStorage.getItem('mywishlistremove')) : [];
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const dataFetched = useRef();
  const [LanguageList, setLanguageData] = useState(null);
  const [headerdata, setHeaderdata] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const accessToken = localStorage.getItem("ecomaccesstoken");
  const [CurrentLan_flg, setLanguageSelectData] = useState({ Lan_photo: 'US.png', lang: "english" })
  const [activeMenu, setActiveMenu] = useState('');
  const [activeDropdowns, setActiveDropdowns] = useState([false]);
  const [searchlist, setSearchlist] = useState([]);
  const [wishlistcounts, setWishlistcounts] = useState();
  const [menubar, setMenubar] = useState(false);
  const [checkApiCall, setCheckApiCall] = useState(true);
  const [languageDropdown, setLanguageDrodown] = useState(false);
  const iswishlistobjectheader = useSelector((state) => state.app.iswishlistobjectheader);

  let langdata = JSON.parse(localStorage.getItem('UserLangecom'))
  const toggleDropdown = (index) => {
    if (activeDropdowns !== index) {
      if (activeDropdowns[index]) {
        setActiveDropdowns((prev) => {
          return [false]
        })
      } else {
        setActiveDropdowns((prev) => {
          const newDropdowns = [];
          newDropdowns[index] = !newDropdowns[index];
          return newDropdowns;
        });
      }
    } else {
      setActiveDropdowns((prev) => {
        return [false]
      })
    }
  };

  const alllogindata = (keyName, value) => {
    setOpenmodal((prevState) => ({
      ...prevState,
      [keyName]: value,
    }));
  };
  const headerSearchToggle = () => {
    setIsSearchOpen(!isSearchOpen);
  };
  const Headerstaics = async () => {
    try {
      let postdata = {
        type: "menu",
      };
      let res = await _Api(postdata, `redis/menu`);
      if (res?.statusCode) {
        setHeaderdata(res?.data?.menu);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const languagedata = async () => {
    setCheckApiCall(false)
    try {
      let postdata = {};
      let res = await _Api(postdata, `language`);
      if (res?.statusCode === 200) {
        setLanguageData(res?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const rtlView = useCallback((type, l) => {
    var jsonData = JSON.stringify(l);
    localStorage.setItem("UserLangecom", jsonData);
    setLanguageSelectData(l)
    let bodyid = document.querySelector("#body_id");
    if (type === "rtl") {
      bodyid.classList.add("rtl");
    } else {
      bodyid.classList.remove("rtl");
    }
  }, [setLanguageSelectData]);
  useEffect(() => {
    if (langdata) {
      setLanguageSelectData(langdata)
    } else {
      setLanguageSelectData({ Lan_photo: 'US.png', lang: "english" })
    }

    if (langdata?.Lan_photo === 'SA.png') {
      rtlView('rtl', langdata)
    }
    // eslint-disable-next-line
  }, [checkApiCall, setLanguageSelectData, rtlView])
  const searchdropdonwn = async (event) => {
    try {
      let postdata = {
        search: event,
      };
      let res = await _Api(postdata, `product/search`);
      if (res?.statusCode === 200) {
        setSearchlist(res?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const wishlistcount = async () => {
    try {
      let postdata = {
        "wishlistproduct": localmywishlist,
        "wishlistremove": localmywishlistremove,
      };
      let res = await _Api(postdata, `product/wishlistCount`);
      if (res?.statusCode === 200) {
        setWishlistcounts(res?.count);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    Headerstaics();
    languagedata();
  }, []);
  useEffect(() => {
    wishlistcount();
    // eslint-disable-next-line
  }, [accessToken]);
  useEffect(() => {
    setWishlistcounts(iswishlistobjectheader?.length)
  }, [iswishlistobjectheader])
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveMenu("");
        setActiveDropdowns([false])
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  const handleDropdownClick = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      <div className="main-WebHeader template_two">
        <nav className="navbar navbar-expand-lg header-inner border-bottom border border-success">
          <div className="container-fluid main-WebHeader-container">
            <div className="navbar-brand main-WebHeader-logo">
              <Link to="/">
                <div className="navbar-brand main-WebHeader-logo-image">
                  <img src={websiteinfo?.websiteInfo?.logo} alt="" />
                </div>
              </Link>
            </div>
            <div className="rightSideContentBox responsMobShow">
              <div>
                <button
                  className="com-transBtn rightSideContentBox-btnBox"
                  type="submit"
                  onClick={headerSearchToggle}
                >
                  <img
                    className="rightSideContentBox-btnBox-imgShow"
                    src={SearchIcon}
                    alt=""
                    cons="true"
                  />
                </button>
                {isSearchOpen && (
                  <>
                    <SearchPopup isSearchOpen={isSearchOpen} searchlist={searchlist} headerSearchToggle={headerSearchToggle} setIsSearchOpen={setIsSearchOpen} />
                  </>
                )}
              </div>

              <Link
                className="com-transBtn rightSideContentBox-btnBox"
                type="submit"
                to="/wishlist"
              >
                <img
                  src={LikeNotific}
                  className="rightSideContentBox-btnBox-imgShow"
                  alt=""
                />
                {wishlistcounts ?
                  <span className="product-count">
                    {wishlistcounts}
                  </span> : ""}
              </Link>

              <Link
                to="/shoppingcart"
                className="com-transBtn rightSideContentBox-btnBox"
                type="submit"
                data-bs-modal="AddToCartModal"
                onClick={() => setOpenmodal(true)}
              >
                <img
                  src={CartIcon}
                  className="rightSideContentBox-btnBox-imgShow"
                  alt=""
                />
                {cartdata?.length ?
                  <span className="product-count">
                    {cartdata?.length ? cartdata?.length : 0}
                  </span> : ""}
              </Link>
             
                <button
                  className="navbar-toggler headerNavToggleBtn mt-1"
                  type="button"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded={!menubar}
                  aria-label="Toggle navigation"
                  onClick={() => {setMenubar(!menubar); setLanguageDrodown(false)}}
                >
                  <img src={menubar ? CrossIcon : HeaderToggleIcon} alt="" />
                </button>
               
            </div>


            <div
              className={`collapse ${menubar ? 'show' : ""} navbar-collapse main-WebHeader-content justify-content-between`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mb-2 mb-lg-0 main-WebHeader-content-ListBox" ref={dropdownRef} onClick={handleDropdownClick}>
                {headerdata?.length ?
                  headerdata.map((result, key) => {
                    return (
                      <React.Fragment key={key}>
                        <li className="nav-item dropdown ">
                          <div
                            className={`nav-link dropdown-toggle d-flex position-relative main-WebHeader-content-ListBox-listSingle showArrowDropdown ${activeMenu === result.name ? "active"
                              : ''}`}

                            onClick={() =>
                              activeMenu === result.name ? setActiveMenu('') : setActiveMenu(result.name)
                            }
                            data-toggle="dropdown"
                            aria-haspopup="true"
                          >
                            <p className="m-0">{t(`${result.name}`)}</p>
                            {
                              activeMenu === result.name ?
                                <svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                </svg>
                                : <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#000"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 18L15 12L9 6"
                                    stroke="#000"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                            }

                          </div>
                        </li>
                        {activeMenu === result.name && (
                          <div className="main-WebHeader-content-dropdown dropdown-item">
                            <div className="main-WebHeader-content-dropdown-inner d-flex">
                              {result?.subcategories?.length
                                ? result?.subcategories?.map((element, index) => {
                                  return (
                                    <li key={index} className="w-100 ">
                                      {element.subcategories?.length ? (
                                        <div
                                          className="d-flex justify-content-between"
                                          onClick={() => toggleDropdown(index)}
                                        >
                                          <b className="cursor-pointer main-WebHeader-content-dropdown-inner-title">
                                            {element.name}
                                          </b>
                                          {element.subcategories?.length > 0 && (

                                            <span className="cursor-pointer text-end">
                                              {activeDropdowns[index] ? <svg xmlns="http://www.w3.org/2000/svg" width='24' height='24' fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                              </svg> : <span><RightSideArrow color={"#000000"} /> </span>}
                                            </span>
                                          )}
                                        </div>
                                      ) : (
                                        <Link
                                          to={`/${activeMenu}/${element.slug}`}
                                          className="text-decoration-none"
                                        >
                                          <div
                                            className="d-flex"
                                            onClick={() => { setMenubar(false); setActiveMenu(""); setActiveDropdowns([false]) }}
                                            data-bs-toggle="collapse"
                                            data-bs-target="#navbarSupportedContent"
                                          >
                                            <b className="cursor-pointer main-WebHeader-content-dropdown-inner-title">
                                              {element.name}
                                            </b>
                                          </div>
                                        </Link>
                                      )}
                                      {activeDropdowns[index] && (
                                        <ul className="d-flex flex-md-column subDropdownUlBox">
                                          {element?.subcategories?.length
                                            ? element.subcategories.map(
                                              (value, count) => (
                                                <Link
                                                  className="text-decoration-none main-WebHeader-content-dropdown-inner-title-list subDropdownUlBox-sublist"
                                                  to={`/${activeMenu}/${value.slug}`}
                                                  key={count}
                                                >
                                                  <li
                                                    style={{
                                                      listStyle: "none",
                                                    }}
                                                    key={count}
                                                    className={`${index === 0
                                                      ? "main-WebHeader-content-dropdown-inner-title-list p-0"
                                                      : " p-0"
                                                      }`}
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#navbarSupportedContent"
                                                    onClick={() => { setMenubar(false); setActiveMenu(""); setActiveDropdowns([false]) }}
                                                  >
                                                    {value.name}
                                                  </li>
                                                </Link>
                                              )
                                            )
                                            : ""}
                                        </ul>
                                      )}
                                    </li>
                                  )

                                })
                                : ""}
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )
                  }) : ""}
                <Link
                  className="text-decoration-none nav-item"
                  to="/appointment"

                >
                  <li
                    className={`main-WebHeader-content-ListBox-listSingle  dropdown-toggle${activeMenu === "Appointment" && "active"
                      }`}
                    onClick={() => { setActiveMenu("Appointment"); setMenubar(false) }}
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                  >
                    {t("Appointment")}
                  </li>
                </Link>
                {accessToken ? (
                  <Link
                    className="text-decoration-none nav-item account-link"
                    to={"/account"}
                  >
                    <li
                      className={`main-WebHeader-content-ListBox-listSingle  dropdown-toggle${activeMenu === "Appointment" && "active"
                        }`}
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      onClick={() => setMenubar(false)}
                    >
                      {t("Account")}
                    </li>
                  </Link>
                ) : (
                  <div
                    className="nav-item account-link"
                    onClick={() => alllogindata("showLoginModel", true)}
                  >
                    <li className="main-WebHeader-content-ListBox-listSingle  dropdown-toggle" data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      onClick={() => setMenubar(false)}
                    >
                      {t("Sign In")}
                    </li>
                  </div>
                )}
              </ul>
              <div className="rightSideContentBox">
                <div className="searchBarMain">
                  {!isSearchOpen && (
                    <button
                      className="com-transBtn rightSideContentBox-btnBox"
                      type="submit"
                      onClick={headerSearchToggle}
                    >
                      <img
                        className="rightSideContentBox-btnBox-imgShow"
                        src={SearchIcon}
                        alt=""
                        cons="true"
                      />
                    </button>
                  )}
                  {isSearchOpen && (
                    <div className="rightSideContentBox-searchBoxshow">
                      <span className="rightSideContentBox-searchBoxshow-ImgFirstBox">
                        <img
                          className="rightSideContentBox-searchBoxshow-ImgFirstBox-firstImg"
                          src={SearchIcon}
                          alt=""
                        />
                      </span>
                      <div className="dropdown w-100">
                        <input
                          className="rightSideContentBox-searchBoxshow-inputbox dropdown-toggle"
                          data-bs-toggle="dropdown"
                          placeholder="Search products..."
                          onChange={(e) => searchdropdonwn(e.target.value)}
                        />
                        <ul
                          className="dropdown-menu rightSideContentBox-searchBoxshow-listBox"
                        >
                          {searchlist?.length
                            ? searchlist.map((result, key) => {
                              return (
                                <Link
                                  className="text-decoration-none text-secondary"
                                  to={`product/${result?.Slug}`}
                                  state={{ id: result?._id }}
                                  key={key}
                                >
                                  <li className="dropdown-item  rightSideContentBox-searchBoxshow-listBox-singleList">
                                    <span className="rightSideContentBox-searchBoxshow-listBox-singleList-spanBox">
                                      {" "}
                                      <img
                                        className=""
                                        width={30}
                                        height={30}
                                        src={result?.main_image}
                                        alt=""
                                      />
                                    </span>
                                    <div>
                                      <p
                                        style={{ fontSize: "14px" }}
                                        className="p-0 m-0"
                                      >
                                        {result?.name}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: "10px",
                                          fontWeight: "bold",
                                          color: "#5CC7C0",
                                        }}
                                        className="p-0 m-0"
                                      >
                                        {result?.item_name ?? "Ring"} ||{" "}
                                        {result?.SKU}{" "}
                                      </p>
                                    </div>
                                  </li>
                                </Link>
                              );
                            })
                            : ""}
                        </ul>
                      </div>
                      <span
                        className="rightSideContentBox-searchBoxshow-ImgSecondBox"
                        onClick={headerSearchToggle}
                      >
                        <img
                          className="rightSideContentBox-searchBoxshow-ImgSecondBox-secondImg"
                          src={CrossIcon}
                          alt=""
                        />
                      </span>
                    </div>
                  )}
                </div>
                {accessToken ? (
                  <div className="dropdown rightSideContentBox-accountDropBox userAccountBoxMain">
                    <button
                      className="com-transBtn rightSideContentBox-btnBox dropdown-toggle"
                      id="headeraccountdropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        <UserIcon />
                      </span>
                    </button>
                    <ul
                      className="dropdown-menu rightSideContentBox-accountDropBox-listBox"
                      aria-labelledby="headeraccountdropdown"
                    >
                      {accessToken ? (
                        <li>
                          <Link
                            className="dropdown-item rightSideContentBox-accountDropBox-listBox-SingleList"
                            to="/account"
                          >
                            <span className="rightSideContentBox-accountDropBox-listBox-SingleList-imgBox">
                              <span>
                                <UserIcon />
                              </span>
                            </span>
                            {t("My Account")}
                          </Link>
                        </li>
                      ) : (
                        <Link
                          className="dropdown-item rightSideContentBox-accountDropBox-listBox-SingleList"
                          to="#"
                        >
                          <span className="rightSideContentBox-accountDropBox-listBox-SingleList-imgBox">
                            <span>
                              <UserIcon />
                            </span>
                          </span>
                          {t("My Account")}
                        </Link>
                      )}

                      <li>
                        <Link
                          className="dropdown-item rightSideContentBox-accountDropBox-listBox-SingleList"
                          to="/account/purchasehistory"
                        >
                          <span className="rightSideContentBox-accountDropBox-listBox-SingleList-imgBox">
                            <span>
                              <PurchaseChart />
                            </span>
                          </span>
                          {t("Purchase History")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item rightSideContentBox-accountDropBox-listBox-SingleList"
                          to="/account/appointmentdata"
                        >
                          <span className="rightSideContentBox-accountDropBox-listBox-SingleList-imgBox">
                            <span>
                              <AppoinmentIcon />
                            </span>
                          </span>
                          {t("Appointment")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item rightSideContentBox-accountDropBox-listBox-SingleList"
                          to="/account/changepassword"
                        >
                          <span className="rightSideContentBox-accountDropBox-listBox-SingleList-imgBox">
                            <span>
                              <ChangePasswordIcon />
                            </span>
                          </span>
                          {t("Change Password")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/logout"
                          className="dropdown-item rightSideContentBox-accountDropBox-listBox-SingleList p-0"
                        >
                          <div className="rightSideContentBox-accountDropBox-listBox-SingleList-SingUpBtn ">
                            <span className="rightSideContentBox-accountDropBox-listBox-SingleList-imgBox">
                              <span>
                                <SignupBackImg />
                              </span>
                            </span>
                            {t("Sign Out")}
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <button
                    className="com-transBtn rightSideContentBox-btnBox user-profile-button"
                    onClick={() => alllogindata("showLoginModel", true)}
                  >
                    <span>
                      <UserIcon />
                    </span>
                  </button>
                )}

                <Link
                  className="com-transBtn rightSideContentBox-btnBox wishlistTabBtnShow mt-1"
                  type="submit"
                  to="/wishlist"
                >
                  <img
                    src={LikeNotific}
                    className="rightSideContentBox-btnBox-imgShow"
                    alt=""
                  />
                  {wishlistcounts ?
                    <span className="product-count">
                      {wishlistcounts ? wishlistcounts : 0}
                    </span> : ""}

                </Link>

                <Link
                  to="/shoppingcart"
                  className="com-transBtn rightSideContentBox-btnBox cartTabIconhide mt-1"
                  type="submit"
                >
                  <img
                    src={CartIcon}
                    className="rightSideContentBox-btnBox-imgShow"
                    alt=""
                  />
                  {cartdata?.length ?
                    <span className="product-count">
                      {cartdata?.length ? cartdata?.length : 0}
                    </span> : ""}
                </Link>
                <li className="rightSideContentBox-languagedropDown dropdown rounded-0  shadow-none list-unstyled">
                  <button
                    className="dropdown-toggle border-0 bg-transparent rightSideContentBox-languagedropDown-mainLangToggle showArrowDropdown mt-2"
                    type="button"
                    id="languagedropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded={!languageDropdown}
                    onClick={()=>setLanguageDrodown(!languageDropdown)}>
                    <div className="d-flex gap-2">
                      <img
                        style={{
                          width: "24px",
                          height: "24px"
                        }}
                        className="rounded-0 shadow-lg"
                        src={base_assets + 'images/flags/' + CurrentLan_flg?.Lan_photo}
                        alt="icon"
                      />
                      <p className="language-showSelctLang">{CurrentLan_flg?.lang}</p>
                    </div>
                    <div className={languageDropdown ? "rotateImg" : ""}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#000"
                      xmlns="http://www.w3.org/2000/svg"
                      
                    >
                      <path
                        d="M9 18L15 12L9 6"
                        stroke="#000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    </div>
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-end rightSideContentBox-languagedropDown-langListBox"
                    aria-labelledby="languagedropdown"
                  >
                    {LanguageList?.map((LanguageCode, key) => (
                      <li key={key}>
                        {LanguageCode.Lan_value === "arabic" ? (
                          <Link
                            className="dropdown-item rightSideContentBox-languagedropDown-langListBox-langSingleList"
                            onClick={() => {
                              i18n.changeLanguage(LanguageCode.Lan_value);
                              rtlView("rtl", {
                                Lan_photo: LanguageCode?.Lan_photo,
                                lang: LanguageCode?.Lan_value
                              });
                              setMenubar(false)
                            }}
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                          >
                            <img
                              src={
                                base_assets +
                                "images/flags/" +
                                LanguageCode.Lan_photo
                              }
                              alt=""
                            />{" "}
                            <span>{LanguageCode.Lan_text}</span>
                          </Link>
                        ) : (
                          <Link
                            className="dropdown-item rightSideContentBox-languagedropDown-langListBox-langSingleList"
                            onClick={() => {
                              i18n.changeLanguage(LanguageCode.Lan_value);
                              rtlView("ltr",
                                {
                                  Lan_photo: LanguageCode?.Lan_photo,
                                  lang: LanguageCode?.Lan_value
                                });
                              setMenubar(false)
                            }}
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                          >
                            <img
                              style={{
                                width: "25px",
                                height: "100%",
                              }}
                              src={
                                base_assets +
                                "images/flags/" +
                                LanguageCode.Lan_photo
                              }
                              alt=""
                            />{" "}
                            <span>{LanguageCode.Lan_text}</span>
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              </div>

            </div>
          </div>
        </nav>
      </div>
      {openmodal?.showLoginModel ? (
        <Login
          setOpenmodal={setOpenmodal}
          setEmailVerifydata={setEmailVerifydata}
        />
      ) : openmodal?.showRegisterModel ? (
        <Register
          setOpenmodal={setOpenmodal}
          setEmailVerifydata={setEmailVerifydata}
        />
      ) : openmodal?.showemailvarifymodal ? (
        <EmailVerify
          setOpenmodal={setOpenmodal}
          emailverifydata={emailverifydata}
        />
      ) : openmodal?.forgetpassword ? (
        <ForgetPassword
          setOpenmodal={setOpenmodal}
          setForgetemail={setForgetemail}
        />
      ) : openmodal?.resetpassword ? (
        <ResetPassword
          setOpenmodal={setOpenmodal}
          forgetemail={forgetemail}
          SetNewpassworddata={SetNewpassworddata}
        />
      ) : openmodal?.newpassword ? (
        <SetNewPassword
          setOpenmodal={setOpenmodal}
          newpassworddata={newpassworddata}
        />
      ) : null}
    </>
  );
};

export default Header;
