import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { _Api } from "../api/_call";
import { useTranslation } from 'react-i18next';
import { loading, Updateloading } from "../common/helpers/toastify";
const Zonedetails = (props) => {
    const { locationid } = props
    const { t } = useTranslation();
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const [Editcash, setEditcash] = useState(true)
    const [deleteid, setDeleteid] = useState([])
    const [createzone, setCrateZone] = useState([{
        "type": "",
        "zone": "",
        "zone_logo": "",
        "_id": ""
    }])
    const onImageChange = (e, index) => {
        const [file] = e.target.files;
        const reader = new FileReader(file);
        reader.onloadend = async () => {
            try {
                const postdata = {
                    image: reader.result,
                    name: "profile",
                };
                let resfront = await _Api(postdata, `api/v1/APP/customer/uploadimage`);
                if (resfront.code === 200) {
                    let data = [...createzone]
                    data[index].zone_logo = resfront?.data?.images?.url
                    setCrateZone(data)
                }
            } catch (err) {
                console.log(err, "err");
            }
        };
        if (file) {
            reader?.readAsDataURL(file);
        }
    };
    const zoneTypeOption = [
        {
            value: "cabinet",
            label: "cabinet",
            icon: <img src={base_assets + "images/admin/icons/cabinet.png"} alt="" />
        },
        {
            value: "Window",
            label: "Window",
            icon: <img src={base_assets + "images/admin/icons/cabinet.png"} alt="" />
        },
        {
            value: "Vitrine",
            label: "Vitrine",
            icon: <img src={base_assets + "images/admin/icons/cabinet.png"} alt="" />
        },
        {
            value: "Shelf",
            label: "Shelf",
            icon: <img src={base_assets + "images/admin/icons/cabinet.png"} alt="" />
        }

    ];
    const addEventList = () => {
        let newValues = [...createzone];
        newValues.push({
            "type": "",
            "zone": "",
            "zone_logo": "",
            "_id": ""
        });
        setCrateZone(newValues);
    };
    const handleRemoveElement = (indexToRemove, ids) => {
        let newValues = [...createzone];
        let deletezone = [...deleteid]
        let updatedArray = newValues.filter((_, index) => index !== indexToRemove);
        setCrateZone(updatedArray);
        if (ids) {
            deletezone.push(ids)
            setDeleteid(deletezone)
        }

    };
    const handleSelectChange = (zone_logo, zone, type, index, id) => {
        const newValues = [...createzone];
        newValues[index] = { type: type, zone: zone, zone_logo: zone_logo, _id: id };
        setCrateZone(newValues);
    };
    const updatezone = async () => {
        let finaldata = [...createzone]
        let data = []
        finaldata.forEach((result, key) => {
            data.push({
                "zone": result?.zone ? result?.zone : "",
                "type": result?.type?.value ? result?.type?.value : "",
                "zone_logo": result?.zone_logo ? result?.zone_logo : "",
                "_id": result?._id ? result?._id : ""
            })
        })
        let GetloadingID = loading();
        try {
            let postdata = {
                data: data,
                "location_id": locationid ? locationid : "",
                deletedZoneID: deleteid ? deleteid : []
            }
            let res = await _Api(postdata, `api/v1/App/location/CreateZone`)
            if (res?.code === 200) {
                setEditcash(false)
                Updateloading(t, GetloadingID, (res?.message));
            } else {
                Updateloading(t, GetloadingID, (res?.errors), 'error');
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const Zonedetaillist = useCallback(async () => {
        try {
            const postdata = {
                "location_id": locationid
            }
            let res = await _Api(postdata, `api/v1/App/location/getZoneList`)
            if (res?.code === 200) {
                let data = [...res?.data]
                zoneTypeOption.forEach((item, key) => {
                    data.forEach((element, key) => {
                        if (item?.value === element?.type) {
                            element.type = item
                        }
                    })
                });
                setCrateZone(data)
            }
        }
        catch (err) {
            console.log(err, "err")
        }
        // eslint-disable-next-line
    }, [locationid])

    useEffect(() => {
        if (locationid) {
            setEditcash(false)
            Zonedetaillist()
        }
    }, [Zonedetaillist, locationid])
    return (
        <>
            <div className="location-detail">
                <div className="bg-white rounded-2 com-card mb-4">
                    <div className="main-content-wrapper-body-top-bar d-flex align-items-center justify-content-between">
                        <div className="main-content-wrapper-body-top-bar-left">
                            <h1 className="main-content-wrapper-body-top-bar-left-heading fw-semibold mb-0">
                                <span className="">{t("Zone")}</span></h1>
                        </div>
                        <div className="main-content-wrapper-body-top-bar-right d-flex align-items-center">
                            {Editcash ?
                                <>
                                    <button
                                        type="button"
                                        onClick={() => setEditcash(false)}
                                        className="btn-close shadow-none mx-3 v3-edit-customer-modal-header-btn ms-auto save p-0 border bg-white"
                                        aria-label="Close">
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => { updatezone() }}
                                        className="v3-edit-customer-modal-header-btn bg-main-green save ms-auto">
                                        <img className="" src={base_assets + "images/icons/white-save.png"} alt="" />
                                    </button>
                                </> :
                                <>
                                    <button
                                        type="button"
                                        onClick={() => setEditcash(true)}
                                        className="v3-edit-customer-modal-header-btn bg-main-green save">
                                        <img className="" src={base_assets + "images/admin/icons/pencil-white.png"} alt="" />
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                    {Editcash ?
                        <div className="location-detail-card bg-white rounded-2 com-card mb-4">
                            <table className="location-detail-card-table-separate w-100">
                                <thead className="bg-transparent">
                                    <tr>
                                        <th className="fw-semibold wheatbg" style={{ width: "3%" }}>#</th>
                                        <th className="fw-semibold wheatbg" style={{ width: "6%" }}></th>
                                        <th className="fw-semibold wheatbg" style={{ width: "73%" }}>{t("Zone")}</th>
                                        <th className="fw-semibold wheatbg" style={{ minWidth: "150px" }}>
                                            {t("Type")}</th>
                                        <th className="fw-semibold" style={{ width: "10px" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <>
                                        {createzone?.length ? createzone.map((result, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    <td>
                                                        <div className="border-choose-img d-flex align-items-center justify-content-center">
                                                            <img className={result?.zone_logo ? "d-none" : "position-absolute"} src={base_assets + "images/icons/add-img-circle.png"} alt="" />
                                                            <label className=" d-block h-100 w-100 cursor-pointer"
                                                                htmlFor={"itemImgChoose" + key} style={{ zIndex: "1" }}>
                                                                <img className="w-100 h-100 object-fit-contain" src={result?.zone_logo ? result?.zone_logo : base_assets + "images/icons/camera_profile.png"} alt="" />
                                                            </label>
                                                            <input type="file" id={"itemImgChoose" + key} className="d-none"
                                                                onChange={(e) => onImageChange(e, key)}
                                                                accept="image/*" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="border-0 text-center w-100 box-bg"
                                                            type="text"
                                                            onChange={(e) => {
                                                                handleSelectChange(result?.zone_logo, e.target.value, result?.type, key, result?._id)
                                                            }}
                                                            value={t(result?.zone)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="v3-input-group mb-0">
                                                            <Select
                                                                className="select-set select w-100 p-0"
                                                                placeholder=""
                                                                options={zoneTypeOption}
                                                                value={result?.type}
                                                                onChange={(e) => {
                                                                    handleSelectChange(result?.zone_logo, result?.zone, e, key, result?._id)
                                                                }}
                                                                classNamePrefix="common-select"
                                                                getOptionLabel={(e) => (
                                                                    <div className="common-select-icon">{e.icon}
                                                                        <span>{e.label}</span></div>
                                                                )}
                                                                isSearchable={false}
                                                            />
                                                        </div>
                                                    </td>
                                                    {key !== 0 ?
                                                        <td onClick={() => handleRemoveElement(key, result?._id)} className="cursor-pointer">
                                                            <img src={base_assets + "images/icons/minus-detail.png"} alt="" />
                                                        </td> : <td></td>
                                                    }

                                                </tr>
                                            )
                                        }) : ""}
                                    </>
                                </tbody>
                            </table>
                            <button className="border-0 bg-transparent p-0 main-green-text fw-semibold mt-3 w-100 text-end"
                                onClick={() => addEventList()}>
                                <img src={base_assets + "images/icons/add-detail.png"} alt="" /><span className="ms-2">{t("Add Zone")}</span>
                            </button>

                        </div> :
                        <div className="location-detail-card bg-white rounded-2 com-card mb-4">
                            <table className="location-detail-card-table-separate w-100">
                                <thead>
                                    <tr>
                                        <th className="fw-semibold" style={{ width: "3%" }}>#</th>
                                        <th className="fw-semibold" style={{ width: "6%" }}></th>
                                        <th className="fw-semibold" style={{ width: "73%" }}>{t("Zone")}</th>
                                        <th className="fw-semibold" style={{ minWidth: "150px" }}>{t("Type")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <>
                                        {createzone?.length ? createzone.map((result, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    <td>
                                                        <div className="pro-border-img d-flex align-items-center justify-content-center">
                                                            <img src={result?.zone_logo} alt="" />
                                                        </div>
                                                    </td>
                                                    <td>{result?.zone ? result?.zone : ""}</td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img className="me-2" src={base_assets + "images/admin/icons/cabinet.png"} alt="" />
                                                            {result?.type?.value ? result?.type?.value : ""}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }) : ""}
                                    </>
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
export default Zonedetails;