  import React from "react";

  const Location = (props) => {
    const {color, forcalendar} = props;
    return(
      <>
      {forcalendar ? 
      <svg width="24" height="24" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.99986 20.5999C8.99986 20.5999 16.5129 13.9216 16.5129 8.91294C16.5129 4.76361 13.1492 1.3999 8.99986 1.3999C4.85052 1.3999 1.48682 4.76361 1.48682 8.91294C1.48682 13.9216 8.99986 20.5999 8.99986 20.5999Z" stroke="#A4A4A4"/>
      <path d="M11.4002 8.60005C11.4002 9.92554 10.3257 11.0001 9.00017 11.0001C7.67468 11.0001 6.60017 9.92554 6.60017 8.60005C6.60017 7.27457 7.67468 6.20005 9.00017 6.20005C10.3257 6.20005 11.4002 7.27457 11.4002 8.60005Z" stroke="#A4A4A4"/>
      </svg>
      :
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.1353 19.6962L11.1381 19.6984L11.6901 20.1344L12 20.3792L12.3099 20.1344L12.8619 19.6984L12.8647 19.6962C17.209 16.2147 19.5 12.9578 19.5 10C19.5 8.01088 18.7098 6.10322 17.3033 4.6967C15.8968 3.29018 13.9891 2.5 12 2.5C10.0109 2.5 8.10322 3.29018 6.6967 4.6967C5.29018 6.10322 4.5 8.01088 4.5 10C4.5 12.9578 6.79095 16.2147 11.1353 19.6962ZM12 1.5C14.2543 1.5 16.4163 2.39553 18.0104 3.98959C19.6045 5.58365 20.5 7.74566 20.5 10C20.5 13.4701 17.8424 17.2432 12.3 21.4C12.2135 21.4649 12.1082 21.5 12 21.5C11.8918 21.5 11.7865 21.4649 11.7 21.4C6.15756 17.2432 3.5 13.4701 3.5 10C3.5 7.74566 4.39553 5.58365 5.98959 3.98959C7.58365 2.39553 9.74566 1.5 12 1.5ZM12 8.5C12.3978 8.5 12.7794 8.65804 13.0607 8.93934C13.342 9.22064 13.5 9.60218 13.5 10C13.5 10.3978 13.342 10.7794 13.0607 11.0607C12.7794 11.342 12.3978 11.5 12 11.5C11.6022 11.5 11.2206 11.342 10.9393 11.0607C10.658 10.7794 10.5 10.3978 10.5 10C10.5 9.60218 10.658 9.22064 10.9393 8.93934C11.2206 8.65804 11.6022 8.5 12 8.5Z" stroke={color ? color : "white"}/>
    </svg>
    
    }
       

      </>
    )
  }

  export default Location;
