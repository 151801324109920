import React from 'react'

export default function CellPhoneGreen() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8">
        <path d="M5.85543 3.60077L6.23401 4.27915C6.57567 4.89135 6.43853 5.69444 5.90041 6.23256C5.90041 6.23256 5.24776 6.88531 6.43112 8.06872C7.61412 9.25172 8.26728 8.59943 8.26728 8.59943C8.8054 8.06131 9.60848 7.92417 10.2207 8.26583L10.899 8.64441C11.8235 9.16031 11.9327 10.4567 11.1201 11.2693C10.6319 11.7575 10.0337 12.1375 9.37252 12.1625C8.25946 12.2047 6.36917 11.923 4.47299 10.0269C2.57682 8.13067 2.29512 6.24039 2.33732 5.12731C2.36238 4.46611 2.7423 3.86798 3.23055 3.37973C4.04311 2.56717 5.33951 2.67635 5.85543 3.60077Z" stroke="#3DC564" stroke-linecap="round" />
      </g>
    </svg>



  )
}
