import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css'
import { SetdataTable, UnSetdataTable } from '../common/api/setdatatable'
import { toFormatPrice } from '../common/helpers/function';
import './Quotation.scss'
import CheckoutBlank from '../checkout/checkoutBlank'
import { _Api } from '../common/api/_call'
import { useTranslation } from 'react-i18next';
import { Tablehead } from '../../components/common/modules/Tablehead'
import { posquotation_head } from '../../components/common/Tablehead'
import CheckoutSell from '../checkout/checkoutSell'
import CheckoutPayment from '../checkout/checkoutPayment'
import { useSelector } from 'react-redux';
import DeleteModal from "../common/module/deletemodal";
const Quotation = () => {
  const navigate = useNavigate();
  const [, setdeletestatus] = useState(false)
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const [newcolumndata, setnewcolumndata] = useState(posquotation_head)
  const [quotationid, setQuotationid] = useState()
  const [quotationlist, setQuotationlist] = useState([])
  const [UpdatedCart, setUpdatedCart] = useState();
  const [showcheckout, setShowcheckout] = useState('blank')
  const [, setfilterby] = useState("");
  const [showpayment, setShowpayment] = useState(false);
  const [searchproductstatus, setsearchproductstatus] = useState(false);
  const [filtername, setFiltername] = useState("");
  const [addparmission, setAddparmission] = useState(0)
  const [deleteparmission, setDeleteparmission] = useState(0)
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  useEffect(() => {
    if (parmissiondatashow?.Quotation?.View)
      setAddparmission(parmissiondatashow?.Quotation?.Add)
    setDeleteparmission(parmissiondatashow?.Quotation?.Delete);
  }, [parmissiondatashow?.Quotation?.Add, parmissiondatashow?.Quotation?.Delete, parmissiondatashow?.Quotation?.View]);
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'report_quotation_tbl',
      }
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`,
      )
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    callcolumns()
  }, [])
  const getquotationlist = useCallback(async (event) => {
    UnSetdataTable('quotaion_list_table')
    UnSetdataTable('quotaion_list_table_custom_order')
    UnSetdataTable('quotaion_list_table_repair')
    try {
      let postdata = {
        "type": event ? event : "",
        "search": filtername,
      }
      const res = await _Api(postdata, `api/v1/POS/quatation/getQuatationList`)
      if (res?.code === 200) {
        setQuotationlist(res?.data);
        SetdataTable("quotaion_list_table", 4, newcolumndata, [0]);
        SetdataTable("quotaion_list_table_custom_order", 4, newcolumndata, [0]);
        SetdataTable("quotaion_list_table_repair", 4, newcolumndata, [0]);
      }
      else {
        SetdataTable("quotaion_list_table", 4, newcolumndata, [0]);
        SetdataTable("quotaion_list_table_custom_order", 4, newcolumndata, [0]);
        SetdataTable("quotaion_list_table_repair", 4, newcolumndata, [0]);
      }
    }
    catch (err) {
      console.log(err, "err");
    }
  }, [filtername, newcolumndata])
  useEffect(() => {
    getquotationlist()
  }, [getquotationlist, newcolumndata])

  const GetCartdetailorder = async (id) => {
    try {
      const postdata = {
        "quatation_id": id
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        setUpdatedCart(res?.data);
        setShowcheckout('sell');
        setQuotationid(id)
      } else {
        navigate('/app/pos/custom/customOrderDelivered')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const removequotation = async () => {
    try {
      let postdata = {
        "quatation_id": quotationid
      }
      let res = await _Api(postdata, `api/v1/POS/quatation/removeQuatation`)
      if (res.code === 200) {
        getquotationlist()
      }

    }
    catch (err) {
      console.log(err, 'err')
    }
  }
  const filterbyname = () => {
    if (filtername) {
      getquotationlist();
    } else {
      getquotationlist();
    }
  };
  return (
    <div className="main-body">
      <div className="main-body-row">
        <div className="main-body-row-left-col p-0 quotation">
          <div className="com-data-area">
            <div className="catalog-area-header-area detail-area-header">
              <div className="catalog-area-header-area-control">
                <div className="catalog-area-header-area-control-left">
                  <h1 className="heading">{t("QUOTATION")}</h1>
                </div>
                <div className="catalog-area-header-area-control-right">
                  <div className="com-search-area">
                    <input
                      type="text"
                      onChange={(e) => setFiltername(e.target.value)}
                      onKeyPress={(e) => e.key === 'Enter' && filterbyname()}
                      placeholder={t("Search By Invoice/SKU/Stock ID/Location")}
                    />
                    <img onClick={filterbyname} className="com-search-area-img" src={base_assets + "images/pos/icons/magnifying-glass.png"} alt="" />

                  </div>
                </div>
              </div>
            </div>
            <div className="com-data-area-inner bg-white">
              <div className="Dailysalereport-content-area-main quotation-main mt-0 mx-0">
                <ul
                  className="Dailysalereport-content-area-navbar nav-tabs quotation-main-navbar mt-4"
                  id="myTab"
                  role="tablist"
                >
                  <li
                    className="nav-item Dailysalereport-content-area-navbar-item quotation-main-navbar-item"
                    role="presentation"
                    onClick={() => getquotationlist("")}
                  >
                    <button
                      className="nav-link active Dailysalereport-content-area-navbar-item-link quotation-main-navbar-item-link"
                      id="Sale-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Sale"
                      type="button"
                      role="tab"
                      aria-controls="Sale"
                      aria-selected="true"
                    >
                      {t("ALL")}
                    </button>
                  </li>
                  <li
                    className="nav-item quotation-main-navbar-item"
                    role="presentation"
                    onClick={() => getquotationlist("custom_order")}
                  >
                    <button
                      className="nav-link quotation-main-navbar-item-link quotation-main-navbar-item-link-custom Dailysalereport-content-area-navbar-item-link"
                      id="Custom-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Custom"
                      type="button"
                      role="tab"
                      aria-controls="Custom"
                      aria-selected="false"
                    >
                      {t("Custom")}
                    </button>
                  </li>
                  <li
                    className="nav-item quotation-main-navbar-item"
                    role="presentation"
                    onClick={() => getquotationlist("repair_order")}
                  >
                    <button
                      className="nav-link quotation-main-navbar-item-link Dailysalereport-content-area-navbar-item-link"
                      id="Repair-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Repair"
                      type="button"
                      role="tab"
                      aria-controls="Repair"
                      aria-selected="false"
                    >
                      {t("Repair")}
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="Sale"
                    role="tabpanel"
                    aria-labelledby="Sale-tab"
                  >
                    <div
                      className="main-body-main-table-wrap vertical-scroll-table quotation-table mt-4"
                      id="table-scroll"
                    >
                      <table
                        id="quotaion_list_table"
                        className="common-table first-row-gray w-100 "
                      >
                        <Tablehead
                          tablehead={posquotation_head}
                          tblname={'report_quotation_tbl'}
                          setfilterby={setfilterby}
                        />
                        <tbody>
                          <>
                            {quotationlist.length ? quotationlist.map((result, key) => {
                              return (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>{result?.date ? result?.date : "-"}</td>
                                  <td>{result?.type ? result?.type : "-"}</td>
                                  <td className="link-color">{result?.quo_no ? result?.quo_no : "-"}</td>
                                  <td>{result?.ref_no ? result?.ref_no : "-"}</td>
                                  <td>{result?.SKU ? result?.SKU : "-"}</td>
                                  <td>{result?.location ? result?.location : "-"}</td>
                                  <td>{result?.qty ? result?.qty : "-"}</td>
                                  <td>{toFormatPrice(result.amount, { addSymbol: true })}</td>
                                  <td >
                                    <div className='d-flex'>
                                      <div className={result?.status?.is_quat === 1 && addparmission ? "dropdown-menu-img cursor-pointer" : "d-none"}>
                                        <svg onClick={() => GetCartdetailorder(result?.id)} width="1.7vw" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M10.5 13.5H5.5M8 16V11" stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                          <path d="M1 18V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H8.172C8.70239 1.00011 9.21101 1.2109 9.586 1.586L14.414 6.414C14.7891 6.78899 14.9999 7.29761 15 7.828V18C15 18.2652 14.8946 18.5196 14.7071 18.7071C14.5196 18.8946 14.2652 19 14 19H2C1.73478 19 1.48043 18.8946 1.29289 18.7071C1.10536 18.5196 1 18.2652 1 18Z" stroke="red" strokeWidth="2" strokeLinejoin="round" />
                                          <path d="M8 1V7C8 7.26522 8.10536 7.51957 8.29289 7.70711C8.48043 7.89464 8.73478 8 9 8H15" stroke="red" strokeWidth="2" strokeLinejoin="round" />
                                        </svg>
                                      </div>
                                      <div className={deleteparmission ? "dropdown-menu-img cursor-pointer ms-2" : "d-none"} data-bs-toggle="modal" data-bs-target="#delete-listedModal" onClick={() => setQuotationid(result?.id)}  >
                                        <svg width="2vw" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M7.85603 4.14342H12.1417C12.1417 3.57509 11.916 3.03005 11.5141 2.62819C11.1122 2.22632 10.5672 2.00056 9.99888 2.00056C9.43056 2.00056 8.88552 2.22632 8.48365 2.62819C8.08179 3.03005 7.85603 3.57509 7.85603 4.14342ZM6.57031 4.14342C6.57031 3.2341 6.93154 2.36203 7.57452 1.71905C8.2175 1.07607 9.08957 0.714844 9.99888 0.714844C10.9082 0.714844 11.7803 1.07607 12.4232 1.71905C13.0662 2.36203 13.4275 3.2341 13.4275 4.14342H18.7846C18.9551 4.14342 19.1186 4.21114 19.2392 4.3317C19.3597 4.45226 19.4275 4.61578 19.4275 4.78627C19.4275 4.95677 19.3597 5.12028 19.2392 5.24084C19.1186 5.3614 18.9551 5.42913 18.7846 5.42913H17.6617L16.6186 17.946C16.5427 18.8566 16.1274 19.7053 15.455 20.3241C14.7827 20.9428 13.9023 21.2862 12.9886 21.2863H7.00917C6.09545 21.2862 5.21512 20.9428 4.54274 20.3241C3.87037 19.7053 3.45506 18.8566 3.37917 17.946L2.33603 5.42913H1.21317C1.04267 5.42913 0.87916 5.3614 0.758601 5.24084C0.638042 5.12028 0.570312 4.95677 0.570312 4.78627C0.570312 4.61578 0.638042 4.45226 0.758601 4.3317C0.87916 4.21114 1.04267 4.14342 1.21317 4.14342H6.57031ZM8.71317 9.07199C8.71317 8.90149 8.64544 8.73798 8.52488 8.61742C8.40432 8.49686 8.24081 8.42913 8.07031 8.42913C7.89982 8.42913 7.7363 8.49686 7.61574 8.61742C7.49519 8.73798 7.42746 8.90149 7.42746 9.07199V16.3577C7.42746 16.5282 7.49519 16.6917 7.61574 16.8123C7.7363 16.9328 7.89982 17.0006 8.07031 17.0006C8.24081 17.0006 8.40432 16.9328 8.52488 16.8123C8.64544 16.6917 8.71317 16.5282 8.71317 16.3577V9.07199ZM11.9275 8.42913C11.757 8.42913 11.5934 8.49686 11.4729 8.61742C11.3523 8.73798 11.2846 8.90149 11.2846 9.07199V16.3577C11.2846 16.5282 11.3523 16.6917 11.4729 16.8123C11.5934 16.9328 11.757 17.0006 11.9275 17.0006C12.098 17.0006 12.2615 16.9328 12.382 16.8123C12.5026 16.6917 12.5703 16.5282 12.5703 16.3577V9.07199C12.5703 8.90149 12.5026 8.73798 12.382 8.61742C12.2615 8.49686 12.098 8.42913 11.9275 8.42913Z" fill="black" />
                                        </svg>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            }) : ""}
                          </>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Custom"
                    role="tabpanel"
                    aria-labelledby="Custom-tab"
                  >
                    <div
                      className="main-body-main-table-wrap vertical-scroll-table quotation-table mt-4"
                      id="table-scroll"
                    >
                      <table
                        id="quotaion_list_table_custom_order"
                        className="common-table first-row-gray w-100 "
                      >
                        <Tablehead
                          tablehead={posquotation_head}
                          tblname={'report_quotation_tbl'}
                          setfilterby={setfilterby}
                        />
                        <tbody>
                          <>
                            {quotationlist.length ? quotationlist.map((result, key) => {

                              return (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>{result?.date ? result?.date : "-"}</td>
                                  <td>{result?.type ? result?.type : "-"}</td>
                                  <td className="link-color">{result?.quo_no ? result?.quo_no : "-"}</td>
                                  <td>{result?.ref_no ? result?.ref_no : "-"}
                                  </td>
                                  <td>{result?.SKU ? result?.SKU : "-"}</td>
                                  <td>{result?.location ? result?.location : "-"}</td>
                                  <td>{result?.qty ? result?.qty : "-"}</td>
                                  <td>{toFormatPrice(result.amount, { addSymbol: true })}</td>
                                  <td>
                                    <div className='d-flex justify-content-evenly'>
                                      <div className={result?.status?.is_quat === 1 && addparmission ? "dropdown-menu-img cursor-pointer" : "d-none"}>
                                        <svg onClick={() => GetCartdetailorder(result?.id)} width="1.7vw" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M10.5 13.5H5.5M8 16V11" stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                          <path d="M1 18V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H8.172C8.70239 1.00011 9.21101 1.2109 9.586 1.586L14.414 6.414C14.7891 6.78899 14.9999 7.29761 15 7.828V18C15 18.2652 14.8946 18.5196 14.7071 18.7071C14.5196 18.8946 14.2652 19 14 19H2C1.73478 19 1.48043 18.8946 1.29289 18.7071C1.10536 18.5196 1 18.2652 1 18Z" stroke="red" strokeWidth="2" strokeLinejoin="round" />
                                          <path d="M8 1V7C8 7.26522 8.10536 7.51957 8.29289 7.70711C8.48043 7.89464 8.73478 8 9 8H15" stroke="red" strokeWidth="2" strokeLinejoin="round" />
                                        </svg>
                                      </div>
                                      <div className={deleteparmission ? "dropdown-menu-img cursor-pointer" : "d-none"} data-bs-toggle="modal" data-bs-target="#delete-listedModal" onClick={() => setQuotationid(result?.id)}  >
                                        <svg width="2vw" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M7.85603 4.14342H12.1417C12.1417 3.57509 11.916 3.03005 11.5141 2.62819C11.1122 2.22632 10.5672 2.00056 9.99888 2.00056C9.43056 2.00056 8.88552 2.22632 8.48365 2.62819C8.08179 3.03005 7.85603 3.57509 7.85603 4.14342ZM6.57031 4.14342C6.57031 3.2341 6.93154 2.36203 7.57452 1.71905C8.2175 1.07607 9.08957 0.714844 9.99888 0.714844C10.9082 0.714844 11.7803 1.07607 12.4232 1.71905C13.0662 2.36203 13.4275 3.2341 13.4275 4.14342H18.7846C18.9551 4.14342 19.1186 4.21114 19.2392 4.3317C19.3597 4.45226 19.4275 4.61578 19.4275 4.78627C19.4275 4.95677 19.3597 5.12028 19.2392 5.24084C19.1186 5.3614 18.9551 5.42913 18.7846 5.42913H17.6617L16.6186 17.946C16.5427 18.8566 16.1274 19.7053 15.455 20.3241C14.7827 20.9428 13.9023 21.2862 12.9886 21.2863H7.00917C6.09545 21.2862 5.21512 20.9428 4.54274 20.3241C3.87037 19.7053 3.45506 18.8566 3.37917 17.946L2.33603 5.42913H1.21317C1.04267 5.42913 0.87916 5.3614 0.758601 5.24084C0.638042 5.12028 0.570312 4.95677 0.570312 4.78627C0.570312 4.61578 0.638042 4.45226 0.758601 4.3317C0.87916 4.21114 1.04267 4.14342 1.21317 4.14342H6.57031ZM8.71317 9.07199C8.71317 8.90149 8.64544 8.73798 8.52488 8.61742C8.40432 8.49686 8.24081 8.42913 8.07031 8.42913C7.89982 8.42913 7.7363 8.49686 7.61574 8.61742C7.49519 8.73798 7.42746 8.90149 7.42746 9.07199V16.3577C7.42746 16.5282 7.49519 16.6917 7.61574 16.8123C7.7363 16.9328 7.89982 17.0006 8.07031 17.0006C8.24081 17.0006 8.40432 16.9328 8.52488 16.8123C8.64544 16.6917 8.71317 16.5282 8.71317 16.3577V9.07199ZM11.9275 8.42913C11.757 8.42913 11.5934 8.49686 11.4729 8.61742C11.3523 8.73798 11.2846 8.90149 11.2846 9.07199V16.3577C11.2846 16.5282 11.3523 16.6917 11.4729 16.8123C11.5934 16.9328 11.757 17.0006 11.9275 17.0006C12.098 17.0006 12.2615 16.9328 12.382 16.8123C12.5026 16.6917 12.5703 16.5282 12.5703 16.3577V9.07199C12.5703 8.90149 12.5026 8.73798 12.382 8.61742C12.2615 8.49686 12.098 8.42913 11.9275 8.42913Z" fill="black" />
                                        </svg>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            }) : ""}
                          </>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Repair"
                    role="tabpanel"
                    aria-labelledby="Repair-tab"
                  >
                    <div
                      className="main-body-main-table-wrap vertical-scroll-table quotation-table mt-4"
                      id="table-scroll"
                    >
                      <table
                        id="quotaion_list_table_repair"
                        className="common-table first-row-gray w-100"
                      >
                        <Tablehead
                          tablehead={posquotation_head}
                          tblname={'report_quotation_tbl'}
                          setfilterby={setfilterby}
                        />
                        <tbody>
                          <>
                            {quotationlist.length ? quotationlist.map((result, key) => {
                              return (

                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>{result?.date ? result?.date : "-"}</td>
                                  <td>{result?.type ? result?.type : "-"}</td>
                                  <td className="link-color">{result?.quo_no ? result?.quo_no : "-"}</td>
                                  <td>{result?.ref_no ? result?.ref_no : "-"}
                                  </td>
                                  <td> {result?.SKU ? result?.SKU : "-"}</td>
                                  <td>{result?.location ? result?.location : "-"}</td>
                                  <td>{result?.qty ? result?.qty : "-"}</td>
                                  <td>{toFormatPrice(result.amount, { addSymbol: true })}</td>
                                  <td>
                                    <div className='d-flex justify-content-evenly'>
                                      <div className={result?.status?.is_quat === 1 && addparmission ? "dropdown-menu-img cursor-pointer" : "d-none"}>
                                        <svg onClick={() => GetCartdetailorder(result?.id)} width="1.7vw" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M10.5 13.5H5.5M8 16V11" stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                          <path d="M1 18V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H8.172C8.70239 1.00011 9.21101 1.2109 9.586 1.586L14.414 6.414C14.7891 6.78899 14.9999 7.29761 15 7.828V18C15 18.2652 14.8946 18.5196 14.7071 18.7071C14.5196 18.8946 14.2652 19 14 19H2C1.73478 19 1.48043 18.8946 1.29289 18.7071C1.10536 18.5196 1 18.2652 1 18Z" stroke="red" strokeWidth="2" strokeLinejoin="round" />
                                          <path d="M8 1V7C8 7.26522 8.10536 7.51957 8.29289 7.70711C8.48043 7.89464 8.73478 8 9 8H15" stroke="red" strokeWidth="2" strokeLinejoin="round" />
                                        </svg>
                                      </div>
                                      <div className={deleteparmission ? "dropdown-menu-img cursor-pointer" : "d-none"} data-bs-toggle="modal" data-bs-target="#delete-listedModal" onClick={() => setQuotationid(result?.id)}  >
                                        <svg width="2vw" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M7.85603 4.14342H12.1417C12.1417 3.57509 11.916 3.03005 11.5141 2.62819C11.1122 2.22632 10.5672 2.00056 9.99888 2.00056C9.43056 2.00056 8.88552 2.22632 8.48365 2.62819C8.08179 3.03005 7.85603 3.57509 7.85603 4.14342ZM6.57031 4.14342C6.57031 3.2341 6.93154 2.36203 7.57452 1.71905C8.2175 1.07607 9.08957 0.714844 9.99888 0.714844C10.9082 0.714844 11.7803 1.07607 12.4232 1.71905C13.0662 2.36203 13.4275 3.2341 13.4275 4.14342H18.7846C18.9551 4.14342 19.1186 4.21114 19.2392 4.3317C19.3597 4.45226 19.4275 4.61578 19.4275 4.78627C19.4275 4.95677 19.3597 5.12028 19.2392 5.24084C19.1186 5.3614 18.9551 5.42913 18.7846 5.42913H17.6617L16.6186 17.946C16.5427 18.8566 16.1274 19.7053 15.455 20.3241C14.7827 20.9428 13.9023 21.2862 12.9886 21.2863H7.00917C6.09545 21.2862 5.21512 20.9428 4.54274 20.3241C3.87037 19.7053 3.45506 18.8566 3.37917 17.946L2.33603 5.42913H1.21317C1.04267 5.42913 0.87916 5.3614 0.758601 5.24084C0.638042 5.12028 0.570312 4.95677 0.570312 4.78627C0.570312 4.61578 0.638042 4.45226 0.758601 4.3317C0.87916 4.21114 1.04267 4.14342 1.21317 4.14342H6.57031ZM8.71317 9.07199C8.71317 8.90149 8.64544 8.73798 8.52488 8.61742C8.40432 8.49686 8.24081 8.42913 8.07031 8.42913C7.89982 8.42913 7.7363 8.49686 7.61574 8.61742C7.49519 8.73798 7.42746 8.90149 7.42746 9.07199V16.3577C7.42746 16.5282 7.49519 16.6917 7.61574 16.8123C7.7363 16.9328 7.89982 17.0006 8.07031 17.0006C8.24081 17.0006 8.40432 16.9328 8.52488 16.8123C8.64544 16.6917 8.71317 16.5282 8.71317 16.3577V9.07199ZM11.9275 8.42913C11.757 8.42913 11.5934 8.49686 11.4729 8.61742C11.3523 8.73798 11.2846 8.90149 11.2846 9.07199V16.3577C11.2846 16.5282 11.3523 16.6917 11.4729 16.8123C11.5934 16.9328 11.757 17.0006 11.9275 17.0006C12.098 17.0006 12.2615 16.9328 12.382 16.8123C12.5026 16.6917 12.5703 16.5282 12.5703 16.3577V9.07199C12.5703 8.90149 12.5026 8.73798 12.382 8.61742C12.2615 8.49686 12.098 8.42913 11.9275 8.42913Z" fill="black" />
                                        </svg>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            }) : ""}
                          </>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-body-row-right-col">
          {showcheckout === 'sell' ? (
            <CheckoutSell
              setShowcheckout={setShowcheckout}
              setdeletestatus={setdeletestatus}
              setUpdatedCart={setUpdatedCart}
              UpdatedCart={UpdatedCart}
              setShowpayment={setShowpayment}
              showpayment={showpayment}
              setsearchproductstatus={setsearchproductstatus}
              searchproductstatus={searchproductstatus}
            // ordertype={"Quotation"}
            />
          ) : showcheckout === 'payment' ? (

            < CheckoutPayment UpdatedCart={UpdatedCart} setShowcheckout={setShowcheckout} quotationid={quotationid ? quotationid : ''} />
          ) : (
            <CheckoutBlank />
          )}
        </div>
      </div>
      <DeleteModal statement={"Quotation"} handler_delete={removequotation} />
    </div>

  )
}

export default Quotation;
