import React, { useCallback, useEffect, useRef, useState } from 'react'
import './kanban.scss'
import { MultipleUserIcon } from '../../common/icons/MultipleUserIconFilled'
import SearchIcon from '../../common/icons/SearchIcon'
import CloseIcon from '../../common/icons/CloseIcon'
import CustomerList from '../CustomerList/Index'
import Po from './Po'
import Purchase from './Purchase'
import Stock from './Stock'
import CalendarFilter from '../CalendarFilter'
import { _Api, graphqlPost } from '../../api/_call'
import { useTranslation } from 'react-i18next'
import KanbanFilter from './KanbanFilter'

export default function KanbanPage({ showFilter, setShowFilter, handleShowFilter, setFilterCount, closeCommonFilter}) {
  const { t } = useTranslation();
  const [handleUserlist, setHandleUserlist] = useState(false);
  const [customerCount, setCustomerCount] = useState(0);
  const [customerData, setCustomerData] = useState();
  const [activeKanban, setActiveKanban] = useState('po');
  const [searchValue, setSearchValue] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [applyfilter, setApplyfilter] = useState({ order_type: [], location: "", search: "", Status: ["Backlog", "Pending", "Approved", "Cancel", "Completed"], duesFilters: ["due", "overdue", "nextDay", "nextWeek"]  })
  const userListRef = useRef(null);
  const [location, setLocation] = useState([]);

  const getLocationList = useCallback(async () => {
    try {
      const postData = `{
        locations { 
          name    
        }
      }`;
      const response = await graphqlPost(postData);
      let locationArray = [];
      if (response.data?.locations) {
        const filteredLocations = response.data?.locations; 
         filteredLocations.map((item)=>{locationArray.push(item?.name)})
         setLocation(prev=>prev = locationArray)
         if(locationArray?.length){
          if(activeKanban !== 'stock'){
            setApplyfilter((prev)=>({...prev,location : locationArray}));
             } 
         }else{
          setApplyfilter((prev)=>({...prev,location : []}));
         }
       
      }
    } catch (err) {
      console.error(err, "err");
    }
    
  }, []);
 
  useEffect(() => {
    getCustomerlist();
    getLocationList();
    // Function to close popup when clicked outside
    const handleClickOutside = (event) => {
      if (userListRef.current && !userListRef.current.contains(event.target)) {
        setHandleUserlist(false);
      }
    };

    // Attach event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getCustomerlist = async () => {
    let postdata = {
      "search": ''
    }
    try {
      let res = await _Api(postdata, `api/v1/App/calender/getCalender-customer-info`)
      if (res?.code === 200) {
        setCustomerData(res?.Data);
        setCustomerCount(res?.Data?.length);
      }
    }
    catch (err) {
      console.log(err, "err")
    }

  }

  const handleSearch = (e) => {
    if (e.type === 'click' || e.key === 'Enter') {
      setApplyfilter((prevFilter) => ({
        ...prevFilter,
        search: searchValue,
      }));
      console.log('Search value:', searchValue);
    }
  };

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleClose = () => {
    // Clear the search value in the applyfilter state
    setApplyfilter((prevFilter) => ({
      ...prevFilter,
      search: "",
    }));
    setSearchValue('');
    setShowSearch(false);
  };

  const handleKanbanTabs = useCallback(async (type) => {
    closeCommonFilter();
   
    if(type === 'po'){
      setActiveKanban('po');
      setApplyfilter((prev)=> prev = { order_type: [], location: "", search: "", duesFilters: ["due", "overdue", "nextDay", "nextWeek"], Status: ["Backlog", "Pending", "Approved", "Cancel", "Completed"]  })

    }else{
      if(type === 'purchase'){
        setActiveKanban('purchase')
        setApplyfilter((prev)=> prev = { order_type: [], location: "", search: "", duesFilters: ["due", "overdue", "nextDay", "nextWeek"], Status: ["Backlog", "Pending", "Approved", "Cancel"] })
      }else{
        setActiveKanban('stock')
        setApplyfilter((prev)=> prev = { order_type: [], location: "", search: "", duesFilters: ["due", "overdue", "nextDay", "nextWeek"], Status: ["Backlog", "Pending", "Approved", "Cancel", "Completed", "Transit"]})
      }
    }
    getLocationList();
  },[activeKanban]) 


  return (
    <div className='kanbanpage'>
      <div className='kanbanpage-header'>
        <div className='kanbanpage-header-left'>
          <div className='kanbanpage-header-left-inner'>
            <div className='kanbanpage-header-left-inner-reportbtn' onClick={() => handleKanbanTabs('po')}>
              <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.4286 1H3.57143C2.15127 1 1 2.10612 1 3.47059V19.5294C1 20.8939 2.15127 22 3.57143 22H16.4286C17.8487 22 19 20.8939 19 19.5294V3.47059C19 2.10612 17.8487 1 16.4286 1Z" stroke={activeKanban === 'po' ? "#212121" : '#A4A4A4'} />
                <path d="M6.14258 7.17647H13.8569M6.14258 12.1176H13.8569M6.14258 17.0588H11.2854" stroke={activeKanban === 'po' ? "#212121" : '#A4A4A4'} strokeLinecap="round" />
              </svg>
              <span className='kanbanpage-header-left-inner-reportbtn-tooltip'>{t("Purchase Order")}</span>
            </div>
            <div className='kanbanpage-header-left-inner-checkedreport' onClick={() => handleKanbanTabs('purchase')}>
              <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.05 20.5455H2.94286C1.5937 20.5455 0.5 19.4896 0.5 18.1872V2.85829C0.5 1.55584 1.5937 0.5 2.94286 0.5H15.1571C16.5063 0.5 17.6 1.55584 17.6 2.85829V10.5227" stroke={activeKanban === 'purchase' ? "#212121" : '#A4A4A4'} />
                <path d="M5.38574 6.39575H12.7143M5.38574 11.1123H12.7143" stroke={activeKanban === 'purchase' ? "#212121" : "#A4A4A4"} strokeLinecap="round" />
                <path d="M14.75 11.9546C12.128 11.9546 10 14.0928 10 16.7273C10 19.3619 12.128 21.5 14.75 21.5C17.372 21.5 19.5 19.3619 19.5 16.7273C19.5 14.0928 17.372 11.9546 14.75 11.9546ZM13.4627 18.7748L11.7575 17.0614C11.7135 17.0172 11.6786 16.9648 11.6548 16.907C11.631 16.8493 11.6188 16.7874 11.6188 16.7249C11.6188 16.6624 11.631 16.6006 11.6548 16.5428C11.6786 16.4851 11.7135 16.4326 11.7575 16.3885C11.8015 16.3443 11.8537 16.3092 11.9111 16.2853C11.9686 16.2614 12.0302 16.2491 12.0924 16.2491C12.1546 16.2491 12.2162 16.2614 12.2736 16.2853C12.3311 16.3092 12.3833 16.3443 12.4273 16.3885L13.8 17.763L17.068 14.4794C17.1568 14.3901 17.2773 14.34 17.4029 14.34C17.5285 14.34 17.6489 14.3901 17.7378 14.4794C17.8266 14.5686 17.8765 14.6896 17.8765 14.8158C17.8765 14.942 17.8266 15.0631 17.7378 15.1523L14.1325 18.7748C14.0886 18.8191 14.0364 18.8542 13.9789 18.8781C13.9214 18.9021 13.8598 18.9144 13.7976 18.9144C13.7354 18.9144 13.6738 18.9021 13.6164 18.8781C13.5589 18.8542 13.5067 18.8191 13.4627 18.7748Z" fill={activeKanban === 'purchase' ? "#212121" : '#A4A4A4'} />
              </svg>
              <span className='kanbanpage-header-left-inner-checkedreport-tooltip'>{t("Purchase")}</span>
            </div>
            <div className='kanbanpage-header-left-inner-stock' onClick={() => handleKanbanTabs('stock')}>
              <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.974 15.2634V3.11182C15.974 2.6369 15.79 2.18144 15.4624 1.84562C15.1349 1.5098 14.6906 1.32114 14.2274 1.32114H2.94971C2.48649 1.32114 2.04224 1.5098 1.71469 1.84562C1.38714 2.18144 1.20313 2.6369 1.20312 3.11182V14.9769C1.20313 15.4518 1.38714 15.9073 1.71469 16.2431C2.04224 16.579 2.48649 16.7676 2.94971 16.7676H4.25965M23.2031 9.79646H15.9758M20.8522 16.4328H21.4548C21.918 16.4328 22.3623 16.2441 22.6898 15.9083C23.0174 15.5725 23.2014 15.117 23.2014 14.6421V9.27L20.7684 4.16117C20.6246 3.86034 20.4014 3.60689 20.1242 3.42962C19.8469 3.25235 19.5267 3.15838 19.1999 3.15838H16.2884" stroke={activeKanban === 'stock' ? "#212121" : "#A4A4A4"} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14.9582 16.7676H9.82844M4.34766 16.8213C4.34766 17.4843 4.60454 18.1202 5.0618 18.589C5.51906 19.0578 6.13923 19.3211 6.78589 19.3211C7.43255 19.3211 8.05272 19.0578 8.50998 18.589C8.96724 18.1202 9.22413 17.4843 9.22413 16.8213C9.22413 16.1584 8.96724 15.5225 8.50998 15.0537C8.05272 14.5849 7.43255 14.3215 6.78589 14.3215C6.13923 14.3215 5.51906 14.5849 5.0618 15.0537C4.60454 15.5225 4.34766 16.1584 4.34766 16.8213ZM15.4158 16.8213C15.4158 17.1496 15.4788 17.4747 15.6014 17.778C15.7239 18.0813 15.9035 18.3568 16.1299 18.589C16.3563 18.8211 16.6251 19.0052 16.9209 19.1309C17.2168 19.2565 17.5338 19.3211 17.854 19.3211C18.1742 19.3211 18.4913 19.2565 18.7871 19.1309C19.0829 19.0052 19.3517 18.8211 19.5781 18.589C19.8045 18.3568 19.9841 18.0813 20.1066 17.778C20.2292 17.4747 20.2922 17.1496 20.2922 16.8213C20.2922 16.4931 20.2292 16.168 20.1066 15.8647C19.9841 15.5614 19.8045 15.2859 19.5781 15.0537C19.3517 14.8216 19.0829 14.6375 18.7871 14.5118C18.4913 14.3862 18.1742 14.3215 17.854 14.3215C17.5338 14.3215 17.2168 14.3862 16.9209 14.5118C16.6251 14.6375 16.3563 14.8216 16.1299 15.0537C15.9035 15.2859 15.7239 15.5614 15.6014 15.8647C15.4788 16.168 15.4158 16.4931 15.4158 16.8213Z" stroke={activeKanban === 'stock' ? "#212121" : "#A4A4A4"} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className='kanbanpage-header-left-inner-stock-tooltip'>{t("Stock Transfer")}</span>
            </div>
          </div>
          <div className='kanbanpage-header-left-user'>
            <div className='kanbanpage-header-left-user-icon' onClick={() => setHandleUserlist(!handleUserlist)}>
              <MultipleUserIcon color={handleUserlist ? "#212121" : "#A4A4A4"} />
            </div>
            <div className='kanbanpage-header-left-user-count'>
              {customerCount}
            </div>
            {handleUserlist ?
              <div ref={userListRef} className='customer-list'>
                <CustomerList setCustomerCount={setCustomerCount} customerData={customerData} />
              </div> : ""}
          </div>
        </div>
        <div className={`kanbanpage-header-right ${showSearch ? 'active' : ''}`} >
          {!showSearch ?
            <div className='kanbanpage-header-right-search' onClick={() => setShowSearch(!showSearch)}>
              <span className='kanbanpage-header-right-search-icon'><SearchIcon /></span>
              <span>Search</span>
            </div>
            :
            <div className='kanbanpage-header-right-searchbar'>
              {showSearch && (
                <>
                  <input
                    className='kanbanpage-header-right-searchbar-input'
                    type='text'
                    placeholder='Search'
                    value={searchValue}
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleSearch(e)}
                  />
                  <span
                    className='kanbanpage-header-right-searchbar-input-searchicon'
                    onClick={handleSearch}
                  >
                    <SearchIcon />
                  </span>
                  <span
                    className='kanbanpage-header-right-searchbar-input-closeicon'
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </span>
                </>
              )}
            </div>
          }
        </div>

      </div>
      <div className='kanbanpage-content'>
        {activeKanban === 'po' ?
          <Po setApplyfilter={setApplyfilter} applyfilter={applyfilter} />
          : activeKanban === 'purchase' ?
            <Purchase setApplyfilter={setApplyfilter} applyfilter={applyfilter} />
            : <Stock setApplyfilter={setApplyfilter} applyfilter={applyfilter} />
        }
      </div>
      {showFilter ?
        <div> 
          <KanbanFilter handleShowFilter={handleShowFilter} setShowFilter={setShowFilter} setApplyfilter={setApplyfilter} location={location} applyfilter={applyfilter} activeKanban={activeKanban}/>
          {/* <CalendarFilter handleShowFilter={handleShowFilter} setShowFilter={setShowFilter} setApplyfilter={setApplyfilter} applyfilter={applyfilter} kanban={true}/> */}
        </div> 
        : ""}
    </div>
  )
}
