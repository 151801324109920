import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import { graphqlPost, _Api } from '../common/api/_call'
import ImageUploading from 'react-images-uploading'
import CheckoutBlank from '../checkout/checkoutBlank'
import ProductModal from '../common/module/ProductModal'
import CheckoutSell from '../checkout/checkoutSell'
import CheckoutPayment from '../checkout/checkoutPayment'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { store } from '../../source/index'
import { error } from '../common/helpers/toastify'
import Loading from '../common/module/Loading/Loading';
const RepairOrderProcess = (props) => {
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const generalsettings = useSelector((state) => state.app.generalsettings)
  const [productdetails, setProductdetails] = useState()
  const { state } = useLocation()
  const [productfilterdetails, setProductfilterdetails] = useState()
  const [images, setImages] = useState([])
  const [repaircheck, setRepaircheck] = useState([])
  const [repairlist, setRepairlist] = useState()
  const [checkeditstatus, setCheckeditstatus] = useState(false)
  const [Loadingstatus, setLoadingstatus] = useState(true);
  const [UpdatedCart, setUpdatedCart] = useState()
  const [showseq, setShowseq] = useState(0)
  const [cartid, setCartid] = useState()
  const [deletestatus, setdeletestatus] = useState(false)
  const [searchproductstatus, setsearchproductstatus] = useState(true)
  const [showcheckout, setShowcheckout] = useState(localStorage.getItem('showcheckout') || 'sell')
  const [showpayment, setShowpayment] = useState(false)
  const [cartseq, setCartseq] = useState()
  const [customCartdetail, setCustomCartdetail] = useState()
  const [editcart, setEditcart] = useState()
  const [showmodal, setShowmodal] = useState(false)
  const [servicelabour, setServicelabour] = useState([])
  const [customsecrive, setCustomsecrive] = useState(false)
  var state1 = store.getState()
  var OrgSettings = state1?.app?.posorganisation_settings
  var currency = OrgSettings?.symbol
  let navigate = useNavigate()
  const { t } = useTranslation()
  const updateimage = async (imageList, label) => {
    if (label === 'update') {
      let array = []
      setImages(imageList)
      try {
        const postdata = {
          Data: imageList,
          name: 'repair_order_image',
        }
        let res = await _Api(postdata, `api/v1/POS/customOrder/uploadDesign`)
        if (res.code === 200) {
          res?.data.forEach((result) => {
            array.push(result?.url)
          })
          setProductdetails((state) => ({
            ...state,
            repair_design: {
              ...state?.repair_design,
              image_design: array
            }
          }))
        } else {
          setProductdetails((state) => ({
            ...state,
            repair_design: {
              ...state?.repair_design,
              image_design: []
            }
          }))
        }
      } catch (err) {
        console.log(err, 'err')
      }
    }
  }
  const repaircheckbox = (e) => {
    if (e.target.checked) {
      setRepaircheck([...repaircheck, e.target.id])
    } else {
      setRepaircheck((current) =>
        current.filter((item) => item !== e.target.id),
      )
    }
  }
  const editproduct_submit = () => {
    var mainarray = { ...productdetails }
    mainarray['product_details'] = productfilterdetails
    setProductdetails(mainarray)
    setCheckeditstatus(true)
  }
  const submitproductremark = async () => {
    setCheckeditstatus(false)
    try {
      const postdata = {
        cart_id: cartid,
        product_detail: productdetails?.product_details,
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/editCartItems`)
      if (res.code === 200) {
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getRepaiList = async () => {
    try {
      const postdata = `{
        Repair{
            id
            name
            
        }
      }`
      let res = await graphqlPost(postdata, 'App/graphql')
      setRepairlist(res?.data?.Repair)
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const customdesign = (lable, value) => {
    let design = { ...productdetails }
    if (lable === 'remark') {
      design.product_details['remark'] = value
    } else {
      design.repair_design[lable] = value
    }
    setProductdetails(design)
  }
  const GetcustomCartdetail = async (order_id, order_edit, order_type) => {
    try {
      const postdata = {
        order_id: order_id,
        order_edit: order_edit,
        order_type: order_type,
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        if (state?.order_id && state?.order_edit && state?.order_type) {
          setUpdatedCart(res?.data)
          if (res?.data?.sell_info?.cart?.length) {
            setCustomCartdetail(res?.data?.sell_info?.cart)
            productdetail(res?.data?.sell_info?.cart[0]?.custom_cart_id)
            setEditcart(res?.data?.sell_info?.cart[0])
          } else {
            setLoadingstatus(false)
            navigate('/app/pos/repair', {
              state: {
                order_id: state?.order_id,
                order_edit: state?.order_edit,
                order_type: state?.order_type
              }
            })
          }
          setShowcheckout('sell')
        } else {
          if ((!cartseq && cartseq !== 0) || deletestatus) {
            setCartseq(res?.data?.length - 1)
            productdetail(res?.data[res?.data?.length - 1]?.custom_cart_id)
            setProductdetails((state) => ({
              ...state,
              service_labour: {
                ...state?.service_labour,
                product_price: res?.data[res?.data?.length - 1]?.price
              }

            }))
          } else {
            productdetail(res?.data[cartseq]?.custom_cart_id)
            setProductdetails((state) => ({
              ...state,
              service_labour: {
                ...state?.service_labour,
                product_price: res?.data[cartseq]?.price
              }

            }))
          }
          setCustomCartdetail(res?.data)
        }
      } else {
        error(res?.message)
        navigate('/app/pos/repair')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const productdetail = async (cartid, key) => {
    setLoadingstatus(true)
    try {
      const postdata = {
        custom_cart_id: cartid
          ? cartid
          : customCartdetail[0]?.custom_cart_id
            ? customCartdetail[0]?.custom_cart_id
            : editcart?.custom_cart_id,
      }
      let res = await _Api(
        postdata,
        `api/v1/POS/customOrder/getCartItemDetails`,
      )
      if (res.code === 200) {
        let imagearray = []
        setProductdetails(res.data)
        setCartid(res?.data?.custom_cart_id)
        setRepaircheck(res.data?.repair_design?.repair_list)
        if (key || key === 0) {
          setCartseq(key)
        }

        if (state?.order_edit) {
          setProductfilterdetails(res.data?.product_details)
        } else {
          setProductfilterdetails(res.data?.product_details)
          if (res?.data?.repair_design?.image_design_base64?.length) {
            res?.data?.repair_design?.image_design_base64.forEach((result) => {
              imagearray.push({ data_url: result })
            })
          }
          setImages(imagearray)
        }
        setLoadingstatus(false)
      } else {
        setLoadingstatus(false)
        error((res?.message))
        navigate('/app/pos/repair')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const submitdetails = async () => {
    setShowcheckout('sell')
    try {
      const postdata = productdetails
      let res = await _Api(
        postdata,
        `api/v1/POS/customOrder/editCartItemDetails`,
      )
      if (res.code === 200) {
        productdetail(productdetails?.custom_cart_id)
        setShowseq(0)
        setsearchproductstatus(true);
        GetcustomCartdetail(state?.order_id, state?.order_edit, state?.order_type)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }


  useEffect(() => {
    if (checkeditstatus) {
      submitproductremark()
    }
    // eslint-disable-next-line
  }, [checkeditstatus])

  useEffect(() => {
    if (repaircheck?.length) {
      customdesign('repair_list', repaircheck)
    }
    // eslint-disable-next-line
  }, [repaircheck])

  useEffect(() => {
    if (state?.order_id && state?.order_edit && state?.order_type) {
      GetcustomCartdetail(state?.order_id, state?.order_edit, state?.order_type)
      setShowcheckout('sell')
    } else {
      GetcustomCartdetail()
    }
    // eslint-disable-next-line
  }, [deletestatus === true, state?.order_id])

  useEffect(() => {
    getRepaiList()
    // eslint-disable-next-line
  }, [])
  const getSalesList = async (props) => {
    try {
      const postdata = `{
            ServiceLabour{
                   name
                   code
                   id
               }
    }`

      let responce = await graphqlPost(postdata, 'App/graphql')
      let ServiceLabour = []
      if (responce.data?.ServiceLabour.length) {
        responce.data?.ServiceLabour?.forEach((result) => {
          ServiceLabour.push({
            id: result?.id,
            code: result?.code,
            name: result?.name,
          })
        })
      }

      setServicelabour(ServiceLabour)
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    getSalesList()
  }, [])

  const selectedproduct = (event, id, code, name, index) => {
    if (event.target.checked) {
      let list = []
      if (productdetails?.service_labour?.service_laburelist?.length) {
        let data = [...productdetails?.service_labour?.service_laburelist]
        if (data[index]?.id !== id) {
          data.push({ id: id, name: name, code: code, scrviceamount: 0 })
        }
        setProductdetails((state) => ({
          ...state,
          service_labour: {
            ...state?.service_labour,
            service_laburelist: data
          }
        }))
      } else {
        list.push({ id: id, name: name, code: code, scrviceamount: 0 })
        setProductdetails((state) => ({
          ...state,
          service_labour: {
            ...state?.service_labour,
            service_laburelist: list
          }
        }))
      }
    } else {
      const resultlist = productdetails?.service_labour?.service_laburelist.filter((element) => {
        return element?.id !== event.target.id
      })
      setProductdetails((state) => ({
        ...state,
        service_labour: {
          ...state?.service_labour,
          service_laburelist: resultlist
        }
      }))
    }
  }
  const deleteproduct = (id) => {
    setProductdetails((state) => ({
      ...state,
      service_labour: {
        ...state?.service_labour,
        service_laburelist: state?.service_labour?.service_laburelist.filter((item) => item?.id !== id)
      }
    }))
  }
  const scrviceamount = (e, key) => {
    let array = [...productdetails?.service_labour?.service_laburelist]
    array[key] = { ...array[key], scrviceamount: parseInt(e.target.value) }
    setProductdetails((state) => ({
      ...state,
      service_labour: {
        ...state?.service_labour,
        service_laburelist: array
      }
    }))
    var total = 0
    for (var i = 0; i < array.length; i++) {
      total = total + array[i].scrviceamount
    }
  }

  const scrvicelabure = async () => {
    try {
      const postdata = {
        cart_id: cartid ? cartid : state ? state?.cartid : '',

        service_labour: {
          service_remark: productdetails?.service_labour?.service_remark,
          service_laburelist: productdetails?.service_labour?.service_laburelist,
          product_price: productdetails?.service_labour?.product_price,
        },
        service_saved: productdetails?.service_labour?.service_laburelist?.length ? true : false,
        order_edit: state?.order_edit,
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/saveServiceLabour`)
      if (res.code === 200) {
        if (res?.data === true) {
          setCustomsecrive(true)
        }
      } else {
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const [isSwitchActive, setIsSwitchActive] = useState(false)
  const switchActive = (event) => {
    setIsSwitchActive((current) => !current)
  }
  useEffect(() => {
    localStorage.setItem('showcheckout', showcheckout);
  }, [showcheckout]);
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            {
              Loadingstatus ? <Loading /> : ''
            }
            <div className="com-data-area">
              <div className="catalog-area-header-area detail-area-header">
                <div className="catalog-area-header-area-control">
                  <div className="catalog-area-header-area-control-left">
                    <h1 className="heading">{t('REPAIR ORDER')}</h1>
                  </div>
                  <div className="catalog-area-header-area-control-right">
                    <div className="custom-tooltip">
                      <Link to="/app/pos/repair/repairOrderDelivered">
                        <img
                          className="custom-order-img"
                          src={
                            base_assets +
                            '/images/pos/icons/custom-order-icon.png'
                          }
                          alt=""
                        />
                        <span className="com-btn custom-tooltip-text">
                          {t('Repair Order')}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="selection-process">
                <div className="selection-process-single active-green">
                  <img
                    className="selection-process-single-img img-green"
                    src={base_assets + 'images/pos/steps/s1-g.png'}
                    alt=""
                  />
                  <span className="selection-process-single-name">
                    {t('Select Product')}
                  </span>
                </div>
                <span className="selection-process-line"></span>
                <div
                  className={
                    productdetails?.repair_design_status === 1
                      ? 'selection-process-single active-green'
                      : 'selection-process-single'
                  }
                >
                  <img
                    className="selection-process-single-img img-gray"
                    src={base_assets + 'images/pos/steps/s9.png'}
                    alt=""
                  />
                  <img
                    className="selection-process-single-img img-green"
                    src={base_assets + 'images/pos/steps/s9-g.png'}
                    alt=""
                  />
                  <span className="selection-process-single-name">
                    {t('Repair Process')}
                  </span>
                </div>
              </div>
              <div className="create-own-setting">
                <div className="create-own-setting-left">
                  <ul className="create-own-setting-left-ul">
                    {customCartdetail?.length ? (
                      customCartdetail.map((result, cartkey) => {
                        return (
                          <li
                            className="create-own-setting-left-ul-li"
                            onClick={() =>
                              productdetail(result?.custom_cart_id, cartkey)
                            }
                            key={cartkey}
                          >
                            <button
                              className={
                                result?.custom_cart_id === cartid
                                  ? 'create-own-setting-left-ul-li-btn btn-style active'
                                  : 'create-own-setting-left-ul-li-btn btn-style'
                              }
                            >
                              <img
                                className="create-own-setting-left-ul-li-btn-img"
                                src={result?.main_image}
                                alt=""
                              />
                            </button>
                          </li>
                        )
                      })
                    ) : editcart ? (
                      <li
                        className="create-own-setting-left-ul-li"
                      >
                        <button
                          className={
                            'create-own-setting-left-ul-li-btn btn-style active'
                          }
                        >
                          <img
                            className="create-own-setting-left-ul-li-btn-img"
                            src={editcart?.main_image}
                            alt=""
                          />
                        </button>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                  {/* {!state?.checklength ?
                    // <Link
                    //   to="/app/pos/repair"
                    //   state={{ changecolor: 'yellow' }}
                    //   className="btn create-own-setting-left-ul-add-more"
                    // >
                    //   <img src={base_assets + 'images/icons/adplus.png'} alt="" />
                    // </Link> 
                    : ""} */}

                </div>
                <div className="create-own-setting-right">
                  <div className="create-own-setting-right-product-detail">
                    <div className="create-own-setting-right-product-detail-left">
                      <img
                        className="create-own-setting-right-product-detail-left-img"
                        src={productdetails?.product_details?.main_image}
                        alt=""
                      />
                    </div>
                    <div className="create-own-setting-right-product-detail-right">
                      <h3 className="create-own-setting-right-product-detail-right-title">
                        {' '}
                        {productdetails?.product_details?.name}
                      </h3>
                      <button
                        className="create-own-setting-right-product-detail-right-sku btn-style text-decoration-none"
                        data-bs-toggle="modal"
                        data-bs-target="#ProductInfoModal"
                      >
                        {productdetails?.product_details?.SKU}
                      </button>
                      <p className="create-own-setting-right-product-detail-right-para">
                        {productdetails?.product_details?.meta_tag}
                      </p>
                      <div className="create-own-setting-right-product-detail-right-detail">
                        <div className="create-own-setting-right-product-detail-right-detail-single">
                          {t('Metal')} :{' '}
                          <span className="create-own-setting-right-product-detail-right-detail-single-data">
                            {productdetails?.product_details?.metal_name}
                          </span>
                        </div>
                        <div className="create-own-setting-right-product-detail-right-detail-single">
                          {t('Stone')} :{' '}
                          <span className="create-own-setting-right-product-detail-right-detail-single-data">
                            {productdetails?.product_details?.stone_name}
                          </span>
                        </div>
                        <div className="create-own-setting-right-product-detail-right-detail-single">
                          {t('Size')} :{' '}
                          <span className="create-own-setting-right-product-detail-right-detail-single-data">
                            {' '}
                            {productdetails?.product_details?.size_name}
                          </span>
                        </div>
                      </div>

                      <textarea
                        className="create-own-setting-right-product-detail-right-remark"
                        onChange={(e) =>
                          customdesign('remark', e?.target.value)
                        }
                        value={
                          productdetails?.product_details?.remark
                            ? productdetails?.product_details?.remark
                            : ''
                        }
                        placeholder="Remark"
                      ></textarea>
                    </div>
                  </div>
                  <div className="create-own-setting-right-sketch-area">
                    <div className="selection-process bg-white">
                      <div
                        className={
                          productdetails?.repair_design_status === 1
                            ? showseq >= 1 || showseq >= 0
                              ? 'selection-process-single active-yellow '
                              : 'selection-process-single active-green'
                            : showseq >= 1
                              ? 'selection-process-single active-green '
                              : 'selection-process-single'
                        }
                      >
                        <img
                          className="selection-process-single-img img-gray"
                          src={base_assets + 'images/pos/steps/s9.png'}
                          alt=""
                        />
                        <img
                          className="selection-process-single-img img-green"
                          src={base_assets + 'images/pos/steps/s9-g.png'}
                          alt=""
                        />
                        <img
                          className="selection-process-single-img img-yellow"
                          src={base_assets + 'images/pos/steps/s9-y.png'}
                          alt=""
                        />
                        <span className="selection-process-single-name">
                          {t('Process')}
                        </span>
                      </div>
                      <span className="selection-process-line border-dashed"></span>

                      <div
                        className={
                          productdetails?.repair_design_status === 1
                            ? showseq === 1 || showseq === 2
                              ? 'selection-process-single active-yellow '
                              : 'selection-process-single active-green'
                            : showseq >= 2
                              ? 'selection-process-single active-green '
                              : 'selection-process-single'
                        }
                      >
                        <img
                          className="selection-process-single-img img-gray"
                          src={base_assets + 'images/pos/steps/s8.png'}
                          alt=""
                        />
                        <img
                          className="selection-process-single-img img-green"
                          src={base_assets + 'images/pos/steps/s8-g.png'}
                          alt=""
                        />
                        <img
                          className="selection-process-single-img img-yellow"
                          src={base_assets + 'images/pos/steps/s8-y.png'}
                          alt=""
                        />
                        <span className="selection-process-single-name">
                          {t('Picture')}
                        </span>
                      </div>

                      <span
                        className={
                          generalsettings?.data?.generalsetup?.servicelabers ===
                            '1'
                            ? 'selection-process-line border-dashed'
                            : 'd-none'
                        }
                      ></span>

                      <div
                        className={
                          generalsettings?.data?.generalsetup?.servicelabers ===
                            '1'
                            ? productdetails?.repair_design_status === 1
                              ? showseq === 2
                                ? 'selection-process-single active-yellow '
                                : 'selection-process-single active-green'
                              : showseq > 2
                                ? 'selection-process-single active-green '
                                : 'selection-process-single'
                            : 'd-none'
                        }
                      >
                        <img
                          className="selection-process-single-img img-gray"
                          src={base_assets + 'images/pos/steps/s10.png'}
                          alt=""
                        />
                        <img
                          className="selection-process-single-img img-green"
                          src={base_assets + 'images/pos/steps/s10-g.png'}
                          alt=""
                        />
                        <img
                          className="selection-process-single-img img-yellow"
                          src={base_assets + 'images/pos/steps/s10-y.png'}
                          alt=""
                        />
                        <span className="selection-process-single-name">
                          {t('Service')}
                        </span>
                      </div>
                    </div>
                    <div className="create-own-setting-right-sketch-area-inner repair-order">
                      <div className="create-own-setting-right-sketch-area-inner-border">
                        {showseq === 0 ? (
                          <div className="create-own-setting-right-sketch-area-inner-concept">
                            <div>
                              <label className="create-own-setting-right-sketch-area-inner-concept-label mb-2">
                                {t('Description')}
                              </label>
                              <textarea
                                className="create-own-setting-right-sketch-area-inner-concept-text"
                                onChange={(e) =>
                                  customdesign('description', e.target.value)
                                }
                                value={
                                  productdetails?.repair_design?.description
                                    ? productdetails?.repair_design?.description
                                    : ''
                                }
                                placeholder=""
                              >
                                {' '}
                              </textarea>
                            </div>

                            <div className="mt-4">
                              <label className="create-own-setting-right-sketch-area-inner-concept-label">
                                {t('Repair list')}
                              </label>
                              <div className="create-own-setting-right-sketch-area-inner-concept-check-row">
                                {repairlist?.length
                                  ? repairlist.map((result, rekey) => {
                                    return (
                                      <div
                                        className="com-check"
                                        key={result?.id}
                                      >
                                        <input
                                          id={result?.id}
                                          checked={
                                            repaircheck?.length
                                              ? repaircheck.includes(
                                                result?.id,
                                              )
                                                ? true
                                                : false
                                              : ''
                                          }
                                          onChange={(e) => repaircheckbox(e)}
                                          type="checkbox"
                                        />
                                        <label
                                          htmlFor={result?.id}
                                          className="com-check-label"
                                        ></label>
                                        <label htmlFor={result?.id}>
                                          {result?.name}
                                        </label>
                                      </div>
                                    )
                                  })
                                  : ''}
                              </div>
                            </div>
                          </div>
                        ) : showseq === 1 ? (
                          <>
                            <div className="create-own-setting-right-sketch-area-inner-sketch"></div>
                            <div className="create-own-setting-right-sketch-area-inner-picture">
                              <p className="create-own-setting-right-sketch-area-inner-picture-label">
                                {t('Drag & Drop your pictures here')} <br />
                                {t('or')}
                              </p>
                              <div className="gallery-upload-area create-own-setting-right-sketch-area-inner-picture-upload">
                                <div className="App">
                                  <ImageUploading
                                    multiple
                                    value={images || ''}
                                    onChange={(e) => updateimage(e, 'update')}
                                    maxNumber={6}
                                    dataURLKey="data_url"
                                  >
                                    {({
                                      imageList,
                                      onImageUpload,
                                      onImageUpdate,
                                      onImageRemove,
                                    }) => (
                                      <div className="gallery-upload-area-wrap">
                                        <div className="create-own-setting-right-sketch-area-inner-picture-upload-btn-row">
                                          <button
                                            type="button"
                                            className="create-own-setting-right-sketch-area-inner-picture-upload-btn-row-btn btn-style"
                                            onClick={onImageUpload}
                                          >
                                            <img
                                              className="create-own-setting-right-sketch-area-inner-picture-upload-btn-row-btn-img"
                                              src={
                                                base_assets +
                                                'images/pos/icons/add-img.png'
                                              }
                                              alt=""
                                            />
                                          </button>
                                          <button className="create-own-setting-right-sketch-area-inner-picture-upload-btn-row-btn   btn-style">
                                            <img
                                              className="create-own-setting-right-sketch-area-inner-picture-upload-btn-row-btn-img"
                                              src={
                                                base_assets +
                                                'images/pos/icons/camera-icon-w.png'
                                              }
                                              alt=""
                                            />
                                          </button>
                                        </div>
                                        <div className="gallery-upload-area-wrap-row">
                                          {productdetails?.repair_design?.image_design?.length ?
                                            productdetails?.repair_design?.image_design.map((image, index) => (
                                              <div
                                                key={index}
                                                className="gallery-upload-area-wrap-row-img"
                                              >
                                                <img
                                                  className="img"
                                                  onClick={() =>
                                                    onImageUpdate(index)
                                                  }
                                                  src={image}
                                                  alt=""
                                                  width="100"
                                                />
                                                <button
                                                  type="button"
                                                  className="gallery-upload-area-wrap-row-img-close"
                                                  onClick={() => {
                                                    onImageRemove(index)
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      base_assets +
                                                      'images/icons/minus-detail.png'
                                                    }
                                                    alt=""
                                                  />
                                                </button>
                                              </div>
                                            )) : ""}
                                        </div>
                                      </div>
                                    )}
                                  </ImageUploading>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div
                            className={
                              generalsettings?.data?.generalsetup
                                ?.servicelabers === '1'
                                ? ''
                                : 'd-none'
                            }
                          >
                            <div
                              className="d-flex justify-content-between"
                            >
                              <div
                                className="switch-select-area text-start"
                                style={{ width: '23.625vw' }}
                              >
                                <div className="d-flex">
                                  <label className="service-modal-label pb-2 switch-select-area-label">
                                    {t('Service Labour')}
                                  </label>

                                  {showmodal || productdetails?.service_labour?.service_laburelist?.length ? (
                                    <span
                                      className="ms-auto danger-text cursor-pointer"
                                      style={{ fontSize: '0.9vw' }}
                                      onClick={() => {
                                        setProductdetails((state) => ({
                                          ...state,
                                          service_labour: {
                                            ...state?.service_labour,
                                            service_laburelist: [],
                                            service_remark: ''
                                          }
                                        }))
                                      }}
                                    >
                                      {t('Remove All')}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>

                                <div className="dropdown common-dropdown service-modal-content-dropdown switch-select-area-dropdown">
                                  <button
                                    className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="dropdownDiamond"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={switchActive}
                                  >
                                    {t('Service Labour')}
                                  </button>

                                  <ul
                                    className={
                                      isSwitchActive
                                        ? 'dropdown-menu switch-select-area-dropdown-menu active'
                                        : 'dropdown-menu switch-select-area-dropdown-menu'
                                    }
                                    aria-labelledby="dropdownDiamond"
                                  >
                                    {servicelabour?.length
                                      ? servicelabour.map((result, key) => {
                                        return (
                                          <li
                                            className="dropdown-item"
                                            key={key}
                                          >
                                            <div className="com-check">
                                              <input
                                                className="scrvicecheck"
                                                id={result?.id}
                                                checked={productdetails.service_labour.service_laburelist.some(item => item.id === result?.id)}
                                                data-code={result?.code}
                                                data-name={result?.name}
                                                type="checkbox"
                                                onChange={(e) => selectedproduct(e, result?.id, result?.code, result?.name, key)}
                                              />
                                              <label
                                                htmlFor={result?.id}
                                                className="com-check-label"
                                              ></label>
                                            </div>
                                            <label>{result?.code}</label>
                                            <label
                                              onClick={() => {
                                                setShowmodal(true)
                                                switchActive()
                                              }}
                                            >
                                              {result?.name}
                                            </label>
                                          </li>
                                        )
                                      })
                                      : ''}
                                  </ul>
                                  {showmodal || productdetails?.service_labour?.service_laburelist.length ? (
                                    <ul className="switch-select-area-dropdown-menu switch-select-area-dropdown-menu-2">
                                      {productdetails?.service_labour?.service_laburelist?.length
                                        ? productdetails?.service_labour?.service_laburelist.map((result, key) => {
                                          return (
                                            <li
                                              className="dropdown-item service-modal-content-dropdown-item"
                                              key={key}
                                            >
                                              <div className="">
                                                <img
                                                  style={{ width: '1.25vw' }}
                                                  onClick={() => {
                                                    deleteproduct(result?.id)
                                                  }}
                                                  src={
                                                    base_assets +
                                                    'images/pos/icons/red-delete.png'
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                              <div>{result?.code}</div>
                                              <div className="switch-select-area-dropdown-item-name">
                                                {result?.name}
                                              </div>
                                              <div className="ms-auto">
                                                {currency}
                                                <input
                                                  type="number"
                                                  value={
                                                    result?.scrviceamount
                                                      ? result?.scrviceamount
                                                      : ''
                                                  }
                                                  className="active-input arrow-none text-end"
                                                  onChange={(e) =>
                                                    scrviceamount(e, key)
                                                  }
                                                />
                                              </div>
                                            </li>
                                          )
                                        })
                                        : <li>{t("No Data Available")}</li>}
                                    </ul>
                                  ) : (
                                    <span> {t("No Data Available")}</span>
                                  )}
                                </div>
                              </div>
                              <div className="w-50">
                                <label className="create-own-setting-right-sketch-area-inner-concept-label mb-2">
                                  {t('Remark')}
                                </label>
                                <textarea
                                  className="create-own-setting-right-sketch-area-inner-concept-text"
                                  value={productdetails?.service_labour?.service_remark}
                                  placeholder="Remark"
                                  onChange={(e) =>
                                    setProductdetails((state) => {
                                      if (
                                        state?.service_labour?.service_laburelist?.length &&
                                        e.target.value.trim() !== ""
                                      ) {
                                        return {
                                          ...state,
                                          service_labour: {
                                            ...state.service_labour,
                                            service_remark: e.target.value,
                                          },
                                        };
                                      }
                                      return state;
                                    })
                                  }
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="create-own-setting-right-sketch-area-inner-btn">
                        {showseq > 0 && (
                          <button
                            onClick={() => setShowseq(showseq - 1)}
                            className="hover-effect-inner com-btn com-btn-border"
                          >
                            {t('back')}
                          </button>
                        )}
                        {generalsettings?.data?.generalsetup?.servicelabers ===
                          '1' && (
                            <>
                              {showseq <= 1 && (
                                <button
                                  className="hover-effect-inner com-btn"
                                  onClick={() => setShowseq(showseq + 1)}
                                >
                                  {t('next')}
                                </button>
                              )}

                              {showseq === 2 && (
                                <button
                                  className="hover-effect-inner com-btn"
                                  onClick={() => {
                                    scrvicelabure()
                                    submitdetails()
                                    setCartseq(
                                      customCartdetail?.length - 1 > cartseq
                                        ? cartseq + 1
                                        : 0,
                                    )
                                    setCustomsecrive(false)
                                  }}
                                >
                                  {t('finish')}
                                </button>
                              )}
                            </>
                          )}

                        {generalsettings?.data?.generalsetup?.servicelabers !==
                          '1' && (
                            <>
                              {showseq === 1 && (
                                <button
                                  className="hover-effect-inner com-btn"
                                  onClick={() => {
                                    submitdetails()
                                    setCartseq(
                                      customCartdetail?.length - 1 > cartseq
                                        ? cartseq + 1
                                        : 0,
                                    )
                                  }}
                                >
                                  {t('finish')}
                                </button>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            {showcheckout === 'sell' ? (
              <CheckoutSell
                setShowcheckout={setShowcheckout}
                setdeletestatus={setdeletestatus}
                setUpdatedCart={setUpdatedCart}
                UpdatedCart={UpdatedCart}
                setShowpayment={setShowpayment}
                showpayment={showpayment}
                searchproductstatus={searchproductstatus}
                setsearchproductstatus={setsearchproductstatus}
                ordertype={'repair_order'}
                order_type={state?.order_type}
                order_id={state?.order_id}
                order_edit={state?.order_edit}
                customsecrive={customsecrive}
              />
            ) : showcheckout === 'payment' ? (
              <CheckoutPayment
                UpdatedCart={UpdatedCart}
                setShowcheckout={setShowcheckout}
                order_type={state?.order_type}
                order_id={state?.order_id}
                order_edit={state?.order_edit}
              />
            ) : (
              <CheckoutBlank />
            )}
          </div>
        </div>
      </div>
      <ProductModal
        data_product={productfilterdetails}
        setOneproduct={setProductfilterdetails}
        editproduct_submit={editproduct_submit}
      />
    </>
  )
}

export default RepairOrderProcess