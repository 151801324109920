import { useTranslation } from 'react-i18next';
import CircledTick from '../../common/icons/sidebar/CircledTick';
import { useCallback, useEffect, useRef, useState } from 'react';
import UserIcon from '../../common/icons/UserIcon';
import { _Api } from '../../api/_call';
import KanbanModal from './KanbanModal';
import ConfirmationPopup from './ConfirmationPopup';
import CloseIcon from '../../common/icons/CloseIcon';
import FilterIconMain from '../../common/icons/sidebar/FilterIconMain';
import { Link } from 'react-router-dom';
import AgentModal from '../../../components/common/modules/agentmodal';
import BacklogFilter from './BacklogFilter';


export default function Stock({ setApplyfilter, applyfilter }) {

    const { t } = useTranslation();
    const modalRef = useRef(null);
    const agentmodalRef = useRef(null);
    const stockBacklogRef = useRef(null);
    const [draggedItem, setDraggedItem] = useState();
    const [STlist, setSTlist] = useState([]);
    const [targetCard, setTargetCard] = useState();
    const [confirmationResponse, setConfirmationResponse] = useState('');
    const [activeLocation, setActiveLocation] = useState();
    const [GetOrderDetails, setGetOrderDetails] = useState({ order_id: "", order_number: "", order_type: "", order_status: "" });
    const [agentvalue, setAgentvalue] = useState()
    const [AWBvalues, setAWBvalue] = useState()
    const [showbacklogFilters, setShowbacklogFilters] = useState(false);
    const [backlogFilters, setBacklogFilters] = useState({ "create_at": ["Oldest"],  "order_type": []})

    const handleDragStart = (event, data, index) => {
        console.log(event, data, index, "This is draggable")
        let dummydata = index === 1 ? 'pending' : index === 2 ? 'approved' : index === 3 ? 'cancel' : index === 4 ? 'completed' : index === 5 ? 'transit' : 'error'
        if (dummydata !== 'error') {
            //     let newData = getData?.[dummydata]
            //     newData = newData.filter((result)=> result.item !== data.item);
            //    setGetData(prev => ({...prev, [dummydata] : newData}))
            setDraggedItem([dummydata, data]);
        }
    };

    useEffect(() => {
        handleDragandDrop();
        // eslint-disable-next-line
    }, [confirmationResponse])
  
    const handleDragandDrop = async () => {
        const modalElement = modalRef.current;
        const bootstrapModal = window.bootstrap.Modal.getOrCreateInstance(modalElement);
        bootstrapModal.hide();
        if (confirmationResponse === 'confirm') {
            try {
                let postdata = {
                    "logistic_id": "",
                    "awb_number": "",
                    "status": (draggedItem[0] === 'pending' && targetCard === 'approved') || (draggedItem[0] === 'transit' && targetCard === 'approved') ? 1 : (draggedItem[0] === 'approved' && targetCard === 'pending') || (draggedItem[0] === 'cancel' && targetCard === 'pending') ? 2 : (draggedItem[0] === 'approved' && targetCard === 'transit' ? 3 : (draggedItem[0] === 'pending' && targetCard === 'cancel') ? 0 : (draggedItem[0] === 'transit' && targetCard === 'completed') ? 4 : null),
                    "stock_transfer_id": draggedItem[1]._id
                }
                let res = await _Api(postdata, `api/v1/Inventory/stocktransfer/editStockTransferStatus`);
                if (res?.code === 200) {
                    getKanbanSTlistApi();

                }
            }
            catch (err) {
                console.log(err, "err")
            }
            setConfirmationResponse('')
            setDraggedItem([]);
        } else {
            setConfirmationResponse('');
            setDraggedItem([]);
        }
    }

    const handleDrop = (event, targetindex) => {
        event.preventDefault();
        let dummydata = targetindex === 1 ? 'pending' : targetindex === 2 ? 'approved' : targetindex === 3 ? 'cancel' : targetindex === 4 ? 'completed' : targetindex === 5 ? 'transit' : 'error'
        console.log(draggedItem, dummydata, targetindex, "handle drop")

        if ((draggedItem[0] === 'pending' && dummydata === 'approved') || (draggedItem[0] === 'pending' && dummydata === 'cancel') || (draggedItem[0] === 'approved' && dummydata === 'pending') ||  (draggedItem[0] === 'transit' && dummydata === 'approved') || (draggedItem[0] === 'cancel' && dummydata === 'pending') || (draggedItem[0] === 'transit' && dummydata === 'completed')) {
            const modalElement = modalRef.current;
            const bootstrapModal = window.bootstrap.Modal.getOrCreateInstance(modalElement);
            bootstrapModal.show();
            setTargetCard(prev => prev = dummydata);
        } else {
            if((draggedItem[0] === 'approved' && dummydata === 'transit')){
                const modalElement = agentmodalRef.current;
                const bootstrapModal = window.bootstrap.Modal.getOrCreateInstance(modalElement);
                bootstrapModal.show();
            }else{
                setDraggedItem(null);
            }
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handlelocationitems = (result) => {
        if (activeLocation?.location_id === result?.location_id) {
            setActiveLocation(null)
        } else {
            setActiveLocation(prev => prev = result)
        }
    }

    const getKanbanSTlistApi = useCallback(async () => {
        try {
            let postdata = {
                "order_type": applyfilter?.order_type?.length ? applyfilter?.order_type : [],
                "location": applyfilter?.location?.length ? applyfilter?.location : [],
                "create_at": applyfilter?.create_at ? applyfilter?.create_at : [],
                "search": applyfilter?.search ? applyfilter?.search : "",
                "duesFilters" : applyfilter?.duesFilters ? applyfilter?.duesFilters : [],
                "backlog": {"create_at": backlogFilters?.create_at ? backlogFilters?.create_at : [], "location":[], "order_type": backlogFilters?.order_type ? backlogFilters?.order_type : []}
            }
            let res = await _Api(postdata, `api/v1/App/kanban/getkanbanSTlist`);
            if (res?.code === 200) {
                setSTlist(res?.data);
                setActiveLocation(null);
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }, [applyfilter, backlogFilters]);

    useEffect(() => {
        if(Array.isArray(applyfilter?.location)){
        getKanbanSTlistApi();
        }
    }, [getKanbanSTlistApi])

    const Getstocktransferstatus = async () => {
        if(AWBvalues && agentvalue){
        try {
            let postdata = {
                "logistic_id": agentvalue?.value ? agentvalue?.value : '' ,
                "awb_number": AWBvalues ? AWBvalues : '',
                "status": 3,
                "stock_transfer_id": draggedItem[1]._id
            }
            let res = await _Api(postdata, `api/v1/Inventory/stocktransfer/editStockTransferStatus`);
            if (res?.code === 200) {
                getKanbanSTlistApi();

            }
        }
        catch (err) {
            console.log(err, "err")
        }
        }
        
        setConfirmationResponse('')
        setDraggedItem([]);
        setAWBvalue('')
        setAgentvalue('')
      }

      const handler_dismiss = () =>{
        const modalElement = agentmodalRef.current;
        const bootstrapModal = window.bootstrap.Modal.getOrCreateInstance(modalElement);
        bootstrapModal.hide();
        setAWBvalue('');
        setAgentvalue('');
        setConfirmationResponse('')
        setDraggedItem([]);
    }

    useEffect(() => {
        // Function to close popup when clicked outside
        const handleClickOutside = (event) => {
          if (stockBacklogRef.current && !stockBacklogRef.current.contains(event.target)) {
            setShowbacklogFilters(false);
          }
        };
    
        // Attach event listener to the document
        document.addEventListener('mousedown', handleClickOutside);
    
        // Clean up the event listener on component unmount
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

      const handleShowFilter = () => {
        setShowbacklogFilters(!showbacklogFilters)
      }

      const getShortForm = (location) => {
        const words = location.split(' ');
        const initials = words.map(word => word.charAt(0).toUpperCase());
        return initials.join('');
    }

    return (
        <div className='kanbanpage-content-po'>
            <div className='kanbanpage-content-po-cardcontainer'>
                {/* backlog card started */}
                {applyfilter?.Status?.includes('Backlog') || applyfilter?.Status?.length <= 0 ? 
                <div className={`kanbanpage-content-po-cardcontainer-card ${showbacklogFilters ? 'withfiltersmall' : ""}`} ref={stockBacklogRef}>
                    <div className='kanbanpage-content-po-cardcontainer-card-top'>
                        <div className='kanbanpage-content-po-cardcontainer-card-top-title'>
                            <span className='kanbanpage-content-po-cardcontainer-card-top-title-icon'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 5H21M11 9H16" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6 5H4C3.44772 5 3 5.44772 3 6V8C3 8.55228 3.44772 9 4 9H6C6.55228 9 7 8.55228 7 8V6C7 5.44772 6.55228 5 6 5Z" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11 15H21M11 19H16" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6 15H4C3.44772 15 3 15.4477 3 16V18C3 18.5523 3.44772 19 4 19H6C6.55228 19 7 18.5523 7 18V16C7 15.4477 6.55228 15 6 15Z" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                            <span className='text'>{t("Backlog")}</span>
                        </div>

                        <div className='kanbanpage-content-po-cardcontainer-card-top-count'>
                            {STlist?.backlogs?.length || 0}
                        </div>
                    </div>
                    <div className='kanbanpage-content-po-cardcontainer-card-subhead'>
                            <Link className='plusicon'
                                to={"/app/inventory/createTransferInventory"}
                                state={{
                                    // location_id: createlist,
                                    // Voucher_id: commanSelected_vouc,
                                    // hidelocationid: selectedlocation,
                                    // showwarehouseid: selectedwarehouse,
                                    // myinventory: false
                                }}><span>{t('Create')}</span><CloseIcon /></Link>
                        <span className='filtericon' onClick={()=>setShowbacklogFilters(!showbacklogFilters)}><span>{t('Filter')}</span><FilterIconMain width={24} height={24} /></span>
                    </div>
                    <div className='kanbanpage-content-po-cardcontainer-card-lists'>
                        {
                            STlist?.backlogs?.map((result, index) => {
                                return (
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content backlog' key={index}>
                                        <div className="custom-checkbox"><input type='checkbox' onClick={() => handlelocationitems(result)} checked={result?.location_id === activeLocation?.location_id ? true : false} /><label htmlFor='myCheckbox' /></div>
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-detail'>
                                                <div className='title'>
                                                    <span className='location-name-ellipsis titletext'>{result?.location_name}
                                                        {/* <span className='location-name-ellipsis-tooltip'>{result?.location_name}</span> */}
                                                    </span>
                                                    <span className='numaric'>({result?.totalQty || 0})</span>
                                                    {result?.PosQty > 0 ? (
                                                        <>
                                                            <span className='iconholder kanbanpage-tooltipholder'>
                                                                <UserIcon />
                                                                <span className='kanbanpage-tooltip'>{getShortForm(result?.location_name)} {result?.pu_no}</span>
                                                            </span>
                                                            <span className='numaric'>{result?.PosQty || 0}</span>
                                                        </>
                                                    ) : ""}
                                                </div>
                                                <div className='date'>{result?.Date}</div>
                                            </div>
                                            {/* <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-tag'>
                                                {result.location_name}
                                            </div> */}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {showbacklogFilters ? <BacklogFilter handleShowFilter={handleShowFilter} backlogFilters={backlogFilters} setBacklogFilters={setBacklogFilters} type="stock"/> : "" }
                </div>
                :""}
                {/* backlog card ended */}
                {/* pending card started */}
                {applyfilter?.Status?.includes('Pending') || applyfilter?.Status?.length <= 0 ? 
                <div className='kanbanpage-content-po-cardcontainer-card'>
                    <div className='kanbanpage-content-po-cardcontainer-card-top pending'
                    >
                        <div className='kanbanpage-content-po-cardcontainer-card-top-title'>
                            <span className='kanbanpage-content-po-cardcontainer-card-top-title-icon'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 6V10L13 13M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                            <span className='text'>{t("Pending")}</span>
                        </div>
                        <div className='kanbanpage-content-po-cardcontainer-card-top-count'>
                            {STlist?.pending?.length || 0}
                        </div>
                    </div>
                    <div className='kanbanpage-content-po-cardcontainer-card-lists' onDrop={(e) => handleDrop(e, 1)}
                        onDragOver={handleDragOver}>
                        {
                            draggedItem?.[0] === 'approved' || draggedItem?.[0] === 'cancel' ?
                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content draganddrop'>
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                        <div>Drag and Drop here</div>
                                    </div>
                                </div>
                                : ""
                        }
                        {STlist?.pending?.map((pending, index) => {
                            return (
                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content cursor-grab'
                                    key={index}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, pending, 1)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#showtaskmodel"
                                    data-id={pending?._id}
                                    onClick={() => setGetOrderDetails({ order_id: pending?._id, order_number: pending?.st_no, order_type: "ST", order_status: 2 })}

                                >
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-detail'>
                                            <div className='title'><span>{pending?.st_no}</span></div>
                                            <div className='date'
                                                style={{
                                                    color:
                                                        pending.Date.includes("Due in") ? "#FABB2D" :
                                                            pending.Date.includes("Overdue") ? "#FF5757" : ""
                                                }}
                                            >{pending?.Date}</div>
                                        </div>
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-tag'>
                                            {pending.location_name}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                :""}
                {/* pending card ended */}
                {/* approved card started */}
                {applyfilter?.Status?.includes('Approved') || applyfilter?.Status?.length <= 0 ? 
                <div className='kanbanpage-content-po-cardcontainer-card'>
                    <div className='kanbanpage-content-po-cardcontainer-card-top approved'>
                        <div className='kanbanpage-content-po-cardcontainer-card-top-title'>
                            <span className='kanbanpage-content-po-cardcontainer-card-top-title-icon'>
                                <CircledTick />
                            </span>
                            <span className='text'>{t("Approved")}</span>
                        </div>
                        <div className='kanbanpage-content-po-cardcontainer-card-top-count'>
                            {STlist?.approved?.length || 0}

                        </div>
                    </div>
                    <div className='kanbanpage-content-po-cardcontainer-card-lists' onDrop={(e) => handleDrop(e, 2)}
                        onDragOver={handleDragOver}>
                        {draggedItem?.[0] === 'transit' || draggedItem?.[0] === 'pending' ?
                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content draganddrop'>
                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                    <div>Drag and Drop here</div>
                                </div>
                            </div>
                            :
                            ""}
                        {
                            STlist?.approved?.map((approve, index) => {
                                return (
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content cursor-grab' key={index}
                                        draggable
                                        onDragStart={(e) => handleDragStart(e, approve, 2)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#showtaskmodel"
                                        data-id={approve?._id}
                                        onClick={() => setGetOrderDetails({ order_id: approve?._id, order_number: approve?.st_no, order_type: "ST", order_status: 1 })}

                                    >
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-detail'>
                                                <div className='title'><span>{approve?.st_no}</span></div>
                                                <div className='date'>{approve?.Date}</div>
                                            </div>
                                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-tag'>
                                                {approve?.location_name}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                :""}
                {/* approved card ended */}
                {/* transit card started */}
                {applyfilter?.Status?.includes('Transit') || applyfilter?.Status?.length <= 0 ? 
                <div className='kanbanpage-content-po-cardcontainer-card'>
                    <div className='kanbanpage-content-po-cardcontainer-card-top transit'>
                        <div className='kanbanpage-content-po-cardcontainer-card-top-title'>
                            <span className='kanbanpage-content-po-cardcontainer-card-top-title-icon'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 12L10 10M10 10L12 8M10 10L8 8M10 10L12 12M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                            <span className='text'>{t("Transit")}</span>
                        </div>
                        <div className='kanbanpage-content-po-cardcontainer-card-top-count'>
                            {STlist?.transit?.length}
                        </div>
                    </div>

                    <div className='kanbanpage-content-po-cardcontainer-card-lists' onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, 5)}>
                        {draggedItem?.[0] === 'approved' ?
                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content draganddrop'>
                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                    <div>Drag and Drop here</div>
                                </div>
                            </div>
                            : ""}
                        {STlist?.transit?.map((transit, index) => {
                            return (
                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content cursor-grab' key={index}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, transit, 5)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#showtaskmodel"
                                    data-id={transit?._id}
                                    onClick={() => setGetOrderDetails({ order_id: transit?._id, order_number: transit?.st_no, order_type: "ST", order_status: 3 })}

                                >
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-detail'>
                                            <div className='title'><span>{transit?.st_no}</span></div>
                                            <div className='date'>{transit?.Date}</div>
                                        </div>
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-tag'>
                                            {transit?.location_name}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                :""}
                {/* transit card ended */}
                {/* complete card started */}
                {applyfilter?.Status?.includes('Completed') || applyfilter?.Status?.length <= 0 ? 
                <div className='kanbanpage-content-po-cardcontainer-card'>
                    <div className='kanbanpage-content-po-cardcontainer-card-top completed'>
                        <div className='kanbanpage-content-po-cardcontainer-card-top-title'>
                            <span className='kanbanpage-content-po-cardcontainer-card-top-title-icon'>
                                <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 1V5C10 5.26522 10.1054 5.51957 10.2929 5.70711C10.4804 5.89464 10.7348 6 11 6H15" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H10L15 6V11M15 11V19Z" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5 11.8125L6.6875 13.5L11.1875 9" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                            <span className='text'>{t("Completed")}</span>
                        </div>
                        <div className='kanbanpage-content-po-cardcontainer-card-top-count'>
                            {STlist?.complete?.length}
                        </div>
                    </div>
                    <div className='kanbanpage-content-po-cardcontainer-card-lists' onDrop={(e) => handleDrop(e, 4)}
                        onDragOver={handleDragOver}>
                        {draggedItem?.[0] === 'transit' ?
                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content draganddrop'>
                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                    <div>Drag and Drop here</div>
                                </div>
                            </div>
                            :
                            ""}
                        {STlist?.complete?.map((complete, index) => {
                            return (
                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content' key={index}
                                    data-bs-toggle="modal"
                                    data-bs-target="#showtaskmodel"
                                    data-id={complete?._id}
                                    onClick={() => setGetOrderDetails({ order_id: complete?._id, order_number: complete?.st_no, order_type: "ST", order_status: 4 })}


                                >
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-detail'>
                                            <div className='title'><span>{complete?.st_no}</span></div>
                                            <div className='date'>{complete?.Date}</div>
                                        </div>
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-tag'>
                                            {complete?.location_name}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                :""}
                {/* complete card ended */}
                {/* cancel card started */}
                {applyfilter?.Status?.includes('Cancel') || applyfilter?.Status?.length <= 0 ? 
                <div className='kanbanpage-content-po-cardcontainer-card'>
                    <div className='kanbanpage-content-po-cardcontainer-card-top cancel'>
                        <div className='kanbanpage-content-po-cardcontainer-card-top-title'>
                            <span className='kanbanpage-content-po-cardcontainer-card-top-title-icon'>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 12L10 10M10 10L12 8M10 10L8 8M10 10L12 12M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#212121" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                            <span className='text'>{t("Cancel")}</span>
                        </div>
                        <div className='kanbanpage-content-po-cardcontainer-card-top-count'>
                            {STlist?.cancel?.length}
                        </div>
                    </div>
                    <div className='kanbanpage-content-po-cardcontainer-card-lists' onDrop={(e) => handleDrop(e, 3)}
                        onDragOver={handleDragOver}>
                        {draggedItem?.[0] === 'pending' ?
                            <div className='kanbanpage-content-po-cardcontainer-card-lists-content draganddrop'>
                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                    <div>Drag and Drop here</div>
                                </div>
                            </div>
                            : ""}
                        {STlist?.cancel?.map((canceldata, index) => {
                            return (
                                <div className='kanbanpage-content-po-cardcontainer-card-lists-content cursor-grab' key={index}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, canceldata, 3)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#showtaskmodel"
                                    data-id={canceldata?._id}
                                    onClick={() => setGetOrderDetails({ order_id: canceldata?._id, order_number: canceldata?.st_no, order_type: "ST", order_status: 0 })}

                                >
                                    <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data'>
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-detail'>
                                            <div className='title'><span>{canceldata?.st_no}</span></div>
                                            <div className='date'>{canceldata?.Date}</div>
                                        </div>
                                        <div className='kanbanpage-content-po-cardcontainer-card-lists-content-data-tag'>
                                            {canceldata?.location_name}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                :""}
                {/* cancel card ended */}
            </div>
            <ConfirmationPopup setConfirmationResponse={setConfirmationResponse} modalRef={modalRef} />
            <KanbanModal
                GetOrderDetails={GetOrderDetails}
                Activity={
                    GetOrderDetails?.order_status === 4
                        ? t('Stock Received')
                        : t('Transfer to')
                }
            />
            <AgentModal
                handler_submit={Getstocktransferstatus}
                handler_dismiss={handler_dismiss}
                setAgentvalue={setAgentvalue}
                setAWBvalue={setAWBvalue}
                agentmodalRef={agentmodalRef}
            ></AgentModal>
        </div>
    )
}
