import React, { useEffect, useRef, useState } from 'react'
import './CustomerInformation.scss';
import { useTranslation } from 'react-i18next';
import Select, { components } from "react-select";
import AddressSection from './AddressSection';
import { Nationality } from "../../../pos/common/module/nationalityarray";
import { _Api, graphqlPost } from '../../api/_call';
import { error, loading, Updateloading } from '../../common/helpers/toastify';
import { useSelector } from 'react-redux';
export default function CustomerInformation(props) {
  const { cusid, setCusid } = props
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [addaddressobj, setAddaddressobj] = useState({ billing_address: {}, shipping_address: {} })
  const { ValueContainer, Placeholder } = components;
  const [showAddContent, setShowAddContent] = useState();
  const [getdates, setDates] = useState({ date: [], month: [], year: [] })
  const [selectdata, setSelectdata] = useState();
  const [addressindex, setAddressindex] = useState(null)
  const mobileImage = <img src={base_assets + "images/icons/phone-icon-1.png"} alt="" />;
  const phoneImage = <img src={base_assets + "images/icons/phone-icon-2.png"} alt="" />;
  const [idModal, setIdModal] = useState({
    openmodal: false,
    index: 0
  })
  const [completeDataObject, setCompleteObject] = useState({
    govt_ids: [{ idtype: "", idnumber: "", id_image: "" }],
    contacts: [{ phoneCode: '93', icon: 1, number: "" }],
    emails: [],
    privacy: {
      data_handling: true,
      contact_term: true,
      cardcreation: true,
    },
    status: "0",
    shipping_address: [],
    billing_address: []

  })
  const phoneOneOption = [
    {
      value: 1,
      icon: <img src={base_assets + "images/icons/phone-icon-1.png"} alt="" />
    },
    {
      value: 2,
      icon: <img src={base_assets + "images/icons/phone-icon-2.png"} alt="" />
    }
  ];

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  const setallobject = (keyName, value) => {
    if (keyName === "country") {
      handleSelectChange(
        completeDataObject?.contacts?.[0]?.icon,
        0,
        value?.phonecode,
        completeDataObject?.contacts?.[0]?.number
      )
    }
    setCompleteObject((state) => ({
      ...state,
      [keyName]: value
    }))
  }

  let isArabic = localStorage.getItem('UserLang');
  const behaviorOption = [
    {
      value: "Polite",
      label: "Polite",
      icon: <i className="fa fa-smile-o me-2 fs-6 lighgreencolor" aria-hidden="true"></i>,
    },
    {
      value: "Good",
      label: "Good",
      icon: <i className="fa fa-smile-o me-2 fs-6 lighgreencolor" aria-hidden="true"></i>,
    }
  ];

  const genderoption = [
    {
      value: "Male",
      label: t("Male"),
      icon: <img src={base_assets + "images/icons/male-icon.png"} alt="" />,
    },
    {
      value: "Female",
      label: t("Female"),
      icon: <img src={base_assets + "images/icons/female-icon.png"} alt="" />,
    },
    {
      value: "Group",
      label: t("Group"),
      icon: <img src={base_assets + "images/icons/group.png"} alt="" />,
    },
    {
      value: "Other",
      label: t("Other"),
      icon: <img src={base_assets + "images/icons/transgenders-icon.png"} alt="" />,
    },
  ];
  const groupoption = [
    {
      value: "Silver",
      label: t("Silver"),
    },
    {
      value: "Gold",
      label: t("Gold"),
    },
    {
      value: "Platinum",
      label: t("Platinum"),
    },
    {
      value: "Diamond",
      label: t("Diamond"),
    },
  ];
  const titleoption = [
    {
      value: "Mr",
      label: t("Mr."),
    },
    {
      value: "Miss",
      label: t("Miss"),
    },
    {
      value: "Mrs",
      label: t("Mrs"),
    },
    {
      value: "Ms",
      label: t("Ms"),
    },
  ];

  const occasionoption = [
    {
      value: "Anniversary",
      label: t("Anniversary"),
      icon: <img src={base_assets + "images/icons/Anniversary.png"} alt="" />,
    },
    {
      value: "Gift",
      label: t("Gift"),
      icon: <img src={base_assets + "images/icons/gift-icon.png"} alt="" />,
    },
    {
      value: "Personal",
      label: t("Personal"),
      icon: <img src={base_assets + "images/icons/personal-icon.png"} alt="" />,
    },
    {
      value: "Prize",
      label: t("Prize"),
      icon: <img src={base_assets + "images/icons/prize.png"} alt="" />,
    },
    {
      value: "Wedding",
      label: t("Wedding"),
      icon: <img src={base_assets + "images/icons/wedding.png"} alt="" />,
    },
    {
      value: "Other",
      label: t("Other"),
      icon: <img src={base_assets + "images/icons/other.png"} alt="" />,
    },
  ];
  const idoption = [
    {
      value: "Passport",
      label: t("Passport"),
    },
    {
      value: "Driving License",
      label: t("Driving License"),
    },
    {
      value: "Citizen ID",
      label: t("Citizen ID"),
    },
  ];
  const sourceoption = [
    {
      value: "Facebook",
      label: t("Facebook"),
      icon: <img src={base_assets + "images/icons/facebook.png"} alt="" />,
    },
    {
      value: "Friends & Famliy",
      label: t("Friends & Famliy"),
      icon: <img src={base_assets + "images/icons/friends-and-family.png"} alt="" />,
    },
    {
      value: "Instagram",
      label: t("Instagram"),
      icon: <img src={base_assets + "images/icons/instagram.png"} alt="" />,
    },
    {
      value: "Snapchat",
      label: t("Snapchat"),
      icon: <img src={base_assets + "images/icons/snapchat.png"} alt="" />,
    },
    {
      value: "Social Media",
      label: t("Social Media"),
      icon: <img src={base_assets + "images/icons/social-media.png"} alt="" />,
    },
    {
      value: "Magazine",
      label: t("Magazine"),
      icon: <img src={base_assets + "images/icons/magzine.png"} alt="" />,
    },
    {
      value: "Event",
      label: t("Event"),
      icon: <img src={base_assets + "images/icons/event.png"} alt="" />,
    },
    {
      value: "Walk-in",
      label: t("Walk-in"),
      icon: <img src={base_assets + "images/icons/walk-in.png"} alt="" />,
    },
  ];

  const callAge = (e) => {
    const current = new Date();
    const year = current.getFullYear();
    const calage = year - e.value;
    setCompleteObject((state) => ({
      ...state,
      age: calage
    }))
  };
  const getCountry = async () => {
    try {
      const postdata = `{
                   countries{
                    name
                    id
                    sortname
                    phoneCode  
                    }
                  }`;
      let res = await graphqlPost(postdata, "App/graphql");
      let countryarray = [];
      let phonecodearray_ = [];
      res?.data?.countries.forEach((cnames) => {
        countryarray.push({
          value: cnames?.id,
          label: cnames?.name,
          phonecode: cnames?.phoneCode,
        });
        phonecodearray_.push({
          label: cnames?.phoneCode,
          value: cnames?.phoneCode,
        });
      });
      setSelectdata((state) => ({
        ...state,
        Phonecodearray: phonecodearray_,
        Countryarray: countryarray
      }))
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    getCountry()
    const Dateoption = [...new Array(31)].map((each, index) => ({
      label: index + 1,
      value: index + 1,
    }));
    setDates((state) => ({
      ...state,
      date: Dateoption
    }))
    const monthoption = [
      {
        value: 1,
        label: "JAN",
      },
      {
        value: 2,
        label: "FEB",
      },
      {
        value: 3,
        label: "MAR",
      },
      {
        value: 4,
        label: "APR",
      },
      {
        value: 5,
        label: "MAY",
      },
      {
        value: 6,
        label: "JUN",
      },
      {
        value: 7,
        label: "JUL",
      },
      {
        value: 8,
        label: "AUG",
      },
      {
        value: 9,
        label: "SEP",
      },
      {
        value: 10,
        label: "OCT",
      },
      {
        value: 11,
        label: "NOV",
      },
      {
        value: 12,
        label: "DEC",
      },

    ]
    setDates((state) => ({
      ...state,
      month: monthoption
    }))
    const current = new Date();
    const lastyear = current.getFullYear() - 5;
    const firstyear = lastyear - 50;
    const yearoption = [...new Array(lastyear - firstyear + 2)]
      .fill()
      .map((_, idx) => ({ label: firstyear + idx, value: firstyear + idx }));
    setDates((state) => ({
      ...state,
      year: yearoption
    }))
    if (Nationality?.length) {
      let nationalityoption = [];
      Nationality.forEach((result) => {
        nationalityoption.push({
          value: result,
          label: result,
        });
      });
      setSelectdata((state) => ({
        ...state,
        Nationality: nationalityoption
      }));
    }
  }, []);
  const onImageChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader(file);
    reader.onloadend = async () => {
      try {
        const postdata = {
          image: reader.result,
          name: "profile",
        };
        let resfront = await _Api(postdata, `api/v1/APP/customer/uploadimage`);
        if (resfront.code === 200) {
          setCompleteObject((state) => ({
            ...state,
            profile: resfront?.data?.images?.url,
          }));
        }
      } catch (err) {
        console.log(err, "err");
      }
    };
    if (file) {
      reader?.readAsDataURL(file);
    }
  }
  const onidimageChange = (idtype, idnumber, e) => {
    let index = e.target.name;
    const [file] = e.target.files;
    const reader = new FileReader(file);
    reader.onloadend = async () => {
      try {
        const postdata = {
          image: reader.result,
          name: "profile",
        };
        let resfront = await _Api(postdata, `api/v1/APP/customer/uploadimage`);
        if (resfront.code === 200) {
          setCompleteObject((prevState) => {
            const newValues_ = { ...prevState };
            const newValues = [...newValues_?.govt_ids];
            newValues[index] = {
              idtype: idtype,
              idnumber: idnumber,
              id_image: resfront?.data?.images?.url,
            };
            newValues_.govt_ids = newValues;
            return newValues_;
          });
        }
      } catch (err) {
        console.log(err, "err");
      }
    };
    if (file) {
      reader?.readAsDataURL(file);
    }
  };
  const handleRemoveElement = (indexToRemove, label) => {
    if (label === "emails") {
      let newValues_ = { ...completeDataObject }
      let updatedArray = newValues_.emails.filter((_, index) => index !== indexToRemove);
      setCompleteObject((prevState) => ({
        ...prevState,
        emails: updatedArray,
      }))
    } else if (label === "phone") {
      let newValues_ = { ...completeDataObject }
      let updatedArray = newValues_.contacts.filter((_, index) => index !== indexToRemove);
      setCompleteObject((prevState) => ({
        ...prevState,
        contacts: updatedArray,
      }))
    } else if (label === "govt_id") {
      let newValues_ = { ...completeDataObject }
      let updatedArray = newValues_.govt_ids.filter((_, index) => index !== indexToRemove);
      setCompleteObject((prevState) => ({
        ...prevState,
        govt_ids: updatedArray,
      }))
    }


  };
  const addEventList = (lable) => {
    if (lable === "ID") {
      let newValues_ = { ...completeDataObject }
      if (!newValues_?.govt_ids) {
        newValues_.govt_ids = []
      }
      let newValues = [...newValues_?.govt_ids]
      newValues.push({ idtype: "", idnumber: "", id_image: "" });
      newValues_.govt_ids = newValues
      setCompleteObject((prevState) => ({
        ...prevState,
        govt_ids: newValues,
      }))
    } else if (lable === "emails") {
      let newValues_ = { ...completeDataObject }
      let newValues = [...newValues_?.emails]
      newValues_.emails = newValues
      newValues.push("");
      setCompleteObject((prevState) => ({
        ...prevState,
        emails: newValues,
      }))
    }
    else {
      let newValues_ = { ...completeDataObject }
      let newValues = [...newValues_?.contacts]
      newValues.push({ phoneCode: '93', icon: 1, number: "" });
      newValues_.contacts = newValues
      setCompleteObject((prevState) => ({
        ...prevState,
        contacts: newValues,
      }))
    }
  };
  const handleSelectChange = (selectedOption, index, code, number, lable) => {
    if (lable === "govt_id") {
      let newValues_ = { ...completeDataObject }
      let newValues = [...newValues_?.govt_ids]
      newValues[index] = { idtype: selectedOption, idnumber: code, id_image: number };
      newValues_.govt_ids = newValues
      setCompleteObject(newValues_)
    }
    else if (lable === "emails") {
      let newValues_ = { ...completeDataObject }
      let newValues = [...newValues_?.emails]
      newValues[index] = number;
      newValues_.emails = newValues
      setCompleteObject(newValues_)
    }
    else {
      let newValues_ = { ...completeDataObject }
      if (!newValues_?.contacts?.length) {
        newValues_.contacts = [{ phoneCode: '93', icon: 1, number: "" }]
      }
      let newValues = [...newValues_?.contacts]
      newValues[index] = { icon: selectedOption, phoneCode: code, number: number };
      newValues_.contacts = newValues
      setCompleteObject(newValues_)
    }
  };
  const createcustomer = async () => {
    const validection = () => {
      let fromisvalid = true
      if (!completeDataObject?.nationality) {
        error("Nationality is required")
        return;
      }
      if (!completeDataObject?.title) {
        error("Title is required")
        return;
      }
      if (!completeDataObject?.fname) {
        error("First name is required")
        return;
      }
      if (!completeDataObject?.lname) {
        error("Last name is required")
        return;
      }
      if (!completeDataObject?.date_of_birth?.year || !completeDataObject?.date_of_birth?.month?.value || !completeDataObject?.date_of_birth?.date) {
        error("DOB is required")
        return;
      }
      if (!completeDataObject?.gender) {
        error("Gender is required")
        return;
      }
      if (!completeDataObject?.group) {
        error("Group is required")
        return;
      }
      if (!completeDataObject?.address) {
        error("Address is required")
        return;
      }
      if (!completeDataObject?.country?.value) {
        error("Country is required")
        return;
      }
      if (!completeDataObject?.state) {
        error("State is required")
        return;
      }
      if (!completeDataObject?.city) {
        error("City is required")
        return;
      }
      if (!completeDataObject?.zipcode) {
        error("Zipcode is required")
        return;
      }
      if (!completeDataObject?.email) {
        error("Email is required")
        return;
      } else {
        const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
        if (!isEmail(completeDataObject?.email)) {
          error("Invalid Email")
          return;
        }
      }
      if (!completeDataObject?.contacts[0]?.icon || !completeDataObject?.contacts[0]?.number || !completeDataObject?.contacts[0]?.phoneCode) {
        error("Contacts is required")
        return;
      }
      return fromisvalid;
    }
    if (validection()) {
      let updateshiping = completeDataObject?.shipping_address?.length ? completeDataObject?.shipping_address.map(address => {
        const { fullAddress, ...rest } = address;
        return { ...rest, country: address.country.value };
      }) : [];

      let updatebiling = completeDataObject?.billing_address?.length ? completeDataObject?.billing_address.map(address => {
        const { fullAddress, ...rest } = address;
        return { ...rest, country: address.country.value };
      }) : [];
      if (completeDataObject?.samebillingaddress) {
        updateshiping = [...updatebiling.map(address => ({ ...address }))];
        updateshiping.forEach((result) => {
          delete result?.UDID;
        });
      }

      let GetloadingID = loading();
      const postdata = {
        id: cusid ? cusid : "",
        title: completeDataObject?.title,
        fname: completeDataObject?.fname,
        mname: completeDataObject?.mname,
        lname: completeDataObject?.lname,
        date_of_birth: {
          year: completeDataObject?.date_of_birth?.year,
          month: completeDataObject?.date_of_birth?.month?.value,
          date: completeDataObject?.date_of_birth?.date
        },
        age: completeDataObject?.age,
        gender: completeDataObject?.gender,
        group: completeDataObject?.group,
        nationality: completeDataObject?.nationality,
        address: completeDataObject?.address,
        country: completeDataObject?.country?.value,
        state: completeDataObject?.state,
        city: completeDataObject?.city,
        zipcode: completeDataObject?.zipcode,
        email: completeDataObject?.email,
        emails: completeDataObject?.emails,
        Instagram: completeDataObject?.Instagram,
        occasion: completeDataObject?.occasion,
        source: completeDataObject?.source,
        contacts: completeDataObject?.contacts,
        profile: completeDataObject?.profile,
        govt_ids: completeDataObject?.govt_ids,
        behavior: completeDataObject?.behavior,
        behavior_note: completeDataObject?.behavior_note,
        status: completeDataObject?.status === "1" ? "1" : "0",
        billing_address: updatebiling,
        samebillingaddress: completeDataObject?.samebillingaddress ? true : false,
        shipping_address: updateshiping,
        privacy: completeDataObject?.privacy,
        opt: completeDataObject?.opt ? true : false,
        tax_exempt: completeDataObject?.tax_exempt ? true : false,

      };
      let res = await _Api(postdata, `api/v1/APP/customer/create`);
      if (res.code === 200) {
        setCusid()
        Updateloading(t, GetloadingID, (res?.message))
        setCompleteObject({
          govt_ids: [{ idtype: "", idnumber: "", id_image: "" }],
          contacts: [{ phoneCode: '93', icon: 1, number: "" }],
          emails: [],
          privacy: {
            data_handling: true,
            contact_term: true,
            cardcreation: true,
          },
          status: "0",
          shipping_address: [],
          billing_address: []
        })
        setAddaddressobj({ billing_address: {}, shipping_address: {} })
        if (modalRef.current) {
          modalRef.current.hide();
        }

      } else {
        Updateloading(t, GetloadingID, (res.errors), 'error');
      }
    }

  }
  const getcustomerdata = async () => {
    if (cusid) {
      try {
        let postdata = {
          id: cusid
        }
        let res = await _Api(postdata, `api/v1/APP/customer/get`);
        if (res.code === 200) {
          let obj = { ...res?.data }
          obj.country = selectdata?.Countryarray.find(element => element?.value === res?.data?.country) || null;
          const monthMatch = getdates?.month.find(element => element?.value === parseInt(res?.data?.date_of_birth?.month));
          if (monthMatch) {
            obj.date_of_birth.month = monthMatch;
          }
          if (!obj.govt_ids?.length) {
            obj.govt_ids = [{ idtype: "", idnumber: "", id_image: "" }]
          }
          if (!obj.contacts?.length) {
            obj.contacts = [{ phoneCode: '93', icon: 1, number: "" }]
          }
          setCompleteObject(obj)
        }

      }
      catch (err) {
        console.log(err, 'err')
      }
    } else {
      setCompleteObject({
        govt_ids: [{ idtype: "", idnumber: "", id_image: "" }],
        contacts: [{ phoneCode: '93', icon: 1, number: "" }],
        emails: [],
        privacy: {
          data_handling: true,
          contact_term: true,
          cardcreation: true,
        },
        status: "0",
        shipping_address: [],
        billing_address: []
      })
      setAddaddressobj({ billing_address: {}, shipping_address: {} })

    }
  }

  useEffect(() => {
    getcustomerdata()
    // eslint-disable-next-line
  }, [cusid])
  const setaddress = (type, adddata, lable, addressindex) => {
    let obj = { ...completeDataObject }
    if (lable === "save") {
      if (!adddata?.billing_address?.address && !adddata?.shipping_address?.address) {
        error("Address is required")
        return;
      }
      if (!adddata?.billing_address?.country?.value && !adddata?.shipping_address?.country?.value) {
        error("Country is required")
        return;
      }
      if (!adddata?.billing_address?.state && !adddata?.shipping_address?.state) {
        error("State is required")
        return;
      }
      if (!adddata?.billing_address?.city && !adddata?.shipping_address?.city) {
        error("City is required")
        return;
      }
      if (!adddata?.billing_address?.zipcode && !adddata?.shipping_address?.zipcode) {
        error("Zipcode is required")
        return;
      }
      if (addressindex === 0 || addressindex) {
        if (type === "Shipping") {
          let shiparray = [...obj?.shipping_address]
          let fullAddressComponents = [
            adddata?.shipping_address?.address,
            adddata?.shipping_address?.city,
            adddata?.shipping_address?.state,
            adddata?.shipping_address?.country?.label,
            adddata?.shipping_address?.zipcode
          ];
          adddata.shipping_address.fullAddress = fullAddressComponents.filter(component => component?.trim() !== '').join(", ");
          shiparray[addressindex] = adddata.shipping_address
          setCompleteObject((state) => ({
            ...state,
            shipping_address: shiparray
          }))
        } else {
          if (type === "Billing") {
            let billarray = [...obj?.billing_address]
            let fullAddressComponents = [
              adddata?.billing_address?.address,
              adddata?.billing_address?.city,
              adddata?.billing_address?.state,
              adddata?.billing_address?.country?.label,
              adddata?.billing_address?.zipcode
            ];
            adddata.billing_address.fullAddress = fullAddressComponents.filter(component => component?.trim() !== '').join(", ");
            billarray[addressindex] = adddata.billing_address
            setCompleteObject((state) => ({
              ...state,
              billing_address: billarray
            }))
          }
        }
        setShowAddContent()
      } else {
        if (type === "Shipping") {
          let shiparray = [...obj?.shipping_address]
          let fullAddressComponents = [
            adddata?.shipping_address?.address,
            adddata?.shipping_address?.city,
            adddata?.shipping_address?.state,
            adddata?.shipping_address?.country?.label,
            adddata?.shipping_address?.zipcode
          ];
          adddata.shipping_address.fullAddress = fullAddressComponents.filter(component => component?.trim() !== '').join(", ");
          shiparray?.push(adddata?.shipping_address)
          setCompleteObject((state) => ({
            ...state,
            shipping_address: shiparray
          }))
        }
        if (type === "Billing") {
          let billarray = [...obj?.billing_address]
          let fullAddressComponents = [
            adddata?.billing_address?.address,
            adddata?.billing_address?.city,
            adddata?.billing_address?.state,
            adddata?.billing_address?.country?.label,
            adddata?.billing_address?.zipcode
          ];
          adddata.billing_address.fullAddress = fullAddressComponents.filter(component => component?.trim() !== '').join(", ");
          billarray?.push(adddata?.billing_address)
          setCompleteObject((state) => ({
            ...state,
            billing_address: billarray
          }))
        }
        setShowAddContent()
      }
    } else if (lable === "delete") {
      if (type === "Billing") {
        let billarray = [...obj?.billing_address]
        const newArray = billarray.filter((_, index) => index !== addressindex);
        setCompleteObject((state) => ({
          ...state,
          billing_address: newArray
        }))
      } else {
        let shiparray = [...obj?.shipping_address]
        const newArray = shiparray.filter((_, index) => index !== addressindex);
        setCompleteObject((state) => ({
          ...state,
          shipping_address: newArray
        }))
      }
      setShowAddContent()

    } else {
      setCompleteObject(obj)
    }


  }
  useEffect(() => {
    modalRef.current = new window.bootstrap.Modal(document.getElementById('customerAddModal'));
  }, []);
  return (

    <div className="modal fade customerinformation" id="customerAddModal" tabIndex="-1" role="dialog" aria-labelledby="customerAddModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header customerinformation-header">
            <h5 className="modal-title w-100 text-center">{t("Add Customer")}</h5>
            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"
              onClick={() => {
                setCusid(""); setCompleteObject({
                  govt_ids: [{ idtype: "", idnumber: "", id_image: "" }],
                  contacts: [{ phoneCode: '93', icon: 1, number: "" }],
                  emails: [],
                  privacy: {
                    data_handling: true,
                    contact_term: true,
                    cardcreation: true,
                  },
                  status: "0",
                  shipping_address: [],
                  billing_address: []
                })
              }}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body customerinformation-inner">
            <div className='customerinformation-inner-left'>
              <div className="customerinformation-inner-left-imgwrapper">
                <input
                  type="file"
                  className="choose-img"
                  onChange={onImageChange}
                  id="setimg"
                  accept="image/*"
                />
                <img className="user-img" src={completeDataObject?.profile ? completeDataObject?.profile : ""} alt="" />
                <label htmlFor="setimg" className={isArabic ? 'add-img right' : 'add-img'}>
                  <img
                    className=""
                    src={base_assets + "images/icons/add-detail.png"}
                    alt="no-img"
                  />
                </label>
                <div className='customerinformation-inner-left-formitems-wrapper justify-content-between addsecforpopup-inputs-radiobtn'>
                  <label className="form-check-label" htmlFor="active">{t("Active")}</label>
                  <div className="form-check form-switch">
                    <input checked={completeDataObject?.status === "1" ? true : false}
                      onChange={(e) => setallobject("status", e.target.checked ? "1" : "0")} className="form-check-input" type="checkbox" role="switch" id="active" />
                  </div>
                </div>
              </div>
              <div className='customerinformation-inner-left-formitems'>
                <h5 className="customerinformation-inner-left-formitems-heading">{t("Customer Information")}</h5>
                <div className="customerinformation-inner-left-formitems-wrapper">
                  <div className="customerinputs place-input customerinformation-inner-left-formitems-wrapper-cus">
                    <input
                      value={completeDataObject?.customerid ? completeDataObject?.customerid : ""}
                      type="text"
                      readOnly
                      className="input-set dissable customerinputs-field "
                      placeholder={t("2024FLTH00001")}
                    />{" "}
                  </div>
                  <div className="col part-3">
                    <Select
                      name='nationality'
                      value={completeDataObject?.nationality ? { label: completeDataObject.nationality, value: completeDataObject.nationality } : null}
                      onChange={(e) => setallobject("nationality", e.value)}
                      options={selectdata?.Nationality}
                      className="select-set select  p-0"
                      placeholder={t("Nationality")}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      menuPlacement="auto"
                      classNamePrefix="common-select"
                      getOptionLabel={(opt) => opt.label}
                      isSearchable={true}
                    />
                  </div>
                </div>
                <div className="customerinformation-inner-left-formitems-wrapper">
                  <div className="customerinputs col px-0">
                    <Select
                      value={completeDataObject.title ? { label: completeDataObject.title, value: completeDataObject.title } : null}
                      onChange={(e) => setallobject("title", e.value)}
                      className={"select  p-0"}
                      placeholder={t("Title")}
                      options={titleoption}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      menuPlacement="auto"
                      classNamePrefix="common-select"
                      getOptionLabel={(e) => (
                        <div className="">
                          <span>{e.label}</span>
                        </div>
                      )}
                      isSearchable={false}
                    />
                  </div>
                  <div className="customerinputs col px-0 form-floating">
                    <input
                      name='fname'
                      value={completeDataObject?.fname ? completeDataObject?.fname : ""}
                      onChange={(e) => setallobject("fname", e.target.value)}
                      type="text"
                      className={"customerinputs-field form-control"}
                      id="firstname"
                      placeholder="fname"
                    />
                    <label
                      className="customerinputs-label longname"
                      alt="fname"
                      htmlFor="firstname"
                      placeholder={t("First Name")}
                    >
                      {t("First Name")}
                    </label>
                  </div>
                  <div className="customerinputs col p-0 form-floating">
                    <input

                      className={"customerinputs-field form-control"}
                      name='mname'
                      value={completeDataObject?.mname ? completeDataObject?.mname : ""}
                      onChange={(e) => setallobject("mname", e.target.value)}
                      type="text"
                      placeholder="mname"
                      id="middlename"
                    />
                    <label
                      className="customerinputs-label longname"
                      alt="mname"
                      htmlFor="middlename"
                      placeholder="Middle Name"
                    >
                      {t("Middle Name")}
                    </label>
                  </div>
                  <div className="customerinputs col px-0 form-floating">
                    <input
                      name='lname'
                      value={completeDataObject?.lname ? completeDataObject?.lname : ""}
                      onChange={(e) => setallobject("lname", e.target.value)}
                      type="text"
                      id="lastname"
                      className={"customerinputs-field form-control"}
                      placeholder="lname"
                    />
                    <label
                      className="customerinputs-label longname"
                      alt="lname"
                      htmlFor="lastname"
                      placeholder="Last Name"
                    >
                      {t("Last Name")}
                    </label>
                  </div>
                </div>

                <div className="customerinformation-inner-left-formitems-wrapper">
                  <div className="col">
                    <Select
                      onChange={(e) => setallobject("date_of_birth", {
                        year: completeDataObject?.date_of_birth?.year,
                        month: completeDataObject?.date_of_birth?.month,
                        date: e.value
                      })}
                      value={completeDataObject?.date_of_birth?.date ? { label: completeDataObject?.date_of_birth?.date, value: completeDataObject?.date_of_birth?.date } : null}
                      options={getdates?.date}
                      className=" select  p-0"
                      placeholder={t("Date")}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      menuPlacement="auto"
                      classNamePrefix="common-select"
                      getOptionLabel={(e) => (
                        <div className="">
                          <span>{e.label}</span>
                        </div>
                      )}
                      isSearchable={false}
                    />
                  </div>
                  <div className="col">
                    <Select
                      onChange={(e) => setallobject("date_of_birth", {
                        year: completeDataObject?.date_of_birth?.year,
                        month: e,
                        date: completeDataObject?.date_of_birth?.date
                      })}
                      value={completeDataObject?.date_of_birth?.month ? completeDataObject?.date_of_birth?.month : null}
                      options={getdates?.month}
                      className=" select  p-0"
                      placeholder={t("Month")}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      menuPlacement="auto"
                      classNamePrefix="common-select"
                      getOptionLabel={(e) => (
                        <div className="">
                          <span>{e.label}</span>
                        </div>
                      )}
                      isSearchable={false}
                    />
                  </div>
                  <div className="col">
                    <Select
                      onChange={(e) => {
                        setallobject("date_of_birth", {
                          year: e.value,
                          month: completeDataObject?.date_of_birth?.month,
                          date: completeDataObject?.date_of_birth?.date
                        }); callAge(e)
                      }}
                      menuPlacement="auto"
                      value={completeDataObject?.date_of_birth?.year ? { label: completeDataObject?.date_of_birth?.year, value: completeDataObject?.date_of_birth?.year } : null}
                      options={getdates?.year}
                      className={"select  p-0"}
                      placeholder={t("Year")}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      classNamePrefix="common-select"
                      getOptionLabel={(e) => (
                        <div className="">
                          <span>{e.label}</span>
                        </div>
                      )}
                      isSearchable={false}
                    />
                  </div>
                  <div className="customerinputs col-4-row-col form-floating">
                    <input
                      readOnly
                      value={completeDataObject?.age ? completeDataObject?.age : ""}
                      type="text"
                      className="customerinputs-field form-control"
                      placeholder="sddsdsd"
                      id=""
                    />
                    <label
                      className="customerinputs-label"
                      htmlFor=""
                    >
                      {t("Age")}
                    </label>
                  </div>
                </div>
                <div className="customerinformation-inner-left-formitems-wrapper">
                  <div className="col">
                    <Select
                      onChange={(e) => { setallobject("gender", e.value) }}
                      value={completeDataObject?.gender ? { label: completeDataObject?.gender, value: completeDataObject?.gender } : null}
                      className="select p-0"
                      placeholder={t("Gender")}
                      options={genderoption}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      menuPlacement="auto"
                      classNamePrefix="common-select"
                      getOptionLabel={(e) =>
                        genderoption
                          .filter((item) => item?.value === e.value)
                          .map((newVal, key) => {
                            return (
                              <div className="common-select-icon" key={key}>
                                {newVal.icon}
                                <span>{newVal.label}</span>
                              </div>
                            );
                          })
                      }
                      isSearchable={false}
                    />
                  </div>
                  <div className="col">
                    <Select
                      onChange={(e) => { setallobject("group", e.value) }}
                      value={completeDataObject?.group ? { label: completeDataObject?.group, value: completeDataObject?.group } : null}
                      className="select  p-0"
                      placeholder={t("Group")}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      menuPlacement="auto"
                      options={groupoption}
                      classNamePrefix="common-select"
                      getOptionLabel={(e) => (
                        <div className="">{e.label}</div>
                      )}
                      isSearchable={false}
                    />
                  </div>
                </div>
                <div className="customerinformation-inner-left-formitems-wrapper">
                  <div className="col part-3">
                    <Select
                      onChange={(e) => { setallobject("behavior", e.value) }}
                      value={completeDataObject?.behavior ? { label: completeDataObject?.behavior, value: completeDataObject?.behavior } : null}
                      name='Behavior'
                      className="select-set select  p-0"
                      placeholder={t("Behavior")}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      menuPlacement="auto"
                      options={behaviorOption}
                      classNamePrefix="common-select"
                      getOptionLabel={(e) => (
                        <div className="">{e.label}</div>
                      )}
                      isSearchable={false}
                    />
                  </div>
                  <div className="customerinputs col part-3">
                    <div className="customerinputs form-floating">
                      <input
                        onChange={(e) => { setallobject("behavior_note", e.target.value) }}
                        value={completeDataObject?.behavior_note ? completeDataObject?.behavior_note : ""}
                        type="text"
                        className="customerinputs-field form-control"
                        placeholder=""
                        id=""
                      />
                      <label
                        className="customerinputs-label"
                        htmlFor=""
                      >
                        {t("Behavior Note")}
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  {completeDataObject?.govt_ids?.length ? completeDataObject?.govt_ids.map((value, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="customerinformation-inner-left-formitems-wrapper">
                          <div className="col part-3">
                            <Select
                              name='govt_ids'
                              className="select-set select  p-0"
                              placeholder={t("ID Number")}
                              menuPlacement="auto"
                              value={value?.idtype ? { value: value?.idtype, label: value?.idtype } : null}
                              onChange={(e) => {
                                handleSelectChange(
                                  e.value ? e.value : value?.idtype,
                                  index,
                                  value?.idnumber,
                                  value?.id_image,
                                  "govt_id"
                                );
                              }}

                              components={{
                                ValueContainer: CustomValueContainer,
                              }}
                              options={idoption}
                              classNamePrefix="common-select"
                              getOptionLabel={(e) => (
                                <div className="">{e.label}</div>
                              )}
                              isSearchable={false}
                            />
                          </div>
                          <div className="customerinputs col part-3">
                            <div className="customerinputs form-floating">
                              <input
                                value={value?.idnumber}
                                onChange={(e) =>
                                  handleSelectChange(
                                    value?.idtype,
                                    index,
                                    e.target.value,
                                    value?.id_image,
                                    "govt_id"
                                  )}
                                type="text"
                                className="customerinputs-field form-control"
                                placeholder=""
                                id=""
                              />
                              <label
                                className="customerinputs-label"
                                htmlFor=""
                              >
                                {t("7-20 Digit")}
                              </label>
                            </div>
                          </div>
                          <div className="customerinputs attach-input part-1">
                            <button className="add-file"
                              onClick={() => {
                                setIdModal((prevState) => ({
                                  ...prevState,
                                  openmodal: true,
                                  index: index
                                }))
                              }}>
                              <img
                                className=""
                                src={
                                  base_assets + "images/icons/attach-files.png"
                                }
                                alt=""
                              />
                            </button>
                            {index !== 0 ?
                              <button onClick={() =>
                                handleRemoveElement(index, "govt_id")
                              } className=""
                              ><img className="" src={base_assets + "images/icons/minus-detail.png"} alt="" /></button>
                              : <button
                                onClick={() => addEventList("ID")}
                                className=""
                              >
                                <img
                                  className=""
                                  src={
                                    base_assets + "images/icons/add-detail.png"
                                  }
                                  alt="add-icon"
                                />
                              </button>}
                          </div>
                        </div>
                        {idModal?.openmodal && index === idModal?.index ?
                          <div
                            className={
                              idModal?.openmodal ? "d-block modal  common-modal" : "modal  common-modal"
                            }
                            id="uploadidModal"
                          >
                            <div className="modal-dialog modal-dialog-centered addUpload">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h4 className="modal-title">{t("Upload ID card")}</h4>
                                  <button type="button" className="close"
                                    onClick={() => {
                                      setIdModal((prevState) => ({
                                        ...prevState,
                                        openmodal: false,
                                        index: index
                                      }))
                                    }}>
                                    <img
                                      className="user-img"
                                      src={base_assets + "images/icons/close.png"}
                                      alt="close-img"
                                    />
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <div className="addUpload-main-body">
                                    <div className="addUpload-main-body-inside">
                                      <div className="main-part">
                                        <div className="data-side">
                                          <div
                                            className="data-side-inner"
                                          >
                                            {idModal?.index === index &&
                                              completeDataObject?.govt_ids[idModal?.index]?.id_image ? (
                                              <img
                                                className="card-img"
                                                src={completeDataObject?.govt_ids[idModal?.index]?.id_image}
                                                alt=""
                                              />
                                            ) : ""}
                                          </div>
                                          <p className="tag-name">
                                            {t("Drop your Image here or")}{" "}
                                            <input
                                              name={index}
                                              id={`choose-img${index}`}
                                              type="file"
                                              className="custom-file-input"
                                              onChange={(e) => {
                                                onidimageChange(
                                                  value?.idtype,
                                                  value?.idnumber,
                                                  e
                                                );
                                              }
                                              }
                                              accept="image/*"
                                            />
                                          </p>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    data-bs-dismiss="modal"
                                    className="btn modal-content-yes"
                                    onClick={() => {
                                      setIdModal((prevState) => ({
                                        ...prevState,
                                        openmodal: false,
                                        index: index
                                      }))
                                    }}
                                  >
                                    {t("Cancle")}
                                  </button>
                                  <button
                                    onClick={() => {
                                      setIdModal((prevState) => ({
                                        ...prevState,
                                        openmodal: false,
                                        index: index
                                      }))
                                    }}
                                    type="button"
                                    data-bs-dismiss="modal"
                                    className="btn modal-content-yes"

                                  >
                                    {t("yes")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div> : ""}
                      </React.Fragment>
                    )
                  }) :
                    <div className="col-md-4 mt-3">
                      <button className="border-0 bg-transparent p-0 main-green-text"
                        onClick={() => addEventList("ID")}>
                        <img src={base_assets + "images/icons/add-detail.png"} alt="" /><span className="ms-2"> {t("Add ID")}</span>
                      </button>
                    </div>}
                </div>
                <div className="customerinformation-inner-left-formitems-wrapper mt-2">
                  <div className="textarea customerinputs text-box form-floating px-0 w-100">
                    <textarea
                      onChange={(e) => { setallobject("address", e.target.value) }}
                      value={completeDataObject?.address ? completeDataObject?.address : ""}
                      name='address'
                      className={"text-box-inner col customerinputs-field form-control customerinformation-inner-left-formitems-wrapper-addrestextarea"
                      }
                      rows="4"
                      cols="50"
                      placeholder={t("Address")}
                    ></textarea>
                    <label
                      className="customerinputs-label"
                      htmlFor=""
                    >
                      {t("Address")}
                    </label>
                  </div>
                </div>
                <div className="customerinformation-inner-left-formitems-wrapper">
                  <div className="col px-0">
                    <Select
                      onChange={(e) => { setallobject("country", e) }}
                      value={completeDataObject?.country}
                      options={selectdata?.Countryarray}
                      className="select select-set select-fix p-0"
                      menuPlacement="auto"
                      placeholder={t("Country")}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      classNamePrefix="common-select"
                      getOptionLabel={(opt) => opt.label}
                      isSearchable={true}
                    />
                  </div>
                  <div className="customerinputs col px-0 form-floating">
                    <input
                      name='state'
                      onChange={(e) => { setallobject("state", e.target.value) }}
                      value={completeDataObject?.state ? completeDataObject?.state : ""}
                      type="text"
                      placeholder="State"
                      className={"customerinputs-field form-control"}
                      id="state"
                    />
                    <label
                      className="customerinputs-label"
                      htmlFor="state"
                    >
                      {t("Provience/State")}
                    </label>
                  </div>
                </div>
                <div className="customerinformation-inner-left-formitems-wrapper">
                  <div className="customerinputs col px-0 form-floating">
                    <input
                      name='city'
                      onChange={(e) => { setallobject("city", e.target.value) }}
                      value={completeDataObject?.city ? completeDataObject?.city : ""}
                      placeholder="city"
                      type="text"
                      className={"customerinputs-field form-control"}
                      id="city"
                    />
                    <label
                      className="customerinputs-label"
                      htmlFor="city"
                    >
                      {t("City")}
                    </label>
                  </div>
                  <div className="customerinputs col px-0 form-floating">

                    <input
                      name='zipcode'
                      onChange={(e) => { setallobject("zipcode", e.target.value) }}
                      value={completeDataObject?.zipcode ? completeDataObject?.zipcode : ""}
                      placeholder="Zipcode"
                      type="text"
                      className={"customerinputs-field form-control"}
                      id="" />
                    <label className="customerinputs-label" htmlFor="">

                      {t("Zip Code")}
                    </label>
                  </div>
                </div>
                {completeDataObject?.contacts?.length ? completeDataObject?.contacts.map((index, value) => {
                  return (
                    <div className="" key={value}>

                      <div className="customerinformation-inner-left-formitems-wrapper position-relative">

                        <Select
                          className="select-set select select-fix ps-0 col-md-3"
                          placeholder=""
                          id="othermobilicon"
                          menuPlacement="auto"
                          options={phoneOneOption}
                          value={{ value: index?.icon, icon: index?.icon === 1 ? mobileImage : phoneImage }}
                          onChange={(e) => {
                            handleSelectChange(
                              e.value ? e.value : index?.icon,
                              value,
                              index?.phoneCode,
                              index?.number
                            )
                          }} classNamePrefix="common-select"
                          isSearchable={false}
                          getOptionLabel={(e) => (
                            <div className="common-select-icon">{e.icon}</div>
                          )}
                        />
                        <Select
                          options={selectdata?.Phonecodearray}
                          className="select-set select select-fix ps-0 col-md-3"
                          id="otherphonecode"
                          menuPlacement="auto"
                          placeholder=""
                          value={{
                            value: index?.phoneCode,
                            label: index?.phoneCode
                          }}
                          onChange={(e) =>
                            handleSelectChange(
                              index?.icon,
                              value,
                              e?.value ? e?.value : index?.phoneCode,
                              index?.number
                            )}
                          classNamePrefix="common-select"
                          isSearchable={true}
                          getOptionLabel={(e) => <div className="" >
                            <span >{e?.value}</span></div>}
                        />

                        <div className="customerinputs col px-0 form-floating  h-100 w-100">
                          <input
                            type="number"
                            value={index?.number}
                            onChange={(e) => handleSelectChange(
                              index?.icon,
                              value,
                              index?.phoneCode,
                              e.target.value ? e.target.value : index?.number
                            )}
                            placeholder="Mobile number"
                            className="customerinputs-field form-control"
                            id="othermobilenumber"
                            maxLength={17}
                            pattern="[0-9]*" />
                          <label className="customerinputs-label" htmlFor="Mobile number">
                            {t("Mobile number")}
                          </label>
                        </div>
                        {value !== 0 ?
                          <button onClick={() => handleRemoveElement(value, "phone")} className={isArabic === 'SA.png' ? "add-btn left" : "add-btn"}
                          ><img className="" src={base_assets + "images/icons/minus-detail.png"} alt="" /></button>
                          : <button
                            onClick={() => addEventList()}
                            className={isArabic === 'SA.png' ? "add-btn left" : "add-btn"}
                          >
                            <img
                              className=""
                              src={
                                base_assets + "images/icons/add-detail.png"
                              }
                              alt="add-icon"
                            />
                          </button>}
                      </div>
                    </div>
                  )
                })
                  : ""}
                <div className="customerinformation-inner-left-formitems-wrapper">
                  <div
                    className="customerinputs col px-0 form-floating position-relative"
                  >
                    <input
                      name='email'
                      onChange={(e) => { setallobject("email", e.target.value) }}
                      value={completeDataObject?.email ? completeDataObject?.email : ""}
                      className={"customerinputs-field form-control"}
                      type="text"
                      id="email"
                      placeholder="Email"
                    />
                    <label
                      className="customerinputs-label"
                      htmlFor="email"
                    >
                      {t("Email")}
                    </label>
                    <button
                      onClick={() => addEventList("emails")}
                      className={isArabic === 'SA.png' ? "add-btn left" : "add-btn"}
                    >
                      <img
                        className=""
                        src={base_assets + "images/icons/add-detail.png"}
                        alt="add-icon"
                      />
                    </button>
                  </div>
                </div>
                {completeDataObject?.emails?.length ? completeDataObject?.emails.map((index, value) => {
                  return (
                    <div className="customerinformation-inner-left-formitems-wrapper" key={value}>
                      <div
                        className="customerinputs col px-0 form-floating position-relative"
                      >
                        <input
                          value={index}
                          onChange={(e) => handleSelectChange(
                            "",
                            value,
                            "",
                            e.target.value ? e.target.value : index,
                            "emails"
                          )}
                          type="text"
                          className="customerinputs-field form-control"
                          name="email"
                          id="email"
                          placeholder="Email"
                        />
                        <label
                          className="customerinputs-label"
                          htmlFor="email"
                        >
                          {t("Email")}
                        </label>
                        <button
                          className={isArabic === 'SA.png' ? "add-btn left" : "add-btn"}
                        >
                          <img
                            onClick={() => handleRemoveElement(value, "emails")}
                            className=""
                            src={base_assets + "images/icons/minus-detail.png"}
                            alt="add-icon"
                          />
                        </button>
                      </div>
                    </div>
                  )
                }) : ""}
                <div className="customerinformation-inner-left-formitems-wrapper">
                  <div className="customerinputs col px-0 form-floating position-relative">
                    <input
                      name='Instagram'
                      onChange={(e) => { setallobject("Instagram", e.target.value) }}
                      value={completeDataObject?.Instagram ? completeDataObject?.Instagram : ""}
                      type="text"
                      className={"customerinputs-field form-control"}
                      id="instagram"
                      placeholder={t("Instagram")}
                    />
                    <label
                      className="customerinputs-label"
                      htmlFor="instagram"
                    >
                      {t("Instagram")}
                    </label>
                  </div>
                  <div className="col px-0">
                    <Select
                      onChange={(e) => { setallobject("occasion", e.value) }}
                      value={completeDataObject?.occasion ? { label: completeDataObject?.occasion, value: completeDataObject?.occasion } : null}
                      placeholder={t("Occasion")}
                      options={occasionoption}
                      menuPlacement="auto"
                      classNamePrefix="common-select"
                      className=" select-set p-0 select"
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      getOptionLabel={(e) =>
                        occasionoption
                          .filter((item) => item?.value === e.value)
                          .map((newVal, key) => {
                            return (
                              <div className="common-select-icon" key={key}>
                                {newVal.icon}
                                <span>{newVal.label}</span>
                              </div>
                            );
                          })
                      }
                      isSearchable={false}
                    />
                  </div>

                  <div className="col px-0  common-select">
                    <Select
                      onChange={(e) => { setallobject("source", e.value) }}
                      value={completeDataObject?.source ? { label: completeDataObject?.source, value: completeDataObject?.source } : null}
                      placeholder={t("Source")}
                      options={sourceoption}
                      menuPlacement="auto"
                      classNamePrefix="common-select"
                      className="select-set p-0 select"
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      getOptionLabel={(e) =>
                        sourceoption
                          .filter((item) => item?.value === e.value)
                          .map((newVal, key) => {
                            return (
                              <div className="common-select-icon" key={key}>
                                {newVal.icon}
                                <span>{newVal.label}</span>
                              </div>
                            );
                          })
                      }
                      isSearchable={false}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='customerinformation-inner-rightside'>
              <div className='customerinformation-inner-right'>
                {showAddContent ?
                  <div className='customerinformation-inner-right-additional'>
                    <div>
                      <div className="customerinformation-inner-right-heading mb-2 mt-2" ><span>{t(`Add ${showAddContent} Address`)}</span></div>

                      <AddressSection
                        searchdata={showAddContent === "Billing" ? completeDataObject?.billing_address : completeDataObject?.shipping_address}
                        cusid={cusid}
                        type={showAddContent}
                        noDropdown={true}
                        completeDataObject={showAddContent === "Billing" ? completeDataObject?.billing_address : completeDataObject?.shipping_address}
                        setCompleteObject={setCompleteObject}
                        setAddaddressobj={setAddaddressobj}
                        addaddressobj={showAddContent === "Billing" ? addaddressobj?.billing_address : addaddressobj?.shipping_address}
                        Countryarray={selectdata?.Countryarray}
                        add={true}
                        setShowAddContent={setShowAddContent}
                        setAddressindex={setAddressindex}
                      />
                    </div>

                    <div className={`customerinformation-inner-right-additional-bottom ${addressindex === 0 || addressindex ? "justify-content-between" : "justify-content-center"}`}>
                      {addressindex === 0 || addressindex ?
                        <button className='delete' onClick={() => setaddress(showAddContent, addaddressobj, "delete", addressindex)}>{t("Delete")}</button> : ""}

                      <div className='customerinformation-inner-right-additional-bottom-rightbtns'>
                        <button className='cancel' onClick={() => { setShowAddContent(); setaddress(showAddContent, addaddressobj, "cancle", null) }}>{t("Cancel")}</button>
                        <button className='save' onClick={() => setaddress(showAddContent, addaddressobj, "save", addressindex)}>{t("Save")}</button>
                      </div>
                    </div>
                  </div>
                  :
                  <>
                    <div className='customerinformation-inner-right-leftpart'>
                      <div className='addsecforpopup-inner-content-billing-head'><span>{t("Billing Address")}
                      </span>
                      </div>
                      {completeDataObject?.billing_address?.length ?
                        <AddressSection
                          searchdata={completeDataObject?.billing_address}
                          cusid={cusid}
                          noDropdown={false}
                          type={'Billing'}
                          completeDataObject={completeDataObject?.billing_address}
                          setCompleteObject={setCompleteObject}
                          setAddaddressobj={setAddaddressobj}
                          addaddressobj={addaddressobj?.billing_address}
                          Countryarray={selectdata?.Countryarray}
                          add={false}
                          setShowAddContent={setShowAddContent}
                          setAddressindex={setAddressindex} /> : ""}

                      <div className='addcustomeraddress-inner-content-billing-addressbox-dropdownbox-inside-addnew' onClick={() => setShowAddContent('Billing')}>
                        <img className='customerinformation-inner-right-addicon' src={base_assets + 'images/icons/adplus.png'} alt="" />
                        <span className='customerinformation-inner-right-subheading'
                          onClick={() => {
                            setaddress(showAddContent, addaddressobj, "cancle");
                            setAddaddressobj({ billing_address: {}, shipping_address: {} });
                            setAddressindex(null)
                          }}>{t("Add Billing Address")}</span>
                      </div>


                      <div>
                        <div className="customerinformation-inner-right-checkboxwrapper">
                          <div className="com-check" id="">
                            <input
                              checked={completeDataObject?.samebillingaddress}
                              onChange={(e) => setallobject("samebillingaddress", e.target.checked)}
                              type="checkbox"
                              id="shippingadddress"
                              className="checked"
                            />
                            <label
                              className="com-check-label"
                              htmlFor="shippingadddress"
                            ></label>
                          </div>
                          <label
                            className="info-title"
                            htmlFor="shippingadddress"
                          >
                            {t("Shipping address same as billing address")}
                          </label>
                        </div>
                        {!completeDataObject?.samebillingaddress ?
                          <>
                            <div className="customerinformation-inner-right-heading mb-2 mt-2" ><span>{t("Shipping Address")}</span>


                            </div>
                            {completeDataObject?.shipping_address?.length ?
                              <AddressSection
                                searchdata={completeDataObject?.shipping_address}
                                cusid={cusid}
                                noDropdown={false}
                                type='Shipping'
                                completeDataObject={completeDataObject?.shipping_address}
                                setCompleteObject={setCompleteObject}
                                setAddaddressobj={setAddaddressobj}
                                addaddressobj={addaddressobj?.shipping_address}
                                Countryarray={selectdata?.Countryarray}
                                setShowAddContent={setShowAddContent}
                                add={false}
                                setAddressindex={setAddressindex} />
                              : ""}
                            <div className='addcustomeraddress-inner-content-billing-addressbox-dropdownbox-inside-addnew' onClick={() => setShowAddContent('Shipping')}>
                              <img className='customerinformation-inner-right-addicon' src={base_assets + 'images/icons/adplus.png'} alt="" />
                              <span className='customerinformation-inner-right-subheading'
                                onClick={() => {
                                  setaddress(showAddContent, addaddressobj, "cancle");
                                  setAddaddressobj({ billing_address: {}, shipping_address: {} });
                                  setAddressindex(null)
                                }}>{t("Add Shipping Address")}</span>
                            </div>
                          </> : ""}
                      </div>
                      <div className='customerinformation-inner-right-block'>
                        <div className="customerinformation-inner-right-heading">{t("Privacy")}</div>
                        <div className="">
                          <div className="customerinformation-inner-right-checkboxwrapper">
                            <div className="com-check" id="">
                              <input
                                onChange={(e) => setCompleteObject((state) => ({
                                  ...state,
                                  privacy: {
                                    ...state?.privacy,
                                    data_handling: e.target.checked
                                  }
                                }))}
                                checked={completeDataObject?.privacy?.data_handling}
                                type="checkbox"
                                id="datahandling"
                                className="checked"
                              />
                              <label
                                className="com-check-label"
                                htmlFor="datahandling"
                              ></label>
                            </div>
                            <label
                              className="info-title"
                              htmlFor="datahandling"
                            >
                              {t("Data Handling")}
                            </label>
                          </div>
                          <div className="customerinformation-inner-right-checkboxwrapper">
                            <div className="com-check" id="">
                              <input
                                onChange={(e) => setCompleteObject((state) => ({
                                  ...state,
                                  privacy: {
                                    ...state?.privacy,
                                    contact_term: e.target.checked
                                  }
                                }))}
                                checked={completeDataObject?.privacy?.contact_term}
                                type="checkbox"
                                id="contacterm"
                                className="checked"
                              />
                              <label
                                className="com-check-label"
                                htmlFor="contacterm"
                              ></label>
                            </div>
                            <label
                              className="info-title"
                              htmlFor="contacterm"
                            >
                              {t("Contact Term")}
                            </label>
                          </div>
                          <div className="customerinformation-inner-right-checkboxwrapper">
                            <div className="com-check" id="">
                              <input
                                onChange={(e) => setCompleteObject((state) => ({
                                  ...state,
                                  privacy: {
                                    ...state?.privacy,
                                    cardcreation: e.target.checked
                                  }
                                }))}
                                checked={completeDataObject?.privacy?.cardcreation}
                                type="checkbox"
                                id="creation"
                                className="checked"
                              />
                              <label
                                className="com-check-label"
                                htmlFor="creation"
                              ></label>
                            </div>
                            <label className="info-title" htmlFor="creation">
                              {t("Card Creation SA")}
                            </label>
                          </div>
                        </div>
                      </div>



                      <div className="py-2">
                        <div className="customerinformation-inner-right-checkboxwrapper">
                          <div className="com-check" id="">
                            <input
                              onChange={(e) => setallobject("opt", e.target.checked)}
                              checked={completeDataObject?.opt}
                              type="checkbox"
                              id="customerofffer"
                              className="checked"
                            />
                            <label
                              className="com-check-label"
                              htmlFor="customerofffer"
                            ></label>
                          </div>
                          <label
                            className="info-title"
                            htmlFor="customerofffer"
                          >
                            {t("Customer has opted to receive offers, updates, marketing and promotion by Email")}
                          </label>
                        </div>
                        <div className="customerinformation-inner-right-checkboxwrapper">
                          <div className="com-check" id="">
                            <input
                              onChange={(e) => setallobject("tax_exempt", e.target.checked)}
                              checked={completeDataObject?.tax_exempt}
                              type="checkbox"
                              id="customertax"
                              className="checked"
                            />
                            <label
                              className="com-check-label"
                              htmlFor="customertax"
                            ></label>
                          </div>
                          <label
                            className="info-title"
                            htmlFor="customertax"
                          >
                            {t("This customer is tax exempt. All taxes to be removed when this customer profile is allocated to a sale")}
                          </label>
                        </div>
                      </div>
                    </div>
                    {cusid ?
                      parmissiondatashow?.pos_customer_reports?.Edit ?
                        <div className='customerinformation-inner-right-savebtn'
                        >
                          <img
                            onClick={() => createcustomer()}
                            src={base_assets + "images/icons/save-icon.png"}
                            alt="close-img"
                          />
                        </div> : "" :
                      <div className='customerinformation-inner-right-savebtn'
                      >
                        <img
                          onClick={() => createcustomer()}
                          src={base_assets + "images/icons/save-icon.png"}
                          alt="close-img"
                        />
                      </div>
                    }
                  </>
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
