import React, { useEffect, useState } from 'react';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import './Main.scss';
import { isAuthenticated, isCurrentDomain } from "../components/AuthMiddleware";
import { useNavigate } from 'react-router-dom';

const Main = () => {
    const [activeDiv, setActiveDiv] = useState('home');
    const navigate = useNavigate();
    const getUserData = async () => {
        if (await isAuthenticated()) {
            navigate("/app/admin/dashboard");
        } else if (await isCurrentDomain('gis247.net')) {

        } else {
            navigate("/logout");
        }


    };

    useEffect(() => {
        getUserData();
    });


    return (
        <div className='landing-main'>
            <Header setActiveDiv={setActiveDiv} />
            <Content activeDiv={activeDiv} />
            <Footer />
        </div>
    );
};

export default Main;



