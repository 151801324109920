import React, { useState, useEffect, useCallback } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import DateTime from '../DateTime'
import '../../posstyle.scss'
import './header.scss'
import '../../rtlPos.scss'
import { useTranslation } from 'react-i18next'
import { _Apiauth } from '../api/_call'
import { useSelector } from 'react-redux'
const PosHeader = (props) => {
  const { t } = useTranslation()
  const posloc = JSON.parse(localStorage.getItem('pos-loc'))
  const { i18n } = props
  const [loginData, setLoginData] = useState(null)
  const [LanguageList, setLanguageData] = useState(null)
  const [CurrentLan_flg, setLanguageSelectData] = useState('US.png')
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission)
  const [checkApiCall, setCheckApiCall] = useState(true)
  const posstatics = useSelector((state) => state.app.posstatics)
  const getUserData = async () => {
    setCheckApiCall(false)
    const postdata = {}
    let profileData = await _Apiauth(postdata, `App/profile/get-auth`)
    if (profileData.code === 200) {
      setLoginData(profileData?.data)
      setLanguageData(profileData?.languageList)
    }
  }
  const rtlView = useCallback(
    (type, l) => {
      localStorage.setItem('UserLang', l)
      setLanguageSelectData(localStorage.getItem('UserLang'))
      let bodyid = document.querySelector('#body_id')
      if (type === 'rtl') {
        bodyid.classList.add('rtl')
      } else {
        bodyid.classList.remove('rtl')
      }
    },
    [setLanguageSelectData],
  )
  useEffect(() => {
    const fetchData = async () => {
      if (checkApiCall) {
        await getUserData()
      }

      if (localStorage.getItem('UserLang')) {
        setLanguageSelectData(localStorage.getItem('UserLang'))
      } else {
        setLanguageSelectData('US.png')
      }

      if (localStorage.getItem('UserLang') === 'SA.png') {
        rtlView('rtl', localStorage.getItem('UserLang'))
      }
    }

    fetchData()
  }, [checkApiCall, setLanguageSelectData, rtlView])
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL

  return (
    <>
      <ToastContainer></ToastContainer>
      <div className="main-header">
        <div className="main-header-left-col">
          <div className="main-header-left-col-left">
            <a href="/app/admin/dashboard" className="main-header-logo">
              <img src={base_assets + 'images/pos/gis-logo.png'} alt="" />
            </a>
            <ul className="main-header-left-col-left-menu">
              <li className="main-header-left-col-left-menu-single">
                <NavLink to="/app/pos/menu" className="d-block">
                  <img
                    src={base_assets + 'images/pos/icons/home-icon.png'}
                    alt=""
                  />
                </NavLink>
              </li>
              <li className="main-header-left-col-left-menu-single">
                <NavLink to="/app/pos/" className="d-block">
                  <img
                    src={base_assets + 'images/pos/icons/shop-icon.png'}
                    alt=""
                  />
                </NavLink>
              </li>
              <li className="main-header-left-col-left-menu-single main-header-left-col-left-menu-single-country dropdown common-dropdown bg-transparent rounded-0 shadow-none">
                <button
                  className="dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    className="rounded-0 shadow-lg"
                    src={base_assets + 'images/flags/' + CurrentLan_flg}
                    alt="icon"
                  />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {LanguageList?.map((LanguageCode, key) => (
                    <li key={key}>
                      {LanguageCode.Lan_value === 'arabic' ? (
                        <Link
                          className="dropdown-item"
                          onClick={() => {
                            i18n.changeLanguage(LanguageCode.Lan_value)
                            rtlView('rtl', LanguageCode.Lan_photo)
                          }}
                        >
                          <img
                            src={
                              base_assets +
                              'images/flags/' +
                              LanguageCode.Lan_photo
                            }
                            alt=""
                          />{' '}
                          <span>{LanguageCode.Lan_text}</span>
                        </Link>
                      ) : (
                        <Link
                          className="dropdown-item"
                          onClick={() => {
                            i18n.changeLanguage(LanguageCode.Lan_value)
                            rtlView('ltr', LanguageCode.Lan_photo)
                          }}
                        >
                          <img
                            src={
                              base_assets +
                              'images/flags/' +
                              LanguageCode.Lan_photo
                            }
                            alt=""
                          />{' '}
                          <span>{LanguageCode.Lan_text}</span>
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
          <div className="main-header-left-col-right">
            <ul className="main-header-left-col-right-menu"
              onClick={() => localStorage.removeItem("showcheckout")}>
              <li className="main-header-left-col-right-menu-single hover-effect activee">
                <Link
                  className="main-header-left-col-right-menu-single-a"
                  to="/app/pos/menu"
                >
                  <div className="main-header-left-col-right-menu-single-wrap hover-effect-inner ">
                    <img
                      className="hover-effect-inner-img hover-effect-inner-img-g menu-icon"
                      src={base_assets + 'images/pos/icons/column-green.png'}
                      alt=""
                    />
                    <img
                      className="hover-effect-inner-img hover-effect-inner-img-w menu-icon"
                      src={base_assets + 'images/pos/icons/column-white.png'}
                      alt=""
                    />
                  </div>
                </Link>
              </li>
              <li
                className={
                  parmissiondatashow?.POS?.View
                    ? 'main-header-left-col-right-menu-single hover-effect'
                    : 'd-none'
                }
              >
                <Link
                  className="main-header-left-col-right-menu-single-a"
                  to="/app/pos/catalog/CollectionList"
                >
                  <div className="main-header-left-col-right-menu-single-wrap hover-effect-inner ">
                    <img
                      className="hover-effect-inner-img hover-effect-inner-img-g cat-icon"
                      src={base_assets + 'images/pos/icons/catalog-green.png'}
                      alt=""
                    />
                    <img
                      className="hover-effect-inner-img hover-effect-inner-img-w cat-icon"
                      src={base_assets + 'images/pos/icons/catalog-white.png'}
                      alt=""
                    />
                  </div>
                </Link>
              </li>
              <li
                className={
                  parmissiondatashow?.WishList?.View
                    ? 'main-header-left-col-right-menu-single hover-effect'
                    : 'd-none'
                }
              >
                <Link
                  className="main-header-left-col-right-menu-single-a"
                  to="/app/pos/wishlist"
                >
                  <div className="main-header-left-col-right-menu-single-wrap hover-effect-inner ">
                    <img
                      className="hover-effect-inner-img hover-effect-inner-img-g wish-icon"
                      src={base_assets + 'images/pos/icons/heart-green.png'}
                      alt=""
                    />
                    <img
                      className="hover-effect-inner-img hover-effect-inner-img-w wish-icon"
                      src={base_assets + 'images/pos/icons/heart-white.png'}
                      alt=""
                    />
                  </div>
                </Link>
              </li>
              <li
                className={
                  parmissiondatashow?.pos_customer_reports?.View
                    ? 'main-header-left-col-right-menu-single hover-effect'
                    : 'd-none'
                }
              >
                <Link
                  className="main-header-left-col-right-menu-single-a"
                  to="/app/pos/customer/customer"
                >
                  <div className="main-header-left-col-right-menu-single-wrap hover-effect-inner ">
                    <img
                      className="hover-effect-inner-img hover-effect-inner-img-g customer-icon"
                      src={base_assets + 'images/pos/icons/customer-green.png'}
                      alt=""
                    />
                    <img
                      className="hover-effect-inner-img hover-effect-inner-img-w customer-icon"
                      src={base_assets + 'images/pos/icons/customer-white.png'}
                      alt=""
                    />
                  </div>
                </Link>
              </li>
              <li
                className={
                  parmissiondatashow?.cash_register?.View
                    ? 'main-header-left-col-right-menu-single hover-effect'
                    : 'd-none'
                }
              >
                <Link
                  className="main-header-left-col-right-menu-single-a"
                  to="/app/pos/Cashregister"
                >
                  <div className="main-header-left-col-right-menu-single-wrap hover-effect-inner ">
                    <img
                      className="hover-effect-inner-img hover-effect-inner-img-g menu-icon"
                      src={
                        base_assets + 'images/pos/icons/cash-register-green.png'
                      }
                      alt=""
                    />
                    <img
                      className="hover-effect-inner-img hover-effect-inner-img-w menu-icon"
                      src={
                        base_assets + 'images/pos/icons/cash-register-white.png'
                      }
                      alt=""
                    />
                  </div>
                </Link>
              </li>
              <li
                className={
                  parmissiondatashow?.pos_reports?.View
                    ? 'main-header-left-col-right-menu-single hover-effect'
                    : 'd-none'
                }
              >
                <Link
                  className="main-header-left-col-right-menu-single-a"
                  to="/app/pos/Dailysalereport"
                >
                  <div className="main-header-left-col-right-menu-single-wrap hover-effect-inner">
                    <img
                      className="hover-effect-inner-img hover-effect-inner-img-g report-icon"
                      src={base_assets + 'images/pos/icons/report-green.png'}
                      alt=""
                    />
                    <img
                      className="hover-effect-inner-img hover-effect-inner-img-w report-icon"
                      src={base_assets + 'images/pos/icons/report-white.png'}
                      alt=""
                    />
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="main-header-right-col">
          <div className="main-header-right-col-inner">
            <div className="main-header-right-col-location">
              <label className="main-header-right-col-location-name">
                {posstatics?.voc ? posstatics?.voc : posloc?.voc}
              </label>
              <div className="main-header-right-col-location-currency">
                <span className="main-header-right-col-location-currency-img">
                  {posstatics?.curr ? (
                    <img
                      src={
                        base_assets +
                        'images/flags/' +
                        posstatics?.curr.toLowerCase() +
                        '.png'
                      }
                      alt=""
                    />
                  ) : (
                    <img
                      src={
                        base_assets +
                        'images/flags/' +
                        posloc?.curr.toLowerCase() +
                        '.png'
                      }
                      alt=""
                    />
                  )}
                </span>
                <span className="main-header-right-col-location-currency-name">
                  {posstatics?.curr ? posstatics?.curr : posloc?.curr}
                </span>
              </div>
            </div>
            <div className="main-header-right-col-cashier">
              <div className="main-header-right-col-cashier-select common-select">
                <div className="dropdown">
                  <button
                    className="dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="main-header-right-col-cashier-select-user"
                      style={{ borderRadius: "50%" }}
                      src={loginData?.myProfile ? loginData?.myProfile : base_assets + 'images/pos/icons/gray-user.png'}
                      alt=""
                    />
                    <span className="main-header-right-user-name">
                      {' '}
                      {loginData?.fname} {loginData?.lname}
                    </span>
                  </button>
                  <ul className="dropdown-menu main-header-right-user-dropdown">
                    {/* {loginData?.userType === "Administrator" ?
                      <li>
                        <div
                          className="dropdown-menu-single"
                          data-bs-toggle="modal"
                          data-bs-target="#dataPurgeModal"
                        >
                          <div className="main-header-right-user-dropdown-img">
                            <i className="fa fa-database" aria-hidden="true"></i>
                          </div>
                          <span>{t('Data Purge')}</span>
                        </div>
                      </li> : ""} */}

                    <li>
                      <Link
                        className="dropdown-menu-single"
                        to="/app/admin/profile"
                      >
                        <div className="main-header-right-user-dropdown-img">
                          <i className="fa fa-user-o" aria-hidden="true"></i>
                        </div>
                        <span>{t('My profile')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-menu-single" to="/logout">
                        <div className="main-header-right-user-dropdown-img">
                          <i className="fa fa-power-off" aria-hidden="true"></i>
                        </div>
                        <span>{t('logout')}</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <span className="main-header-right-col-cashier-date">
                {' '}
                <DateTime></DateTime>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PosHeader
