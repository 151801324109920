import React from "react";

const Customer = ({color, stroke}) => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 21C20 18.239 16.418 16 12 16C7.582 16 4 18.239 4 21M12 13C10.6739 13 9.40215 12.4732 8.46447 11.5355C7.52678 10.5979 7 9.32608 7 8C7 6.67392 7.52678 5.40215 8.46447 4.46447C9.40215 3.52678 10.6739 3 12 3C13.3261 3 14.5979 3.52678 15.5355 4.46447C16.4732 5.40215 17 6.67392 17 8C17 9.32608 16.4732 10.5979 15.5355 11.5355C14.5979 12.4732 13.3261 13 12 13Z" stroke={color ? color : "white"} strokeWidth={stroke ? stroke : "2"} strokeLinecap="round" strokeLinejoin="round" />
      </svg>

    </>
  )
}

export default Customer;
