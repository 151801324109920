import React from 'react'

export default function Calendar(props) {
  const { color, width, height, withclock } = props;
  return (
    <>
    {withclock ? 
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <path d="M11.795 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5H17C17.5304 5 18.0391 5.21071 18.4142 5.58579C18.7893 5.96086 19 6.46957 19 7V11" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15 3V7M7 3V7M3 11H19M14 18C14 19.0609 14.4214 20.0783 15.1716 20.8284C15.9217 21.5786 16.9391 22 18 22C19.0609 22 20.0783 21.5786 20.8284 20.8284C21.5786 20.0783 22 19.0609 22 18C22 16.9391 21.5786 15.9217 20.8284 15.1716C20.0783 14.4214 19.0609 14 18 14C16.9391 14 15.9217 14.4214 15.1716 15.1716C14.4214 15.9217 14 16.9391 14 18Z" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18 16.4961V18.0001L19 19.0001" stroke="#A4A4A4" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>

      :
      <svg width={width ? width : "24"}  height={height? height : "24"} viewBox="0 0 20 20" fill="none" strokeWidth='2px' xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5 15.2202V15.1428M10.5625 15.2202V15.1428M10.5625 11.0286V10.9512M15.0625 11.0286V10.9512M2.125 6.91425H17.875M4.16071 1V2.54304M15.625 1V2.54285M15.625 2.54285H4.375C2.51104 2.54285 1 3.92436 1 5.62855V15.9143C1 17.6185 2.51104 19 4.375 19H15.625C17.489 19 19 17.6185 19 15.9143L19 5.62855C19 3.92436 17.489 2.54285 15.625 2.54285Z" stroke={color ? color : "white"} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    }
    </>
  )
}
