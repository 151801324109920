export const po_status_class = (status_key) => {
  let status_class = ""
  if (status_key === 2) {
    status_class = "status-pending";
  } else if (status_key === 1) {
 status_class = "status-completed";
  } else if (status_key === 3) {
    status_class = "status-approved";
  } else {
   status_class = "status-canceled";
  }
  return status_class;
};
export const status_transfer_class = (status_key) => {
  let status_class = ""
  if (status_key === 2) {
    status_class = "status-pending";
  } else if (status_key === 3) {
    status_class = "status-transit";
  } else if (status_key === 1) {
    status_class = "status-approved";
  } else if (status_key === 4 || status_key === 5) {
    status_class = "status-completed";
  }
  else {
    status_class = "status-canceled";
  }
  return status_class;
};
export const status_receive_class = (status_key) => {
  let status_class = ""
  if (status_key === 4) {
    status_class = "status-received";
  } else if (status_key === 3) {
    status_class = "status-transit";
  } else if (status_key === 5) {
    status_class = "status-canceled";
  }
  return status_class;
};
export const status_priority = (priority_key) => {
  let status_class = ""
  if (priority_key === 0) {
    status_class = "low-priority";
  } else if (priority_key === 1) {
    status_class = "medium-priority";
  } else {
    status_class = "high-priority";
  }
  return status_class;
};
export const purchase_status = (status_key) => {
  let status_class = ""
  if (status_key === 2) {
    status_class = "status-pending";
  } else if (status_key === 1) {
    status_class = "status-approved";
  } else {
    status_class = "status-canceled";
  }
  return status_class;
};
