import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
export const Tablehead = function (props) {
  const { t } = useTranslation();
  const base_url = process.env.REACT_APP_BASE_ASSETS_URL;
  const { tablehead, setSelectAllStatus, setShowoption, setAllSelectedID } = props;
  const handleSelectAllCheckBox = (event) => {
    let pushIDs_for_tbl_rows = [];
    var checkBox = event.target;

    var child_check_class =
      "." + checkBox.getAttribute("data-select").replace("_main", "");
    var allCheckbox = document.querySelectorAll(child_check_class);
    if (checkBox.checked === true) {
      if (setSelectAllStatus === undefined) {
        allCheckbox.forEach(function (checkBox_selectAble) {
          checkBox_selectAble.checked = true;
          let tbl_row_id = checkBox_selectAble.getAttribute('data-id');
          let location_id = checkBox_selectAble.getAttribute('data-location');
          pushIDs_for_tbl_rows.push({ id: tbl_row_id, location_id: location_id, type: 1 });
        });

      }
      if (setShowoption !== undefined) {
        setShowoption(true)
      }
      if (setAllSelectedID !== undefined) {
        setAllSelectedID(pushIDs_for_tbl_rows)
      }

    } else {
      if (setSelectAllStatus === undefined) {
        allCheckbox.forEach(function (checkBox_selectAble) {
          checkBox_selectAble.checked = false;
          let tbl_row_id = checkBox_selectAble.getAttribute('data-id');
          let location_id = checkBox_selectAble.getAttribute('data-location');
          pushIDs_for_tbl_rows.push({ id: tbl_row_id, location_id: location_id, type: 0 });
        });
      }
      if (setShowoption !== undefined) {
        setShowoption(false)
      }
      removeRotateIcon();

    }
  };
  const [newcolumndata_, setnewcolumndata_] = useState(tablehead);
  useEffect(() => {
    setnewcolumndata_(tablehead)
  }, [tablehead])
  const removeRotateIcon = () => {
    var expand_col = document.querySelectorAll(".table_btn_expand_col");
    if (expand_col) {
      for (let i = 0; i < expand_col.length; i++) {
        expand_col[i].classList.remove("rotate-90");
      }
    }

  }
  const tableFilter = (index, event) => {

    var filter = [];
    var parentDiv = event.target.closest("table");
    var th_data = parentDiv.querySelectorAll("th");
    th_data.forEach(function (button) {
      var index_of_th = button.getAttribute("data-id");
      if (index_of_th === index) {
        var sort_of_th = button.getAttribute("aria-sort");
        var label = button.getAttribute("aria-label");
        var label_of_th = label.split(":");
        if (sort_of_th) {
          filter[label_of_th[0]] = sort_of_th;
        }
      }
    });
    removeRotateIcon();
  };

  return (
    <React.Fragment>
      {newcolumndata_ ? (
        <thead>
          <tr>
            {newcolumndata_?.map((result, index) => {
              if (result.class === " ") {
                result.class = "gisClassTh_main";
              }
              return (
                <React.Fragment key={index}>
                  {result.name === "check_box" ? (
                    <th>
                      <div className="com-check">
                        <input
                          checked={setSelectAllStatus}
                          id="th_comman_checkbox"
                          onClick={(e) => handleSelectAllCheckBox(e)}
                          className={result.class}
                          data-select={result.class}
                          type="checkbox"
                        />
                        <label
                          htmlFor="th_comman_checkbox"
                          className="com-check-label"
                        />
                      </div>
                    </th>
                  ) : null}
                  {result.name !== "check_box" ? (
                    <th
                      key={index}
                      data-id={index}
                      data-type="po_no"
                      className={result.class}
                      onClick={(e) => tableFilter(index, e)}
                    >
                      {result.image ? (
                        <>
                          <img
                            className=""
                            src={base_url + result?.image}
                            alt=""
                          />
                        </>
                      ) : (
                        t(result.lable)
                      )}
                      {result.sort ? (
                        <div className="up-down-arrow-btn">
                          {result?.class === 'danger-text' ?
                            <>
                              <img
                                className="up-down-arrow-img up-down-arrow-img-forward active"
                                src={base_url + "images/icons/up-down-arrow-danger.png"}
                                alt=""
                              />
                              <img
                                className="up-down-arrow-img up-down-arrow-img-reverse"
                                src={
                                  base_url +
                                  "images/icons/up-down-arrow-danger-reverse.png"
                                }
                                alt=""
                              />
                            </>
                            :
                            <>
                              <img
                                className="up-down-arrow-img up-down-arrow-img-forward active"
                                src={base_url + "images/icons/up-down-arrow.png"}
                                alt=""
                              />
                              <img
                                className="up-down-arrow-img up-down-arrow-img-reverse"
                                src={
                                  base_url +
                                  "images/icons/up-down-arrow-reverse.png"
                                }
                                alt=""
                              />
                            </>

                          }

                        </div>
                      ) : null}
                    </th>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tr>
        </thead>
      ) : null}
    </React.Fragment>
  );
};
