import React from 'react'

export default function CircledTick(props) {
  const { color, width, height } = props;
  return (
    <svg width={width ? width : "20"} height={height ? height : "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 10L9 12L13 8M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke={color ? color : "#212121"} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
