import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SetdataTable, UnSetdataTable } from "../common/api/setdatatable";
import {
  success, error, loading,
  Updateloading,
} from "../common/helpers/toastify";
import { Tablehead } from "../../components/common/modules/Tablehead";
import { poscustomorder_head } from "../../components/common/Tablehead";
import CheckoutBlank from "../checkout/checkoutBlank";
import "./custom.scss";
import { _Api } from "../common/api/_call";
import { store } from '../../source/index'
import { useSelector } from 'react-redux';
import CustomerSearchModal from "../common/module/customerSearch";
import TableColumnModal from "../../components/common/modules/tableColumn";
import { toFormatPrice, toUnformatPrice } from '../common/helpers/function';
import CheckoutSell from '../checkout/checkoutSell'
import CheckoutPayment from '../checkout/checkoutPayment'
import { Customsubtable } from "./subtable/Customsubtable";
import $ from 'jquery';
import { useTranslation } from 'react-i18next';

const CustomOrderDelivered = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  let reduxdata = store.getState()
  let OrgSettings = reduxdata?.app?.posorganisation_settings
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [newcolumndata, setnewcolumndata] = useState(poscustomorder_head);
  const customer = useSelector((state) => state.app.customer);
  const saleperson = useSelector((state) => state.app.saleperson);
  const [, setfilterby] = useState("");
  const [orderlist, setOrderlist] = useState();
  const [, setdeletestatus] = useState(false)
  const [searchproductstatus, setsearchproductstatus] = useState(false);
  const [orderid, setOrderid] = useState('');
  const [showcheckout, setShowcheckout] = useState('blank')
  const [showpayment, setShowpayment] = useState(false);
  const [UpdatedCart, setUpdatedCart] = useState();
  const [TransactionDate, setTransactionDate] = useState(new Date());
  const [excuteTable, setexcuteTable] = useState(false);
  const [searchlist, setSearchlist] = useState();
  const [customerid, setCustomerid] = useState()
  useEffect(() => {
    callcolumns();
  }, []);
  const callcolumns = async () => {
    try {
      const postdata = {
        name: "tbl_pos_customorderdelivered",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const Getcustomeorder = async () => {
    setexcuteTable(false);
    var expand_col = document.querySelector(".table_btn_expand_col");
    if (expand_col) { expand_col.classList.remove("rotate-90"); }
    try {
      setTimeout(async function () {
        UnSetdataTable('com-data-area_table');
        const postdata = {
          "customer_id": customer?.id,
          "date": TransactionDate,
          'order_type': 'custom_order'
        };
        let res = await _Api(postdata, `api/v1/POS/customOrder/getOrderList`);
        if (res.code === 200) {
          setexcuteTable(true);
          SetdataTable('com-data-area_table', '5', newcolumndata, [1]);
          setOrderlist(res?.data);

        } else {
          SetdataTable('com-data-area_table', '5', newcolumndata, [1]);
        }
      }, 100)
    }
    catch (err) {
      console.log(err, 'err');
    }
  }
  useEffect(() => {
    if (customer?.id) {
      Getcustomeorder();
    } else {
      Getcustomeorder();
    }
    // eslint-disable-next-line
  }, [customer?.id, TransactionDate]);

  const setSubTablecustom = async (order_id) => {
    var e = document.querySelector("#table-btn_" + order_id);
    var row = document.querySelector('#common-main-table-wrap-' + order_id);
    var classList_ = e.classList;
    if (classList_.contains("rotate-90")) {
      classList_.remove("rotate-90");
      let alllist_data = [...orderlist];
      alllist_data.filter(item => item.order_id === order_id).forEach((result) => {
        result.subTabledata = null
      })
      setOrderlist(alllist_data);
    } else {
      classList_.add("rotate-90");
      let alllist_data = [...orderlist];
      const postdata = {
        "Order_id": order_id
      };
      let prioritydata = await _Api(postdata, `api/v1/POS/customOrder/getSkuByOrderList`);
      var html_ = Customsubtable(prioritydata, order_id, t);
      alllist_data.filter(item => item.order_id === order_id).forEach((result) => {
        result.subTabledata = html_
      })
      setOrderlist(alllist_data);

      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-sub-table-wrap-' + order_id);
        if (rowSub) {
          $(row).after(rowSub);
          clearInterval(checkRowSub);
        }
      }, 100);
    }
  }
  const GetCartdetailorder = async () => {
    try {
      const postdata = {
        "order_id": orderid,
        'order_type': 'custom_order',
        "by_custom_order": true
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        setUpdatedCart(res?.data);
        setShowcheckout('sell');
      } else {
        navigate('/app/pos/custom/customOrderDelivered')
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getsearchlist = async (value) => {
    try {
      const posdata = {
        "search": value,
        "type": "inventory",
        "from": 'createYourOwn'
      }
      let res = await _Api(posdata, `api/v1/POS/customOrder/skuNameBySearch`)
      if (res.code === 200) {
        setSearchlist(res?.data);
      } else {
        setSearchlist([]);
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const AddtoCart = async (product_id) => {
    if (!customer?.id) {
      error(t("Customer is Requried!!"));
      return;
    }
    if (!saleperson?.value) {
      error(t("SalePerson is Requried!!"));
      return;
    }
    try {
      const postdata = {
        "product_id": product_id,
        "type": 'inventory',
        "customer_id": customer?.id,
        "sales_person_id": saleperson?.value,
        "order_type": "custom_order"
      };
      let res = await _Api(postdata, `api/v1/POS/customOrder/addItemToCart`);
      if (res.code === 200) {
        success(t("Successfully Add to Cart!!"));
        setShowcheckout('sell');
        setsearchproductstatus(true)

      } else {
        error(t("This Product Already in cart!!"));
      }
    } catch (err) {
      console.log(err, "err");
    }
  }
  const getprintcustomorder = async () => {
    if (!customer?.id && !customerid) {
      error(t("Customer is Requried!!"));
      return;
    }
    let GetloadingID = loading()
    try {
      const postdata = {
        "customer_id": customerid ? customerid : customer?.id
      }
      let res = await _Api(postdata, `api/v1/POS/reports/print/customOrderOutstanding`)

      if (res.code === 200) {
        setTimeout(() => {
          const exportLinkElement = document.createElement('a')
          exportLinkElement.hidden = true
          exportLinkElement.download = res?.name ? res?.name : 'report.pdf'
          exportLinkElement.href = res?.link
          exportLinkElement.text = 'downloading...'
          exportLinkElement.setAttribute('target', '_blank')
          document.body.appendChild(exportLinkElement)
          exportLinkElement.click()
          exportLinkElement.remove()
          Updateloading(t, GetloadingID, t('download successfully'))
        }, 50)
      }
      if (res.code === 400) {
        Updateloading(t, GetloadingID, res?.messgae, t('error'))
      }


    } catch (err) {
      console.log(err, 'err')
      Updateloading(t, GetloadingID, err, t('error'))
    }
  }
  const selectedproduct = (event, id, ststus) => {
    if (event.target.checked) {
      if (ststus === 5) {
        setOrderid(id)
      } else {
        setOrderid("")
      }
    } else {
      setOrderid("")
    }
  }
  return (
    <>
      <div className="main-body custom-order-table">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className='com-data-area'>
              <div className="catalog-area-header-area detail-area-header">
                <div className="catalog-area-header-area-control">
                  <div className="catalog-area-header-area-control-left page-back-btn-inside">
                    <Link to="/app/pos/custom/custom"><img className='page-back-btn-inside-img' src={base_assets + "/images/icons/arrow-left.png"} alt="back" /></Link>
                    <h1 className="heading">{t("Custom Order")}</h1>
                  </div>

                  <div className="catalog-area-header-area-control-right">
                    <div className="com-search-area">
                      <button className="com-search-area-left-img scan-img"><img src={base_assets + "/images/pos/icons/scan-icon.png"} alt="" /></button>
                      <input type="text" onChange={(e) => { getsearchlist(e.target.value) }} placeholder={t("Scan barcode, Search SKU")} id="dropdownMenuButton1" data-bs-toggle="dropdown" autoComplete="off" />
                      <ul className="dropdown-menu custom-search-dropdown" aria-labelledby="dropdownMenuButton1">
                        {searchlist?.length ? searchlist.map((result, key) => {
                          return (<li key={key}><Link className="dropdown-item" onClick={() => AddtoCart(result?._id)}>{result?.name}<span>{result?.SKU}{result?.stock_id ? '-' + result?.stock_id : ''}</span></Link></li>);
                        }) : ''}
                      </ul>
                      <button className="btn-style lh-0">
                        <img className="com-search-area-img" src={base_assets + "/images/pos/icons/magnifying-glass.png"} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='com-data-area-header delivered-header'>
                <div className='delivered-header-left custom-order-search'>
                  <CustomerSearchModal ></CustomerSearchModal>
                  <ul className='graph-indicator ms-4 text-nowrap'>
                    <li className='graph-indicator-single'>
                      <span className='graph-indicator-single-color danger-bg'></span>
                      <label className='graph-indicator-single-label'>{t("Create your own")}</label>
                    </li>
                    <li className='graph-indicator-single'>
                      <span className='graph-indicator-single-color status-pending'></span>
                      <label className='graph-indicator-single-label'>{t("Mix & Match")}</label>
                    </li>
                  </ul>
                </div>
                <div className='customer-inner-data-payment-table-top-bar-right placeholder-up-input-date date ms-auto me-2'>
                  <DatePicker
                    className="placeholder-up-input-field"
                    selected={TransactionDate}
                    onChange={(input) => setTransactionDate(input)}
                    name="TransactionDate"
                    dateFormat={OrgSettings?.date_picker}
                    autoComplete='none'
                  />
                  <img
                    className="placeholder-up-input-field-calender"
                    src={base_assets + "images/icons/calendar-green.svg"}
                    alt=""
                  />
                </div>
                <div className=" dropdown Dailysalereport-content-area-header-leftdropdown">
                  <button className='btn-style' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img src={base_assets + 'images/icons/ellipsis-circular.png'} alt="" /></button>
                  <ul className="dropdown-menu Dailysalereport-content-area-header-leftdropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li onClick={() => getprintcustomorder()}>
                      <Link className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" to="#">
                        <div className="dropdown-menu-img">
                          <img src={base_assets + "images/pos/report/print-icon.png"} alt="" />
                        </div>
                        {t("Print")}</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item  Dailysalereport-content-area-header-leftdropdown-menu-item"
                        to="#">
                        <div className="dropdown-menu-img">
                          <img src={base_assets + "images/pos/report/export-icon.png"} alt="" />
                        </div>
                        {t("Export")}</Link>
                    </li>
                    <li data-bs-toggle="modal" data-bs-target="#columnModal">
                      <Link className="dropdown-item Dailysalereport-content-area-header-leftdropdown-menu-item" to="#">
                        <div className="dropdown-menu-img">
                          <img src={base_assets + "images/icons/column-icon.png"} alt="" />
                        </div>
                        {t("Column")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="com-data-area-inner">

                <div className="main-body-main-table-wrap vertical-scroll-table" id="table-scroll" >
                  <table id='com-data-area_table' className="common-table first-row-gray w-100">
                    <Tablehead tablehead={poscustomorder_head} tblName={"tbl_pos_customorderdelivered"} setfilterby={setfilterby} />
                    <tbody className='dataTables_wrapper'>
                      <>
                        {orderlist?.length ? orderlist.map((result, key) => {
                          return (
                            <React.Fragment key={key}>
                              <tr className={result?.status_type === "custom_order" ? 'tr-left-red-border' : "tr-left-yellow-border"}
                                id={`common-main-table-wrap-${result?.order_id}`} key={result?.order_id}   >
                                <td className="row-check">
                                  <div className="com-check radio">
                                    <input type="radio"
                                      onClick={(e) => { setCustomerid(result?.customer_id) }}
                                      onChange={(e) => selectedproduct(e, result?.order_id, result?.status)}
                                      name='customchack'
                                      id={result?.order_id}
                                    />
                                    <label htmlFor={result?.order_id} className="com-check-label" />
                                  </div>
                                </td>
                                <td>{key + 1}</td>
                                <td>{result?.transaction_date}</td>
                                <td className='link-color'>{result?.order_no}</td>

                                <td className='sku-col table_btn_expand_col'
                                  onClick={() => setSubTablecustom(result?.order_id)}
                                  id={"table-btn_" + result?.order_id}>  <img
                                    className="sku-col-arrow cursor-pointer"
                                    src={
                                      base_assets +
                                      "/images/icons/right_green_button.png"
                                    }
                                    alt=""
                                  />
                                  {result?.SKU}</td>

                                <td className='danger-text'>{result?.customer}</td>
                                <td>{result?.Qty}</td>
                                <td>{toFormatPrice(result?.labour, { addSymbol: true })}</td>
                                <td>{toFormatPrice(result?.amount, { addSymbol: true })}</td>
                                <td className='main-green-text'>{toFormatPrice(result?.payment, { addSymbol: true })}</td>
                                <td className='danger-text'>{toFormatPrice(result?.balance_due, { addSymbol: true })}</td>
                                <td className='danger-text'>{result?.delivery_date}</td>
                                <td>{result?.location_name}</td>
                                <td>{result?.status === 1 ? <label className='status-tab open-order-bg'>{t("Open Order")}</label>
                                  :
                                  result?.status === 2 ?
                                    <label className='status-tab production-col'>{t("Production")}</label>
                                    :
                                    result?.status === 3 ?
                                      <label className='status-tab mgf-stock-bg'>{t("MFG Stock")}</label>
                                      :
                                      result?.status === 4 ?
                                        <label className='status-tab transit-bg-col'>{t("transit")} </label>
                                        :
                                        result?.status === 5 ?

                                          <label className='status-tab stoke-bg'>{t("Stock")}</label>
                                          :
                                          result?.status === 6 ?
                                            <label className='status-tab sol-out-col'>{t("Sold Out")} </label>
                                            :
                                            result?.status === 9 ?
                                              <label className='status-tab gray-tab'>{t("Close Order")}</label>
                                              : ''
                                }
                                </td>
                              </tr>
                              {
                                excuteTable ? (
                                  <>
                                    {typeof result.subTabledata == "undefined" ? (result.subTabledata = "") : result.subTabledata}
                                  </>
                                ) : null
                              }
                            </React.Fragment>
                          );
                        }) : ''}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{
                          orderlist
                            ? orderlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.Qty)),
                              0
                            )
                            : ""
                        }</td>
                        <td>{toFormatPrice(
                          orderlist
                            ? orderlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.labour)),
                              0
                            )
                            : ""
                          , { addSymbol: true })}</td>
                        <td>{toFormatPrice(
                          orderlist
                            ? orderlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.amount)),
                              0
                            )
                            : ""
                          , { addSymbol: true })}</td>
                        <td className='main-green-text'>{toFormatPrice(
                          orderlist
                            ? orderlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.payment)),
                              0
                            )
                            : ""
                          , { addSymbol: true })}</td>
                        <td className='danger-text'>{toFormatPrice(
                          orderlist
                            ? orderlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.balance_due)),
                              0
                            )
                            : ""
                          , { addSymbol: true })}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className='com-data-area-footer-btn'>
                  <button onClick={() => { GetCartdetailorder(); }}
                    className={orderid !== '' && showcheckout === 'blank' ? 'com-btn ms-auto' : 'com-btn ms-auto btn-disabled'}>
                    {t("Submit")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            {showcheckout === 'sell' ? (
              <CheckoutSell
                setShowcheckout={setShowcheckout}
                setdeletestatus={setdeletestatus}
                setUpdatedCart={setUpdatedCart}
                UpdatedCart={UpdatedCart}
                setShowpayment={setShowpayment}
                showpayment={showpayment}
                ordertype={"pos_order"}
                setsearchproductstatus={setsearchproductstatus}
                searchproductstatus={searchproductstatus}
                by_custom_order={true}
                order_id={orderid ? orderid : ''}
              />
            ) : showcheckout === 'payment' ? (
              <CheckoutPayment UpdatedCart={UpdatedCart} setShowcheckout={setShowcheckout} order_id={orderid ? orderid : ''} by_custom_order={true} />
            ) : (
              <CheckoutBlank />
            )}
          </div>
        </div>
      </div>
      <TableColumnModal tablehead={poscustomorder_head} tblName={"tbl_pos_customorderdelivered"} setnewcolumndata={setnewcolumndata}
        calldatatable={Getcustomeorder}></TableColumnModal>
    </>
  )
}

export default CustomOrderDelivered;