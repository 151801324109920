import React, { useEffect, useState, useRef } from 'react';
import { Route, Outlet } from 'react-router-dom';
import CustomSwitch from '../PublicCustomSwitch.js';
import HomePage from '../Ecommerce/template_two/default/Pages/ProductPage/HomePage.js';
import ProductDetailsPage from "../Ecommerce/template_two/default/Pages/ProductPage/ProductDetailpage.js"
import LogOut from '../Ecommerce/template_two/default/auth/LogOut.js';
import { Helmet } from 'react-helmet';
import { _Apiauth } from '../Ecommerce/template_two/common/api.js';
import Header from '../Ecommerce/template_two/default/include/Header/Header.js';
import Footer from '../Ecommerce/template_two/default/include/Footer/Footer.js';
import WishlistPage from '../Ecommerce/template_two/default/Pages/WishList/WishlistPage.js';
import ShippingPage from '../Ecommerce/template_two/default/Pages/ShippingPage/ShippingPage.js'
import MyAccountAddress from '../Ecommerce/template_two/default/Pages/AccountPage/MyAccountAddress.js';
import MyAccountDetailsPage from '../Ecommerce/template_two/default/Pages/AccountPage/MyAccountDetailsPage.js';
import ChangePassword from '../Ecommerce/template_two/default/Pages/AccountPage/ChangePassword.js';
import PurchaseHistory from '../Ecommerce/template_two/default/Pages/AccountPage/PurchaseHistory.js';
import AppointmentData from '../Ecommerce/template_two/default/Pages/AccountPage/AppointmentData.js';
import AccountShippingAdd from '../Ecommerce/template_two/default/Pages/AccountPage/AccountShippingAdd.js';
import StripeTest from "../Ecommerce/template_two/default/Pages/StripeTest.js";
import Appointment from '../Ecommerce/template_two/default/Pages/Appointment/Appointment.js';
import AppointmentConfirm from '../Ecommerce/template_two/default/Pages/Appointment/AppointmentConfirm.js';
import ShoppingCart from '../Ecommerce/template_two/default/Pages/ShippingPage/ShoppingCart.js';

export default function EcommerceRoutes() {
    const dataFetched = useRef();
    const [websiteinfo, setWebsiteinfo] = useState()
    const [showecomdata, setShowecomdata] = useState(false)
    const [openmodal, setOpenmodal] = useState({
        showLoginModel: false,
        showRegisterModel: false,
        showemailvarifymodal: false,
        forgetpassword: false,
        resetpassword: false,
        newpassword: false
    })
    const [newpassworddata, SetNewpassworddata] = useState()
    const [forgetemail, setForgetemail] = useState()
    const [emailverifydata, setEmailVerifydata] = useState()
    const ecominfo = async () => {
        try {
            let postdata = {}
            let res = await _Apiauth(postdata, `info`)
            if (res?.statusCode === 200) {
                setWebsiteinfo(res?.data)
            }
            setShowecomdata(true)
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    const formatefunction = async () => {
        try {
            let postdata = {}
            let res = await _Apiauth(postdata, `formatefunction`)
            if (res?.statusCode === 200) {
                var jsonData = JSON.stringify(res?.data);
                localStorage.setItem("formatefunction", jsonData);
            }
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        if (dataFetched.current) return;
        dataFetched.current = true
        ecominfo()
        formatefunction()
    }, [])
    const AdminReactActive = function () {
        return (
            <>
                <div className={openmodal.showLoginModel || openmodal.showRegisterModel || openmodal.showemailvarifymodal || openmodal.forgetpassword || openmodal.resetpassword || openmodal.newpassword ? "ecommerce-main-layout overflow-hidden vh-100" : "ecommerce-main-layout"}>
                    {showecomdata ?
                        <>
                            <div className="ecommerce-main-layout-header">
                                <Header
                                    websiteinfo={websiteinfo}
                                    openmodal={openmodal}
                                    setOpenmodal={setOpenmodal}
                                    SetNewpassworddata={SetNewpassworddata}
                                    newpassworddata={newpassworddata}
                                    setForgetemail={setForgetemail}
                                    forgetemail={forgetemail}
                                    setEmailVerifydata={setEmailVerifydata}
                                    emailverifydata={emailverifydata} />
                            </div>
                            <Outlet/>
                            <div className="ecommerce-main-layout-footer">
                                <Footer />
                            </div>
                        </>
                        : ""}

                </div>
            </>
        );

    };
    return (
        <>
            <Helmet>
                <title>{websiteinfo?.websiteInfo?.metaTitle}</title>
                <link rel="icon" type="image/png" href={websiteinfo?.websiteInfo?.favicon} sizes="16x16" />
                <meta name="description" content={websiteinfo?.websiteInfo?.metaDescription} />
            </Helmet>
            <CustomSwitch>
                <Route element={<AdminReactActive />}>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/Category/:token" element={<HomePage />} />
                    <Route path="/Collection/:token" element={<HomePage/>} />
                    <Route path="/account" element={<MyAccountDetailsPage />} />
                    <Route path="/account/address" element={<MyAccountAddress />} />
                    <Route path="/account/addressdetails" element={<AccountShippingAdd />} />
                    <Route path="/account/changepassword" element={<ChangePassword />} />
                    <Route path="/account/purchasehistory" element={<PurchaseHistory />} />
                    <Route path="/account/appointmentdata" element={<AppointmentData />} />
                    <Route path="/product/:token" element={<ProductDetailsPage />} />
                    <Route path="/appointment" element={<Appointment />} />
                    <Route path="/wishlist" element={<WishlistPage setOpenmodal={setOpenmodal} />} />
                    <Route path="/stripetest" element={<StripeTest />} />
                    <Route path="/appointmentconfirm" element={<AppointmentConfirm />} />
                    <Route path="/shoppingcart" element={<ShoppingCart />} />
                    <Route path="/wishlist" element={<WishlistPage />} />
                    <Route path="/shipping" element={<ShippingPage setOpenmodal={setOpenmodal} />} />
                    <Route path="/logout" element={<LogOut />} />
                </Route>
            </CustomSwitch>
        </>
    );
}

