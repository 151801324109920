import React, { useState, useEffect } from "react";
import "../master.scss"
import { _Api } from "../../api/_call";
import Select from "react-select";
import { useTranslation } from 'react-i18next';
import { error, loading, Updateloading } from "../../common/helpers/toastify";
const EditCurrency = (props) => {
    const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
    const { setEditItem, setShowedit, dataid, create, setDataid, setCreate } = props
    const [currency, setCurrency] = useState();
    const { t } = useTranslation();
    const [exchangerate, setExchangerate] = useState()
    const [currencyarr, setCurrencyarr] = useState()
    const [exchangeratefor, setExchangeRateFor] = useState()
    const [active, setActive] = useState(true)
    const [defaultcurrency, setDefaultcurrency] = useState(false)
    const getcurrencylist = async () => {
        try {
            let postdata = {}
            let res = await _Api(postdata, `api/v1/App/organisation/getAll_Currency`)
            let currencyarr = []
            res?.data.forEach((result) => {
                currencyarr.push({
                    lable: result,
                    value: result
                })
            })
            setCurrencyarr(currencyarr)
        }
        catch (err) {
            console.log(err, "err")
        }
    }
    useEffect(() => {
        getcurrencylist()
    }, [])

    const currencyview = async () => {
        if (create) {
            setExchangerate("")
            setExchangeRateFor("")
            setActive(true)
            setCurrency({
                lable: "",
                value: ""
            })
            setDefaultcurrency("")

        } else if (dataid) {
            try {
                let postdata = {
                    "id": dataid
                }
                let res = await _Api(postdata, `api/v1/App/master/currencyMaster`)
                if (res?.code === 200) {
                    setExchangerate(res?.data?.exchangeRate)
                    setExchangeRateFor(res?.data?.exchangeRateFor)
                    setCurrency({
                        lable: res?.data?.currency,
                        value: res?.data?.currency
                    })
                    setDefaultcurrency(res?.data?.Default_currency)
                    if (res?.data?.Status === "1") {
                        setActive(true)
                    } else {
                        setActive(false)
                    }

                }
            }
            catch (err) {
                console.log(err, "err")
            }
        }

    }
    useEffect(() => {
        if (dataid || create) {
            currencyview()
        }
        // eslint-disable-next-line
    }, [dataid, create])

    const editcurrency = async () => {
        if (!currency?.value) {
            error(t("Currency is required."))
            return
        }
        setShowedit(false)
        let GetloadingID = loading()
        try {
            let postdata = {
                "id": dataid,
                "currency": currency?.value,
                "exchangeRate": exchangerate,
                "Status": active ? "1" : "0",
                "edit": dataid ? true : false,
                "is_default": defaultcurrency ? 1 : 0
            }
            let res = await _Api(postdata, `api/v1/App/master/currencyMasterEdit`)
            if (res?.code === 200) {
                if (dataid) {
                    Updateloading(t, GetloadingID, (res?.message));
                } else {
                    Updateloading(t, GetloadingID, (res?.message));
                }
                setDataid("")
                setCreate(false)
                setShowedit(true)
                setEditItem(false)
                setExchangerate("")
                setExchangeRateFor("")
                setActive(true)
                setCurrency({
                    lable: "",
                    value: ""
                })
                setDefaultcurrency("")
            } else {
                Updateloading(t, GetloadingID, (res?.errors), 'error');
            }

        }
        catch (err) {
            console.log(err, "err")
        }
    }
    return (
        <>
            <div className="edit-item-area bg-white">
                <div className="edit-item-area-header d-flex align-items-center justify-space-between mb-4">
                    <div className="me-auto">
                        <label className="d-block">{t("Currency")}: {exchangeratefor} </label>
                        <label className="d-block">{t("Exchange Rate")}: {exchangerate}</label>
                    </div>
                    <button className="square-btn square-btn-white btn-close focus-none"
                        onClick={() => { setEditItem(false); setDataid(""); setCreate(false) }}></button>
                    <button className="square-btn" onClick={() => editcurrency()}><img src={base_assets + 'images/icons/white-save.png'} alt="" /></button>
                </div>
                <div className="edit-item-area-body">
                    <div className="row mx-0">
                        <div className="col-md-8 ps-0">
                            <div className="v3-input-group">
                                <label className="v3-input-group-label">{t("Currency")} <sup>*</sup></label>
                                <Select
                                    className="select-set select w-100 p-0"
                                    placeholder=""
                                    options={currencyarr}
                                    value={currency}
                                    onChange={(e) => setCurrency(e)}
                                    classNamePrefix="common-select"
                                    getOptionLabel={(e) => (
                                        <div className="common-select-icon">{e.lable}</div>
                                    )}
                                    isSearchable={false}
                                />
                            </div>

                            <div className="v3-input-group">
                                <label className="v3-input-group-label">{t("Exchange Rate")}</label>
                                {currency?.value === exchangeratefor ?
                                    <div className="d-flex align-items-center text-nowrap gap-1 v3-input-group-input pe-0 border-end-0">
                                        <span className="light-gray-text">1 {exchangeratefor} =</span>
                                        <input type="text" className="v3-input-group-input w-100 border-start-0"
                                            value={exchangerate || ''}
                                            onChange={(e) => setExchangerate(1)} />
                                    </div> :
                                    <div className="d-flex align-items-center text-nowrap gap-1 v3-input-group-input pe-0 border-end-0">
                                        <span className="light-gray-text">1 {exchangeratefor} =</span>
                                        <input type="text" className="v3-input-group-input w-100 border-start-0"
                                            value={exchangerate || ''}
                                            onChange={(e) => setExchangerate(e.target.value)} />
                                    </div>
                                }
                            </div>
                            <div className="v3-input-group">
                                <label className="v3-input-group-label">{t("Default Currency")}</label>
                                <div className="form-check form-switch ps-0">
                                    <input className="form-check-input float-none ms-2" type="checkbox" id="active"
                                        checked={defaultcurrency}
                                        onChange={(e) => setDefaultcurrency(e.target.checked)} />
                                </div>
                            </div>
                            <div className="d-flex align-items-center text-nowrap gap-1 v3-input-group-input pe-0 border-end-0">
                                <span className="light-gray-text">{t("Current")} : </span>
                                <span>{exchangeratefor}</span>
                            </div>
                        </div>
                        <div className="col-md-3 offset-md-1 pe-0 text-end">
                            <div className="form-check form-switch ps-0">
                                <label className="form-check-label" htmlFor="active">{t("Active")}</label>
                                <input className="form-check-input float-none ms-2" type="checkbox" id="active"
                                    checked={active ? true : false}
                                    onChange={(e) => setActive(e.target.checked)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditCurrency;