import React from 'react'

export default function FilterIconMain(props) {
    const { width, height } = props
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 15.6H15C15.1499 15.5997 15.2945 15.6556 15.4053 15.7566C15.5161 15.8576 15.5851 15.9965 15.5986 16.1458C15.6122 16.2951 15.5693 16.4441 15.4785 16.5634C15.3877 16.6827 15.2555 16.7637 15.108 16.7904L15 16.8H9C8.85006 16.8003 8.70546 16.7444 8.59466 16.6434C8.48386 16.5424 8.4149 16.4035 8.40135 16.2542C8.3878 16.1049 8.43065 15.9559 8.52146 15.8366C8.61227 15.7173 8.74446 15.6363 8.892 15.6096L9 15.6ZM6.6 10.8H17.4C17.5499 10.7997 17.6945 10.8556 17.8053 10.9566C17.9161 11.0576 17.9851 11.1965 17.9986 11.3458C18.0122 11.4951 17.9693 11.6441 17.8785 11.7634C17.7877 11.8827 17.6555 11.9637 17.508 11.9904L17.4 12H6.6C6.45006 12.0003 6.30546 11.9444 6.19466 11.8434C6.08386 11.7424 6.0149 11.6035 6.00135 11.4542C5.9878 11.3049 6.03065 11.1559 6.12146 11.0366C6.21227 10.9173 6.34446 10.8363 6.492 10.8096L6.6 10.8ZM4.2 6H19.8C19.9499 5.99973 20.0945 6.0556 20.2053 6.15661C20.3161 6.25763 20.3851 6.39646 20.3986 6.54579C20.4122 6.69511 20.3693 6.84409 20.2785 6.9634C20.1877 7.08271 20.0555 7.16369 19.908 7.1904L19.8 7.2H4.2C4.05006 7.20028 3.90546 7.14441 3.79466 7.04339C3.68386 6.94238 3.6149 6.80354 3.60135 6.65422C3.5878 6.50489 3.63065 6.35591 3.72146 6.2366C3.81227 6.1173 3.94446 6.03631 4.092 6.0096L4.2 6Z" fill="#A4A4A4"/>
</svg>
  )
}
