import React, { useState, useEffect, useCallback } from 'react'
import Chart from 'react-apexcharts'
import { LocationList } from "../../common/Commongraphql"
import { _Api } from '../../api/_call'
import { useTranslation } from 'react-i18next';
const PaymentMethods = (props) => {
  const { duplicateHandler, deletedublicate } = props
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const { t } = useTranslation();
  const [fixed, setFixed] = useState(false)
  const [graphdata, setGraphdata] = useState()
  const [tabledata, setTabledata] = useState([])
  const [pinchartdata, setPinchartdata] = useState({ label: [], data: [] })
  const [locationarrayList, setLocationarrayList] = useState([])
  const [completeDataObject, setCompliteDataObject] = useState({
    "timeset": "Monthly",
    "graphtype": "Chart",
    "location": {
      name: "All",
      id: ""
    },
  })
  let time_periods = ['Daily', 'Monthly', 'Yearly', 'Weekly']

  const paymentdata = useCallback(async () => {
    try {
      let postdata = {
        "graphtype": completeDataObject?.graphtype,
        "duration": completeDataObject?.timeset,
        "location": completeDataObject?.location?.id
      }
      let res = await _Api(postdata, `api/v1/dashboard/paymentMethodStatics`)
      if (res?.code === 200) {
        if (postdata?.graphtype === "Chart") {
          setGraphdata(res?.data)
        } else if (postdata?.graphtype === "Table") {
          setTabledata(res?.statics)
        } else if (postdata?.graphtype === "PieChart") {
          setPinchartdata(res?.statics)
        }
      }
    }
    catch (err) {
      console.log(err, "err")
    }
  }, [completeDataObject]);
  useEffect(() => {
    paymentdata()
  }, [completeDataObject, paymentdata])
  const paymentMethodsChart = {
    series: graphdata?.data?.length ? graphdata?.data : [],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '65%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: graphdata?.xaxis?.length ? graphdata?.xaxis : [],
      },
      yaxis: [
        {
          title: {
            text: 'Qty',
          },
        }
      ],
      fill: {
        opacity: 1,
      },
      colors: graphdata?.color?.length ? graphdata?.color : [],
    },
  };
  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const paiChart = {
    options: {
      chart: {
        type: "donut",
      },
      labels: pinchartdata?.xaxis?.length ? pinchartdata?.xaxis : [],
      title: {
        text: "Pie Chart",
        align: "center",
        style: {
          fontSize: "20px",
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "70%",
          },
        },
      },
      legend: {
        position: "bottom",
      },
      colors: Array.from({ length: pinchartdata?.xaxis?.length }, () => generateRandomColor()),
    },
    series: pinchartdata?.data?.length ? pinchartdata?.data : [],
  };
  return (
    <div className={`dashboard-area-cards-single h-100 ${fixed ? 'fixed' : ''}`}>
      <div className='dashboard-area-cards-single-back-layer d-none position-absolute start-0 top-0 w-100 h-100' onClick={() => setFixed(false)}></div>
      <div className="dashboard-area-cards-single-inner h-100 bg-white rounded-3 p-3">
        <div className="dashboard-area-cards-single-header d-flex align-items-center">
          <div className="w-auto pe-0 drag-btn">
            <img
              className="drag-btn-icon"
              src={base_assets + 'images/admin/icons/drag-icon.png'}
              alt=""
            />
          </div>
          <div className="dashboard-area-cards-single-heading fw-semibold ms-3 me-auto">
            {t("Payments Methods")}
          </div>
          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle border-right-radius-0 fw-semibold"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {completeDataObject?.location?.name ? completeDataObject?.location?.name : "Source Type"}
              <i className="fa fa-caret-down ms-2" aria-hidden="true"></i>
            </span>
            <ul className="dropdown-menu">
              <li
                onClick={() =>
                  setCompliteDataObject(prevState => ({
                    ...prevState,
                    "location": {
                      name: "All",
                      id: ""
                    }
                  }))}>
                <span className="dropdown-item">All</span>
              </li>
              {
                locationarrayList?.length ? locationarrayList.map((result, key) => {
                  return (
                    <li
                      key={key}
                      onClick={() =>
                        setCompliteDataObject(prevState => ({
                          ...prevState,
                          "location": result
                        }))}>
                      <span className="dropdown-item">{result?.name ? result?.name : ""}</span>
                    </li>
                  )
                }) : ""
              }
            </ul>
          </div>
          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle border-left-radius-0 fw-semibold"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t(`${completeDataObject?.timeset}`)}
              <i className="fa fa-caret-down ms-2" aria-hidden="true"></i>
            </span>
            <ul className="dropdown-menu">
              {time_periods?.length ? time_periods.map((result, key) => {
                return (
                  <li key={key}
                    onClick={() =>
                      setCompliteDataObject(prevState => ({
                        ...prevState,
                        timeset: result
                      }))}>
                    <span className="dropdown-item">{t(`${result}`)}</span>
                  </li>
                )
              }) : ""}
            </ul>
          </div>
          <div className="dropdown common-dropdown dashboard-area-cards-single-dropdown">
            <span
              className="btn dropdown-toggle border-0 p-0 px-2 ms-1"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={base_assets + 'images/admin/icons/gray-ellipsis.png'} alt='' />
            </span>
            <ul className="dropdown-menu">
              <li
                onClick={() =>
                  setCompliteDataObject(prevState => ({
                    ...prevState,
                    graphtype: "Chart"
                  }))}>
                <span className="dropdown-item">
                  <i className="fa fa-line-chart me-2" aria-hidden="true"></i>{' '}
                  {t("Chart")}
                </span>
              </li>
              <li
                onClick={() =>
                  setCompliteDataObject(prevState => ({
                    ...prevState,
                    graphtype: "PieChart"
                  }))}>
                <span className="dropdown-item">
                  <i className="fa fa-pie-chart me-2" aria-hidden="true"></i>{' '}
                  {t("Pie Chart")}
                </span>
              </li>
              <li
                onClick={() =>
                  setCompliteDataObject(prevState => ({
                    ...prevState,
                    graphtype: "Table"
                  }))}>
                <span className="dropdown-item">
                  <i className="fa fa-table me-2" aria-hidden="true"></i> {t("Table")}
                </span>
              </li>
              <li onClick={() => setFixed(current => !current)}>
                <span className="dropdown-item">
                  {fixed ?
                    <><i className="fa fa-sign-out me-2" aria-hidden="true"></i>{t("Exit Screen")} </>
                    :
                    <><i className="fa fa-arrows-alt me-2" aria-hidden="true"></i>{t("Full Screen")}</>
                  }
                </span>
              </li>
              <li onClick={() => duplicateHandler("PaymentMethod")}>
                <span className="dropdown-item">
                  <i className="fa fa-files-o me-2" aria-hidden="true"></i> {t("Duplicate")}
                </span>
              </li>
              <li onClick={() => deletedublicate("PaymentMethod")}>
                <span className="dropdown-item">
                  <i className="fa fa-trash-o me-2" aria-hidden="true"></i>{' '}
                  {t("Delete")}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="dashboard-area-cards-single-chart-area py-3">
          {completeDataObject?.graphtype === "Chart" ?
            <div className='dashboard-area-cards-single-chart-area-inner' id="paymentMethodsChart">
              <Chart
                options={paymentMethodsChart.options}
                series={paymentMethodsChart.series}
                type="bar"
                height={350}
              />
            </div> :
            completeDataObject?.graphtype === "Table" ?
              <div className='dashboard-area-cards-single-chart-area-table'>
                <table className='w-100'>
                  <thead>
                    <tr>
                      <th className='text-center'>{t("Bank")}</th>
                      <th className='text-center'>{t("Cash")}</th>
                      <th className='text-center'>{t("Credit Note")}</th>
                      <th className='text-center'>{t("Credit Card")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabledata?.length ? tabledata.map((result, key) => {
                      return (
                        <tr key={key}>
                          <td className='text-center'>{result?.Bank ? result?.Bank : 0}</td>
                          <td className='text-center'>{result?.Cash ? result?.Cash : 0}</td>
                          <td className='text-center'>{result?.CreditNote ? result?.CreditNote : 0}</td>
                          <td className='text-center'>{result?.Credit_Card ? result?.Credit_Card : 0}</td>
                        </tr>
                      )
                    }) : ""}

                  </tbody>
                </table>
              </div> :
              completeDataObject?.graphtype === "PieChart" ?
                <div className='dashboard-area-cards-single-chart-area-inner'>
                  <Chart
                    options={paiChart.options}
                    series={paiChart.series}
                    type="donut"
                  />
                </div> : ""}
        </div>
      </div>
      <LocationList setLocationarrayList={setLocationarrayList} />
    </div>
  )
}
export default PaymentMethods
