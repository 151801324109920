import { createSlice } from '@reduxjs/toolkit';
const OrgSettings1 = localStorage.getItem("getPOSSettings");
let OrgSettings = JSON.parse(OrgSettings1);
const initialState = {
  reactactive: false,
  totalcountsnumberstatus: false,
  posorganisation_settings: OrgSettings,
  organisation_settings: {
    "date_format": "DD/MM/YYYY",
    "price_format": {
      "currency": "USD",
      "show_amount_in": "Million",
      "decimal_point": "2",
      "thousand_separator": ","
    },
    "timezones": Intl.DateTimeFormat().resolvedOptions().timeZone
  },
  isSessionExpiredModalVisible: false,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateReactActive: (state, action) => {
      state.reactactive = action.payload;
    },
    updateMiddlewareLocation: (state, action) => {
      state.Middlelocation = action.payload;
    },
    updateGeneralSettings: (state, action) => {
      state.generalsettings = action.payload;
    },
    updateUserPermission: (state, action) => {
      state.ApplyParmission = action.payload;
    },
    updateModulePermission: (state, action) => {
      state.ModulePermission = action.payload;
    },
    updateTotalCounts: (state, action) => {
      state.totalcountsnumber = action.payload;
    },
    OrganisationSettings: (state, action) => {
      state.organisation_settings = action.payload;
    },
    updateTotalStatus: (state, action) => {
      state.totalcountsnumberstatus = action.payload;
    },
    updateSaleperson: (state, action) => {                                     // pos saleperson           
      state.saleperson = action.payload;
    },
    updateCustomer: (state, action) => {                                       // pos customer
      state.customer = action.payload;
    },
    posOrganisationSettings: (state, action) => {                                 // pos customer
      state.posorganisation_settings = action.payload;
    },
    posstatics: (state, action) => {                                 // pos customer
      state.posstatics = action.payload;
    },
    showSessionExpiredModal: (state, action) => {
      state.isSessionExpiredModalVisible = action.payload;
    },
    wishlistobjectheader: (state, action) => {
      state.iswishlistobjectheader = action.payload;
    },
  },
});


export const {
  updateReactActive,
  updateGeneralSettings,
  updateUserPermission,
  updateModulePermission,
  updateTotalCounts,
  updateTotalStatus,
  OrganisationSettings,
  posOrganisationSettings,
  updateSaleperson,
  updateCustomer,
  updateMiddlewareLocation,
  showSessionExpiredModal,
  posstatics,
  wishlistobjectheader
} = appSlice.actions;

export default appSlice.reducer;