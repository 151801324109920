import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DateTime from "../common/DateTime";
import { DateRange } from "../common/helpers/daterange";
import { Tablehead } from "../common/modules/Tablehead";
import { userLogTablethead, operationLogTablethead } from "../common/Tablehead";
import { SetdataTable, UnSetdataTable } from "../api/setdatatable";
import { _Api } from "../api/_call";
const UserLogs = (props) => {
  const { t } = useTranslation();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [datefilter, setDatefilter] = useState([]);
  const [userlogsdata, setUserlogsdata] = useState()
  const [newcolumndata, setnewcolumndata] = useState(userLogTablethead, operationLogTablethead);
  const [operationlogrepostlist, setOperationlogrepostlist] = useState([])
  const { state } = useLocation();
  const userlogslist = async () => {
    UnSetdataTable("userlogslist");
    try {
      const postdata = {
        "user_id": state?.userid
      };
      let res = await _Api(postdata, `api/v1/App/User/LoginDetails`);
      if (res.code === 200) {
        setUserlogsdata(res?.data)
        setTimeout(() => {
          SetdataTable("userlogslist", 0, newcolumndata, [0]);
        }, 50)

      }
    }
    catch (err) {
      SetdataTable("userlogslist", 0, newcolumndata, [0]);
      console.log(err, 'err');
    }
  }

  useEffect(() => {
    userlogslist();
    operationlogrepost();
    // eslint-disable-next-line
  }, []);
  const operationlogrepost = async (current, firstlimit) => {
    UnSetdataTable("operationlogrepostlist");
    try {
      const postdata = {
        "start_date": datefilter ? datefilter[0] : "",
        "end_date": datefilter ? datefilter[1] : "",
      };
      let res = await _Api(postdata, `api/v1/App/User/OperationLogs`);
      if (res.code === 200) {
        setTimeout(() => {
          SetdataTable("operationlogrepostlist", 0, newcolumndata, [0]);
        }, 2000)
        setOperationlogrepostlist(res?.data)


      }
    }
    catch (err) {
      SetdataTable("operationlogrepostlist", 0, newcolumndata, [0]);
      console.log(err, 'err');
    }
  }

  const callcolumns = async () => {
    try {
      const postdata = {
        name: "user_logs",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    callcolumns();
  }, []);
  return (
    <>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <h1 className="main-body-heading mb-4">
                {t("User")}
              </h1>

              <div className="main-body-top-status-bar">

                <div className="main-body-top-status-bar-multi-date-picker align-items-center">
                  <DateRange setDatefilter={setDatefilter} />
                  <Link to="/app/users/UserLogs" className="btn-style stock-take-inner-row-col-mid-header-right-btn mx-1">
                    <img className="note" src={base_assets + "images/icons/printer-icon.png"} alt="" />
                  </Link>
                  <Link to="/app/users/adduser" className="main-body-top-inputs-bar-col-right-submit-btn com-btn"><i className="fa fa-user-o me-2" aria-hidden="true"></i> {t("Add User")}</Link>
                </div>
              </div>
              <ul className="nav nav-tabs user-log-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="user-log-tab" data-bs-toggle="tab" data-bs-target="#user-log-tab-pane" type="button" role="tab" aria-controls="user-log-tab-pane" aria-selected="true">{t("User Log Report")}  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="operation-log-tab" data-bs-toggle="tab" data-bs-target="#operation-log-tab-pane" type="button" role="tab" aria-controls="operation-log-tab-pane" aria-selected="false">{t("Operation Log Report")}</button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="user-log-tab-pane" role="tabpanel" aria-labelledby="user-log-tab" tabIndex="0">
                  <div
                    className="main-body-main-table-wrap position-relative"
                  >
                    <table
                      className="stripe row-border order-column common-table table-striped main-table w-100"
                    >
                      <Tablehead
                        tablehead={userLogTablethead}
                        tblName={"user"}
                      />
                      <tbody>
                        {userlogsdata?.length ? userlogsdata.map((result, key) => {
                          return (
                            <tr key={key}>
                              <td className="fixed-side">{result?.name ? result?.name : "-"}</td>
                              <td className="fixed-side">{result?.date ? result?.date : "-"} <span className="badge bg-danger rounded-1">{result?.countryCode && result?.countryCode}</span></td>
                              <td className="fixed-side">{result?.type ? result?.type : "-"}</td>
                              <td className="fixed-side">{result?.ip_address ? result?.ip_address : "-"}</td>
                              <td className="fixed-side">{result?.platform ? result?.platform : "-"}</td>
                            </tr>
                          )
                        }) :
                          <tr>
                            <td colSpan="50" className="text-center">
                              {' '}
                              {t("No Data Available In Table")}
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="tab-pane fade" id="operation-log-tab-pane" role="tabpanel" aria-labelledby="operation-log-tab" tabIndex="0">
                  <div
                    className="main-body-main-table-wrap position-relative"
                  >
                    <table
                      className="stripe row-border order-column common-table table-striped main-table w-100"
                    >
                      <Tablehead
                        id="operationlogrepostlist"
                        tablehead={operationLogTablethead}
                      />
                      <tbody>
                        {operationlogrepostlist?.length ? operationlogrepostlist.map((result, key) => {
                          return (
                            <tr className="" key={key}>
                              <td className="fixed-side">{result?.date ? result?.date : ""}  <span className="badge bg-danger rounded-1">{result?.country ? result?.country : ""}</span></td>
                              <td className="fixed-side">{result?.user ? result?.user : ""}</td>
                              <td className="fixed-side">{result?.Category ? result?.Category : ""}</td>
                              <td className="fixed-side">{result?.action ? result?.action : ""}</td>
                              <td className="fixed-side">{result?.Operation_Detail ? result?.Operation_Detail : ""}</td>
                            </tr>
                          )
                        }) :
                          <tr>
                            <td colSpan="50" className="text-center">
                              {' '}
                              {t("No Data Available In Table")}
                            </td>
                          </tr>
                        }

                      </tbody>
                      <tfoot>
                        <tr>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserLogs;