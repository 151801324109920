import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { _Api } from "../../api/_call";
import { SetdataTable, UnSetdataTable } from "../../api/setdatatable";
import { LocationList } from "../../common/graphql/commonlayouts";
import DateTime from "../../common/DateTime";
import TableColumnModal from "../../common/modules/tableColumn";
import ConfirmationModel from "../../common/modules/confirmationmodel";
import { Tablehead } from "../../common/modules/Tablehead";
import Advancedfiltermodel from "../../common/modules/advanced-filter-model";
import { VoucherList } from "../../common/graphql/commonlayouts";
import { edittransferlisthead } from '../../common/Tablehead';
import NotesModalLabel from "../../common/modules/NotesModalLabel";
import { success, error } from "../../common/helpers/toastify";
import { toFormatPrice, toUnformatPrice } from "../../common/helpers/function";
import TransAndDeliverDate from "../../common/modules/TransAndDeliverDate";
import SocialModal from "../../common/modules/SocialModal/SocialModal";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'
import Select from "react-select";
import { useTranslation } from 'react-i18next';
const EditstockTransfer = (props) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  let navigate = useNavigate();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [limit, setLimit] = useState(100);
  const [filtername, setFiltername] = useState('');
  const [form_note, setForm_note] = useState(null);
  const [, setcommanSelected_vouc] = useState(null);
  const [newcolumndata, setnewcolumndata] = useState(edittransferlisthead);
  const [editstocktransferlist, setEditstocktransferlist] = useState();
  const [oldeditstocktransferlist, setOldeditstocktransferlist] = useState();
  const [printurl, setPrinturl] = useState()
  const [transactionDate, set_transactionDate] = useState(state?.transitiondate ? new Date(state?.transitiondate) : new Date());
  const [deliveredDate, set_deliveredDate] = useState(state?.deliverydate ? new Date(state?.deliverydate) : new Date());
  const [commanSelected_loc, setcommanSelected_loc] = useState(null);
  const [deletearray, setDeletearray] = useState([]);
  const [selecteditem, setSelecteditem] = useState([]);
  const [SelectAllStatus, setSelectAllStatus] = useState();
  const [datalodaer, setDatsLodaer] = useState(false)
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const callcolumns = async () => {
    try {
      const postdata = {
        name: 'tbl_edit_transfer_list',
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const Getedittransferinventory = async (firstlimit, search) => {
    setDatsLodaer(true)
    UnSetdataTable("tbl_editTransfer");
    try {
      const postdata = {
        "search": search ? search : "",
        "limit": firstlimit ? firstlimit : limit,
        "skip": 0,
        "tf_no": state?.tf_no ? state?.tf_no : ''
      };
      let restransferinventory = await _Api(postdata, `api/v1/Inventory/stocktransfer/editStockTransferList`);
      if (restransferinventory.code === 200) {
        let Olddata = restransferinventory.data?.po_product_data;
        let Newdata = state?.skulist ? state?.skulist : '';
        if (Newdata) {
          let newarray = [];
          Newdata.forEach((result) => {
            result.product_new = true;
            newarray.push(result);
          });
          let mix_data = Olddata.concat(newarray);
          setEditstocktransferlist(mix_data);
          setOldeditstocktransferlist(mix_data);
          setPrinturl(restransferinventory?.data?.printURL)
        } else {
          setEditstocktransferlist(Olddata);
          setOldeditstocktransferlist(Olddata);

        }
        setForm_note(restransferinventory.data?.note);
        setPrinturl(restransferinventory?.data?.printURL)
        SetdataTable("tbl_editTransfer", "4", newcolumndata, [1, 9, 10, 11]);
        setDatsLodaer(false)
      } else {
        setDatsLodaer(false)
        SetdataTable("tbl_editTransfer", "4", newcolumndata, [1, 9, 10, 11]);
      }
    }
    catch (err) {
      console.log(err, 'err');
    }
  }
  const SaveStocklist = async () => {
    if (!commanSelected_loc) {
      error(t("Location is Required!!"));
      return;
    }
    if (!transactionDate) {
      error(t("Transcation Date is Required!!"));
      return;
    }
    if (!deliveredDate) {
      error(t("Delivered Date is Required!!"))
      return;
    }


    var allcheck = document.querySelectorAll(".editTransfer");
    for (var j = 0; j < allcheck.length; j++) {
      let qty = allcheck[j].getAttribute('data-qty');
      if (parseInt(qty) === 0) {
        let qtycheck = document.querySelectorAll(".qtycheck");
        qtycheck[j].style.border = "1px solid red ";
        return;
      } else {
        let qtycheck = document.querySelectorAll(".qtycheck");
        qtycheck[j].style.border = "1px solid #f4f4f4 ";
      }
    }
    let new_data = [];
    let old_data = [];
    if (editstocktransferlist.length) {
      editstocktransferlist.forEach((result) => {
        if (result?.product_new) {
          new_data.push({ "po_product_id": result.id, "stock_transfer_id": '', "quantity": parseInt(result.po_QTY) });
        } else {
          old_data.push({ "po_product_id": result.id, "stock_transfer_id": '', "quantity": parseInt(result.po_QTY) });
        }
      })
    }
    try {
      const postdata = {
        "transaction_date": transactionDate,
        "delivery_date": deliveredDate,
        "remark": form_note,
        "old_data": old_data,
        "new_data": new_data,
        "deletedata": deletearray,
        "id": state?.stockid ? state?.stockid : ''

      };
      let res = await _Api(postdata, `api/v1/Inventory/stocktransfer/editStockTransferSave`);
      if (res.code === 200) {
        success(t("Created successfully!!"));
        const timer = setTimeout(() => {
          navigate("/app/inventory/stock_transfer");
        }, 1000);
        return () => clearTimeout(timer);
      }
    }
    catch (err) {
      console.log(err, 'err');
    }
  }
  const changeqty = (event, i) => {
    var main = [...editstocktransferlist];
    let oldmain = [...oldeditstocktransferlist];
    if (event.target.value <= oldmain[i].po_QTY) {
      const { value, name } = event.target;
      main[i] = {
        ...main[i],
        [name]: value,
      };
      setEditstocktransferlist(main);
    } else {
      error(t("Enter Quantity less than") + oldmain[i].po_QTY);
    }
  };
  useEffect(() => { }, [deliveredDate]);
  const filterbyname = (search) => {
    Getedittransferinventory(limit, search);
  }
  const deleteIcon = (event) => {
    if (event.target.checked) {
      const values = [...selecteditem, event.target.value];
      setSelecteditem(values);
    } else {
      const result = selecteditem.filter((element) => {
        return element !== event.target.value;
      });
      setSelecteditem(result);
    }
  };
  const deleteitem = () => {
    const selected = document.querySelectorAll(".editTransfer");
    let newdelete = [];
    let deleteArray = [...deletearray];
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].checked) {
        let product_id = selected[i].getAttribute('data-id');
        let productnew = selected[i].getAttribute('data-newproduct');
        selected[i].checked = false;
        if (!productnew) {
          deleteArray.push(product_id);
        } else {
          newdelete.push(product_id);
        }
        setDeletearray(deleteArray);
        setEditstocktransferlist((current) =>
          current.filter((item) => item?.id !== product_id)
        );
      }
    }
    setSelecteditem([]);
    UnSetdataTable('tbl_editTransfer');
    setTimeout(() => {
      SetdataTable("tbl_editTransfer", "4", newcolumndata, [1, 9, 10, 11]);
    }, 100);
  };
  useEffect(() => {
    if (SelectAllStatus) {
      const selected = document.querySelectorAll(".editTransfer");
      let arrya = [];
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].checked) {
          let product_id = selected[i].getAttribute('data-id');
          arrya.push(product_id);
        }
      }
      setSelecteditem(arrya);
    } else {
      setSelecteditem([]);
    }
  }, [SelectAllStatus]);

  useEffect(() => {
    Getedittransferinventory();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    callcolumns();
  }, []);
  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    edittransferlisthead.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (editstocktransferlist.length) {
      editstocktransferlist.forEach((result, key) => {
        bodyarray.push(["", key + 1, result?.main_image, result?.name, result?.SKU, result?.stock_id, result?.metal_name, result?.stone_name
          , result?.size_name, result?.po_QTY, result?.price, result?.amount_total, result?.location_name, result?.customer_name, result?.delivery_date
        ])
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "edit_stock");
  }
  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="main-body-current-day-time">
                <DateTime></DateTime>
              </span>
              <div className="top-heading-area">
                <div className="top-heading-area-left">
                  <Link to="/app/inventory/stock_transfer" className="page-back-btn">
                    <img
                      src={base_assets + "images/icons/arrow-left.png"}
                      alt=""
                    />
                  </Link>
                  <img src={base_assets + "images/icons/home-icon.png"} alt="" />
                  <VoucherList
                    setcommanSelected_vouc={setcommanSelected_vouc}
                    group={"Stock_Transfer"}
                    addclass={true}
                    voucherid={state?.voucher_id ? state?.voucher_id : ''}
                  ></VoucherList>
                </div>
                <h1 className="main-heading text-center danger-text">{t("Edit transfer")}</h1>
              </div>
              <div className="main-body-top-tab-bar create-purchase-top-bar">
                <div className="main-body-top-tab-bar-left-col nav nav-pills"></div>
              </div>
              <div className="main-body-top-inputs-bar">
                <div className="main-body-top-inputs-bar-col-left">
                  <label className="po-num-label  po-num-red">{state?.tf_no ? state?.tf_no : ''}</label>
                  <LocationList addclass={'row-inactive'} setcommanSelected_loc={setcommanSelected_loc} locationid={state?.location_id ? state?.location_id : ''} hidelocationid={''} />
                  <TransAndDeliverDate TransactionDate={transactionDate} DeliveryDate={deliveredDate} setTransactionDate={set_transactionDate} setDeliveryDate={set_deliveredDate} />
                </div>
                <div className="main-body-top-inputs-bar-col-right">
                  <Link
                    to="/app/inventory/inventorylist"
                    className="main-body-top-tab-bar-right-col-a"
                    state={{
                      shipfromid: state?.shipfromid ? state?.shipfromid : '', voucher_id: state?.voucher_id ? state?.voucher_id : '',
                      transitiondate: state?.transitiondate, deliverydate: state?.deliverydate, tf_no: state?.tf_no, location_id: state?.location_id, stock_status: state?.stock_status, stockid: state?.stockid
                    }}
                  >
                    <img
                      src={base_assets + "images/icons/sbi-5.png"}
                      alt=""
                    />
                    <span className="main-body-top-tab-bar-right-col-a-text">
                      {t("Inventory List")}
                    </span>
                  </Link>
                  {state?.stock_status === 2 ?
                    <button onClick={SaveStocklist} className="main-body-top-inputs-bar-col-right-submit-btn com-btn">
                      {t("Save")}
                    </button>
                    : ''
                  }
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className="limit-select short mx-3"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value) }}
                />
                <div className="main-body-top-status-bar-filter">
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t("StockID/SKU/Metal/Stone")}
                    onKeyPress={(e) => e.key === 'Enter' && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button onClick={() => filterbyname(filtername)} className="main-body-top-status-bar-filter-search">
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </button>
                  <div className="print-export-dropdown-selected-icon">
                    <button className={
                      selecteditem.length > 0
                        ? "deletebtn btn-style d-block"
                        : "d-none"
                    } onClick={deleteitem}>
                      <img src={base_assets + "images/icons/delete-icon.png"} alt="" />
                    </button>
                  </div>
                  <div
                    className="main-body-top-status-bar-note-button"
                    data-bs-toggle="modal"
                    data-bs-target="#notesModal"
                  >
                    <img
                      src={base_assets + "images/icons/comment-icon.png"}
                      alt=""
                    />
                    {t("note")}
                  </div>
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={base_assets + "images/icons/ellipsis-circular.png"}
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        onClick={() => {
                          if (printurl) {
                            window.open(printurl, '_blank', 'noreferrer')
                          }
                        }}
                        className={state?.printparmission ? "dropdown-item print-dropdown-item" : "d-none"}
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + 'images/icons/printer-icon.png'}
                            alt=""
                          />
                        </div>
                        <span>{t('print')}</span>
                      </li>
                      <li className="dropdown-item" onClick={() => downloadexclsheet()}>
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/export-x-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("export")}</span>
                      </li>
                      <li className="dropdown-item">
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/import-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("import")}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={base_assets + "images/icons/column-icon.png"}
                            alt=""
                          />
                        </div>
                        <span>{t("column")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="common-sub-table-wrap add-row">
                <div className="main-body-main-table-wrap position-relative">
                  <table
                    id="tbl_editTransfer"
                    className="common-table first-row-gray w-100"
                  >

                    <Tablehead
                      tablehead={edittransferlisthead}
                      tblName={"tbl_edit_transfer_list"}
                      setfilterby={""}
                      setShowoption={setSelectAllStatus}
                    />
                    <tbody>
                      <>
                        {editstocktransferlist ? editstocktransferlist.map((result, key) => {
                          return (
                            <tr key={key}>
                              <td>
                                <div className="com-check">
                                  <input id={result?.id} data-qty={result?.po_QTY} data-newproduct={result?.product_new} onClick={deleteIcon} data-id={result?.id} className="editTransfer edittransfershead" value={result?.id} type="checkbox" />
                                  <label htmlFor={result?.id} className="com-check-label" />
                                </div>
                              </td>
                              <td>{key + 1}</td>
                              <td className="table-pro-img">
                                <img src={result?.main_image ? result?.main_image : base_assets + "images/icon/camera_profile.png"} alt="" />
                              </td>
                              <td className="po-no">
                                {result?.name ? result?.name : ''}
                              </td>
                              <td className="po-no">
                                {result?.SKU ? result?.SKU : ''}
                              </td>
                              {result.status_type === 'stock' ? (
                                <td className="light-blue-text">
                                  {result?.stock_id ? result?.stock_id : ''}
                                </td>
                              ) : result.status_type === 'transit' ? (
                                <td className="status-green-text">
                                  {result?.stock_id ? result?.stock_id : ''}
                                </td>
                              ) : result.status_type === 'warehouse' ? (
                                <td className="status-gray-text">
                                  {result?.stock_id ? result?.stock_id : ''}
                                </td>
                              ) : result.status_type === 'reserve' ? (
                                <td className="yellow-text">
                                  {result?.stock_id ? result?.stock_id : ''}
                                </td>
                              ) : result.status_type === 'repair_order' ? (
                                <td className="purple-text">
                                  {result?.stock_id ? result?.stock_id : ''}
                                </td>
                              ) : (
                                <td className="danger-text">
                                  {result?.stock_id ? result?.stock_id : ''}
                                </td>
                              )}
                              <td>{result?.metal_name ? result?.metal_name : ''}</td>
                              <td>{result?.stone_name ? result?.stone_name : ''}</td>
                              <td>{result?.size_name ? result?.size_name : ''}</td>
                              <td>
                                <div className="col-input input-field-bg">
                                  <input
                                    type="text"
                                    className="qtycheck"
                                    name="po_QTY"
                                    value={result?.po_QTY}
                                    onChange={(e) => changeqty(e, key)}
                                  />
                                </div>
                              </td>
                              <td>{result?.price ? result?.price : ''}</td>
                              <td>{result?.amount_total ? result?.amount_total : ''} </td>
                              <td className="text-center link-color">
                                {result?.location_name ? result?.location_name : ''}
                                <span className="td-sub-text text-danger">
                                  {result?.location_zone ? result?.location_zone : ''}
                                </span>
                              </td>
                            </tr>
                          );
                        }) : ''}
                      </>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={1} className="main-green-text left-0">
                          {selecteditem.length > 0 ? (
                            <span> {selecteditem.length} {t("item Selected")}</span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>{editstocktransferlist ? editstocktransferlist?.reduce(
                          (interation, val) => (interation = interation + parseInt(val.po_QTY)),
                          0
                        ) : ""}</td >
                        <td />
                        <td >{
                          toFormatPrice(
                            editstocktransferlist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                parseFloat(toUnformatPrice(val.amount_total))),
                              0
                            ), { addSymbol: true })
                        }</td>

                        <td />
                      </tr>
                    </tfoot>
                  </table>
                  <div id="table_loader" className={datalodaer ? "" : "d-none"}>
                    <div className="dots">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialModal />
      </div>
      <ConfirmationModel></ConfirmationModel>
      <TableColumnModal tablehead={edittransferlisthead} tblName={"tbl_edit_transfer_list"}
        calldatatable={Getedittransferinventory}
        setnewcolumndata={setnewcolumndata}></TableColumnModal>
      <NotesModalLabel setForm_note={setForm_note} form_note={form_note}></NotesModalLabel>
      <Advancedfiltermodel></Advancedfiltermodel>
    </React.Fragment>
  );
};
export default EditstockTransfer;