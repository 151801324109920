import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminSetting from "../admin/setting/Setting.js"
import CloudSetting from "../admin/setting/CloudSetting.js"
import SmtpEmail from "../admin/setting/SmtpEmail.js"
import SettingImport from "../admin/setting/Import.js"
import SettingExport from "../admin/setting/Export.js"
import SettingLanguage from "../admin/setting/Language.js"
import SettingCurrency from "../admin/setting/Currency.js"
import Integratedapplications from '../admin/setting/Integratedapplications.js';
export default function SettingRoutes(toploadingbarStart) {
    return (
        <Routes>
            <Route exact path='/' element={<AdminSetting toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='cloudsetting' element={<CloudSetting toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='smtpemail' element={<SmtpEmail toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='import' element={<SettingImport toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='export' element={<SettingExport toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='language' element={<SettingLanguage toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='currency' element={<SettingCurrency toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='integratedapplications' element={<Integratedapplications toploadingbarStart={toploadingbarStart} />} > </Route>
        </Routes>
    )
}
