import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SetupDevice from "../admin/setup/Device";
import SetupGeneral from "../admin/setup/General";
import SetupPaymentMethod from "../admin/setup/PaymentMethod.js"
import SetupTaxRules from "../admin/setup/TaxRules.js"
import SetupApplicationKeys from "../admin/setup/ApplicationKeys.js"
import SetupEmail from "../admin/setup/email/Email.js"
import SetupEmailTemplate from "../admin/setup/email/EmailTemplate.js"
import SetupDomain from "../admin/setup/domain/Domain.js"
import QuickStoreSetup from "../admin/setup/quickStoreSetup/QuickStoreSetup.js"
import AdminCashRegister from "../admin/setup/quickStoreSetup/CashRegister.js"
import AdminVoucherType from "../admin/setup/quickStoreSetup/VoucherType.js"
import AdminVoucherTypeDetail from "../admin/setup/quickStoreSetup/VoucherTypeDetail.js"
import { useSelector } from 'react-redux';
export default function SetupRoutes(toploadingbarStart) {
    const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
    return (
        <Routes>
            {parmissiondatashow?.Device?.View &&
                <Route exact path='device' element={<SetupDevice toploadingbarStart={toploadingbarStart} />} > </Route>
            }
            {parmissiondatashow?.gis_setup_general?.View &&
                <Route exact path='general' element={<SetupGeneral toploadingbarStart={toploadingbarStart} />} > </Route>}
            {parmissiondatashow?.gis_setup_payment_method?.View &&
                <Route exact path='paymentmethod' element={<SetupPaymentMethod toploadingbarStart={toploadingbarStart} />} > </Route>}
            {parmissiondatashow?.gis_setup_tax_rules?.View &&
                <Route exact path='taxrules' element={<SetupTaxRules toploadingbarStart={toploadingbarStart} />} > </Route>
            }
            <Route exact path='applicationkeys' element={<SetupApplicationKeys toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='email' element={<SetupEmail toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='email/template' element={<SetupEmailTemplate toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='domain' element={<SetupDomain toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='quickstoresetup' element={<QuickStoreSetup toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='quickstoresetup/cashregister' element={<AdminCashRegister toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='quickstoresetup/vouchertype' element={<AdminVoucherType toploadingbarStart={toploadingbarStart} />} > </Route>
            <Route exact path='quickstoresetup/vouchertypedetail' element={<AdminVoucherTypeDetail toploadingbarStart={toploadingbarStart} />} > </Route>
        </Routes>
    )
}
