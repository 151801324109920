import React, { useRef, useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { _Api } from "../../api/_call";
import { SetdataTable, UnSetdataTable } from "../../api/setdatatable";
import { success, error } from "../../common/helpers/toastify";
import DateTime from "../../common/DateTime";
import TableColumnModal from "../../common/modules/tableColumn";
import ConfirmationModel from "../../common/modules/confirmationmodel";
import { Tablehead } from "../../common/modules/Tablehead";
import Advancedfiltermodel from "../../common/modules/advanced-filter-model";
import { transactionPurchasetablehead } from "../../common/Tablehead";
import { useSelector } from 'react-redux';
import { DateRange } from '../../common/helpers/daterange'
import { purchase_status, status_priority } from "../../common/helpers/status_class";
import Select from "react-select";
import { toFormatPrice, toUnformatPrice, transactionTotals } from "../../common/helpers/function";
import { purchase_product_list } from "../../transaction/subTables/purchase/purchasesubtable";
import ProductImageSliderModal from "../../common/modules/productImageslider";
import ProductModal from "../../common/modules/ProductModal";
import SocialModal from "../../common/modules/SocialModal/SocialModal";
import { Pagination } from "../../common/Pagination";
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'
import { formatDate } from "../../common/helpers/formatDate";
import TableLoader from "../../../admin/common/TableLoader";
const TransactionPurchase = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dataFetched = useRef();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const totalcountsnumber = useSelector((state) => state.app.totalcountsnumber);
  const base_url_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [datefilter, setDatefilter] = useState([]);
  const [newcolumndata, setnewcolumndata] = useState(transactionPurchasetablehead);
  const [limit, setLimit] = useState(100);
  const [filtername, setFiltername] = useState("");
  const [transpurchaselist, setTranspurchaselist] = useState([]);
  const [statement, setStatement] = useState("");
  const [po_sataus, setPo_sataus] = useState([]);
  const [po_priority, setpo_priority] = useState([]);
  const [imageid, setImageid] = useState();
  const [Productmodaldata, setProductmodaldata] = useState([]);
  const [skip, setSkip] = useState()
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
  const [datalodaer, setDatsLodaer] = useState(false)
  const [filterchangestatus, setfilterchangestatus] = useState()
  const colorStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color
      };
    }
  };
  const priorityarray = [
    { label: "low", value: 0, color: "#D9D9D9", class: "low-text" },
    { label: "medium", value: 1, color: "#D2E4FF", class: "medium-text" },
    { label: "high", value: 2, color: "#FFD2D9", class: "high-text" }
  ]
  useEffect(() => {
    callcolumns();
  }, []);

  const callcolumns = async () => {
    try {
      const postdata = {
        name: "tbl_transcation_purchase",
      };
      let columnhead = await _Api(
        postdata,
        `api/v1/Inventory/common/getColumnHeads`
      );
      if (columnhead.code === 200) {
        setnewcolumndata(columnhead?.data?.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const [state, setstate] = useState({
    totalPages: 20,
    currentPage: 1,
  });

  const { totalPages, currentPage } = state;
  const handlePaginations = (current) => {
    setstate({ ...state, currentPage: current });
    Gettranscationpurchase(current, "", "");

  };
  const filter_status = [
    { label: t("PO Outstanding"), labelcount: "outstanding", value: 3, color: "gray-mid" },
    { label: t("Pending for Stock"), labelcount: "pending", value: 2, color: "yellow" },
    { label: t("Approved"), labelcount: "approved", value: 1, color: "main-green" },
    { label: t("Canceled"), labelcount: "cancelled", value: 0, color: "danger" },

  ]
  const filterbyname = (search) => {
    setstate({ ...state, currentPage: 1 });
    Gettranscationpurchase(currentPage, limit, filterchangestatus, search);
  };
  const getlimit = (limit) => {
    setLimit(limit);
    Gettranscationpurchase(1, limit);
    setstate({ ...state, currentPage: 1 });

  };

  const selectOptions = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 }
  ]

  const setSubTable = async (id) => {
    var e = document.querySelector("#table-btn_" + id);
    var row = document.querySelector('#common-main-table-wrap-' + id);

    var classList_ = e.classList;
    if (classList_.contains("rotate-90")) {
      classList_.remove("rotate-90");
      let alllist_data = [...transpurchaselist];
      alllist_data.filter(item => item.id === id).forEach((result) => {
        result.subTabledata = null
      })
      setTranspurchaselist(alllist_data);
    } else {
      classList_.add("rotate-90");
      let alllist_data = [...transpurchaselist];
      const postdata = {
        po_order_id: id,
        show_all: false,
      };
      let prioritydata = await _Api(
        postdata,
        `api/v1/Inventory/purchase/getPuDetails`
      );
      var html_ = purchase_product_list(prioritydata, setImageid, setProductmodaldata, t);
      alllist_data.filter(item => item.id === id).forEach((result) => {
        result.subTabledata = html_
      })
      setTranspurchaselist(alllist_data);
      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-sub-table-wrap-' + id);
        if (rowSub) {
          $(row).after(rowSub);
          clearInterval(checkRowSub);
        }
      }, 100);
    }
  };
  const setSubTablePU = async (id) => {
    var e = document.querySelector("#table-btnpu_" + id);
    var row = document.querySelector('#common-main-table-wrap-' + id);

    var classList_ = e.classList;
    if (classList_.contains("rotate-90")) {
      classList_.remove("rotate-90");
      let alllist_data = [...transpurchaselist];
      alllist_data.filter(item => item.id === id).forEach((result) => {
        result.subTabledata = null
      })
      setTranspurchaselist(alllist_data);
    } else {
      classList_.add("rotate-90");
      let alllist_data = [...transpurchaselist];
      const postdata = {
        po_order_id: id,
        show_all: false,
      };
      let prioritydata = await _Api(
        postdata,
        `api/v1/Inventory/purchase/getPuDetails`
      );
      var html_ = purchase_product_list(prioritydata, setImageid, setProductmodaldata, t);
      alllist_data.filter(item => item.id === id).forEach((result) => {
        result.subTabledata = html_
      })
      setTranspurchaselist(alllist_data);
      var checkRowSub = setInterval(function () {
        var rowSub = document.querySelector('#common-sub-table-wrap-' + id);
        if (rowSub) {
          $(row).after(rowSub);
          clearInterval(checkRowSub);
        }
      }, 100);
    }
  };
  const Changepriority = (index, value, po_order_id) => {
    setStatement(t("Change Priority"));
    setpo_priority([{ index: index, status: value, po_order_id: po_order_id }]);
    var mainarray = [...transpurchaselist];
    mainarray[index]['status'] = {
      ...mainarray[index]['status'],
      'priority': value?.value
    }
    setTranspurchaselist(mainarray);
    document.querySelector("#status_query_select").click();
  };
  const handler_status = async () => {
    try {
      if (po_sataus[0].po_order_id) {
        const postdata = {
          pu_order_id: po_sataus[0].po_order_id,
          status: po_sataus[0].status?.value,
        };
        let statusdata = await _Api(
          postdata,
          `api/v1/Inventory/purchase/editPUStatus`
        );
        if (statusdata?.code === 200) {
          success(t(statusdata?.message));
          Gettranscationpurchase();
        } else {
          error(t(statusdata?.message));
        }
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const handler_priority = async () => {
    try {
      const postdata = {
        pu_order_id: po_priority[0].po_order_id,
        status: po_priority[0].status?.value,
      };
      let prioritydata = await _Api(
        postdata,
        `api/v1/Inventory/purchase/editPriorityStatus`
      );
      if (prioritydata?.code === 200) {
        success(t("Successfully Priority Updated!!"));
        Gettranscationpurchase();
      } else {
        error(t("Something Went Wrong!!"));
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const select_po_status = (index, value, po_order_id) => {
    setStatement("Change Status");
    setPo_sataus([{ index: index, status: value, po_order_id: po_order_id }]);
    document.querySelector("#status_query_select").click();
  };

  const [excuteTable, setexcuteTable] = useState(false);
  const Gettranscationpurchase = useCallback(async (current, firstlimit, filters_status, search) => {
    setexcuteTable(false);
    setfilterchangestatus(filters_status)
    if (filters_status === 3) {
      navigate("/app/inventory/purchaseOrderOutstanding");

    }
    try {
      setDatsLodaer(true)
      setTimeout(async function () {
        UnSetdataTable("tbl_transactionPurchase_list");
        const postdata = {
          "start_date": datefilter ? datefilter[0] : "",
          "end_date": datefilter ? datefilter[1] : "",
          "search": search ? search : "",
          "skip": skip === 0 ? skip : current ? (current - 1) * limit : 0,
          "status": filters_status,
          "limit": firstlimit ? firstlimit : limit
        };

        let restranspurchase = await _Api(
          postdata,
          `api/v1/Inventory/purchase/getList`
        );
        if (restranspurchase?.code === 200) {
          setDatsLodaer(false)
          setexcuteTable(true);
          SetdataTable("tbl_transactionPurchase_list", 5, newcolumndata);
          setstate({
            ...state,
            totalPages: Math.ceil(restranspurchase?.count / postdata.limit),
            currentPage: current ? current : 1
          });
          setTranspurchaselist(restranspurchase?.data);
          transactionTotals();
          dataFetched.current = false;
        } else {
          setDatsLodaer(false)
          SetdataTable("tbl_transactionPurchase_list", 5, newcolumndata);
        }
      }, 100)
    } catch (err) {
      console.log(err, "err");
    }
  }, [newcolumndata, datefilter, limit, skip, state, navigate]);

  useEffect(() => {
    if (datefilter[0] && datefilter[1]) {
      Gettranscationpurchase();
    }
    // eslint-disable-next-line
  }, [datefilter]);

  const downloadexclsheet = async () => {
    let header_values = [];
    let bodyarray = [];
    transactionPurchasetablehead.map(async (r, k) => {
      header_values.push(r?.lable)
    })
    if (transpurchaselist.length) {
      transpurchaselist.forEach((result, key) => {
        bodyarray.push([result?.createdAt, result?.pu_no, result?.po_no, result?.supplier_name, result?.location_name, result?.sku, result?.total_quantity, result?.delivery_date, result?.due_days, result?.total_amount,
        result.status.pu_status === 0 ? "Canceled" :
          result.status.pu_status === 1 ? "Completed" :
            result.status.pu_status === 2 ? "Pending" :
              result.status.pu_status === 3 ? "Approved" : ""
          , result.status.priority === 0 ? "Low" :
          result.status.priority === 1 ? "Medium" : "High",
        result?.note, result?.user_actions?.created_by_name, result?.user_actions?.updated_by_name

        ])
      })
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(bodyarray, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [header_values], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Records');
    const fileContent = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([fileContent], { type: fileType });
    FileSaver.saveAs(data, "purchaselist");
  }
  return (
    <React.Fragment>
      <div className="main-body">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <DateTime></DateTime>
              <h1 className="main-body-heading">{t("Transaction")}</h1>
              <div className="main-body-top-tab-bar">
                <div
                  className="main-body-top-tab-bar-left-col nav nav-pills"
                  role="tablist"
                >
                  <Link
                    className={parmissiondatashow?.purchase_order?.View ? "main-body-top-tab-bar-left-col-a nav-link" : "d-none"}
                    to="/app/inventory"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      {t("Purchase Order")}
                    </span>
                    <span className="num-tab">{totalcountsnumber?.PO?.total ? totalcountsnumber?.PO?.total : "0"}</span>

                  </Link>
                  <Link
                    className="main-body-top-tab-bar-left-col-a nav-link active"
                    to="/app/inventory/transactionPurchase"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      {t("Purchase")}
                    </span>
                    <span className="num-tab" data-bs-toggle="collapse" data-bs-target="#tPurchase">{totalcountsnumber?.PU?.total ? totalcountsnumber?.PU?.total : "0"}</span>
                  </Link>
                  <Link
                    className={parmissiondatashow?.inventory_transaction_stock_Receive?.View ? "main-body-top-tab-bar-left-col-a nav-link" : "d-none"}
                    to="/app/inventory/stock_transfer"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      {t("Stock Transfer")}
                    </span>
                    <span className="num-tab">{totalcountsnumber?.ST?.total ? totalcountsnumber?.ST?.total : "0"}</span>
                  </Link>
                  <Link
                    className={parmissiondatashow?.inventory_transaction_stock_Receive?.View ? "main-body-top-tab-bar-left-col-a nav-link" : "d-none"}
                    to="/app/inventory/transactionStockReceive"
                  >
                    <span className="main-body-top-tab-bar-left-col-a-text">
                      {t("Stock Receive")}
                    </span>
                    <span className="num-tab">{totalcountsnumber?.SR ? totalcountsnumber?.SR : "0"}</span>
                  </Link>
                </div>
                <div className="main-body-top-tab-bar-right-col">
                  <Link to="" className="main-body-top-tab-bar-right-col-a">
                    <img
                      src={base_assets + "images/icons/kanban-icon.svg"}
                      alt=""
                    />
                    <span className="main-body-top-tab-bar-right-col-a-text">
                      {t("KANBAN")}
                    </span>
                  </Link>
                  <Link
                    to="/app/inventory/purchaseOrderOutstanding"
                    className="main-body-top-tab-bar-right-col-a"
                  >
                    <img
                      src={base_assets + "images/icons/outstanding-icon.png"}
                      alt=""
                    />
                    <span className="main-body-top-tab-bar-right-col-a-text">
                      {t("PO Outstanding")}
                    </span>
                    <span className="main-body-top-tab-bar-right-col-a-num">
                      {totalcountsnumber?.PU?.outstanding ? '(' + totalcountsnumber?.PU?.outstanding + ')' : ""}
                    </span>
                  </Link>
                  <span className={parmissiondatashow?.inventory_transaction_purchase?.Add ? "divider" : "d-none"} />
                  <Link
                    to="/app/inventory/createPurchase"
                    className={parmissiondatashow?.inventory_transaction_purchase?.Add ? "main-body-top-tab-bar-right-col-a" : "d-none"}
                  >
                    <img
                      src={base_assets + "images/icons/plus-circle-white.png"}
                      alt=""
                    />
                    <span className="main-body-top-tab-bar-right-col-a-text">
                      {t("Create")}
                    </span>
                  </Link>
                </div>
              </div>
              <div id="tPurchase" className="collapse show">
                <div className="main-body-top-color-bar">
                  {filter_status.map((result, key) => {
                    return (
                      <div className="main-body-top-color-bar-single" onClick={() => {
                        Gettranscationpurchase("", '', result?.value);
                        setSkip(0)
                      }} key={key}>
                        <div className={"main-body-top-color-bar-single-color " + result?.color + "-bg"} />
                        <span className={"main-body-top-color-bar-single-color-status " + result?.color + "-text"}>
                          {result?.label}
                        </span>
                        <span>{totalcountsnumber?.PU?.[`${result?.labelcount}`] ? totalcountsnumber?.PU?.[`${result?.labelcount}`] : "0"}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="main-body-top-status-bar">
                <Select
                  className=" select-set select p-0 main-content-wrapper-body-top-status-bar-select-num"
                  options={selectOptions}
                  value={{ label: limit, value: limit }}
                  onChange={(e) => { setLimit(e.value); getlimit(e.value) }}
                  isSearchable={false}
                />
                <div className="main-body-top-status-bar-filter">
                  <input
                    type="text"
                    value={filtername}
                    className="main-body-top-status-bar-filter-input"
                    placeholder={t("PU No./PO No./Supplier/Ship to/StockID")}
                    onKeyPress={(e) => e.key === "Enter" && filterbyname(e.target.value)}
                    onChange={(e) => setFiltername(e.target.value)}
                  />
                  {filtername ? <img className="main-body-top-status-bar-filter-clear" src={base_assets + 'images/icons/False.png'} onClick={(e) => { setFiltername(""); filterbyname("") }} alt="" /> : ""}
                  <button
                    onClick={() => filterbyname(filtername)}
                    className="main-body-top-status-bar-filter-search"
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                  <Advancedfiltermodel></Advancedfiltermodel>
                </div>
                <div className="main-body-top-status-bar-multi-date-picker">
                  <button className="print-export-dropdown-selected-icon d-none">
                    <img
                      src={base_url_assets + "images/icons/printer-icon.png"}
                      alt=""
                    />
                  </button>
                  <DateRange setDatefilter={setDatefilter} />
                  <div className="main-body-top-status-bar-print-export-dropdown dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={
                          base_url_assets + "images/icons/ellipsis-circular.png"
                        }
                        alt=""
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item" onClick={() => downloadexclsheet()}>
                        <div className="dropdown-item-img">
                          <img
                            src={
                              base_url_assets + "images/icons/export-x-icon.png"
                            }
                            alt=""
                          />
                        </div>
                        <span>{t("export")}</span>
                      </li>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#columnModal"
                      >
                        <div className="dropdown-item-img">
                          <img
                            src={
                              base_url_assets + "images/icons/column-icon.png"
                            }
                            alt=""
                          />
                        </div>
                        <span>{t("column")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="main-body-main-table-wrap position-relative"
                id="table-scroll"
              >
                <table
                  id="tbl_transactionPurchase_list"
                  className="stripe row-border order-column common-table table-striped main-table w-100"
                >

                  <Tablehead
                    tablehead={transactionPurchasetablehead}
                    tblName={"tbl_transcation_purchase"}
                    setfilterby={""}
                  />
                  <tbody>
                    <>
                      {transpurchaselist.length
                        ? transpurchaselist.map((result, key) => {
                          var po_status_class_val = purchase_status(
                            result.status.pu_status
                          );
                          var priority_class_val = status_priority(
                            result.status.priority
                          );
                          var valueofpriority = [];
                          if (result.status.priority === 0) {
                            valueofpriority = [{ label: t("Low"), value: 0, color: "#D9D9D9" }]

                          } else if (result.status.priority === 1) {
                            valueofpriority = [{ label: t("Medium"), value: 1, color: "#D2E4FF" }]
                          } else {
                            valueofpriority = [{ label: t("High"), value: 2, color: "#FFD2D9" }]
                          }
                          var colorsArray = []
                          var valueofstatus = [];
                          if (result.status.pu_status === 1) {
                            valueofstatus = [{ label: t("Approved"), value: result.status.pu_status, color: "#0ECB81" }]
                            colorsArray = [
                              { label: ("Pending"), value: 2, color: "#F9BC44" },

                            ]
                          } else if (result.status.pu_status === 2) {
                            valueofstatus = [{ label: t("Pending"), value: result.status.pu_status, color: "#F9BC44" }]
                            colorsArray = [
                              { label: t("Approved"), value: 1, color: "#0ECB81" },
                              { label: t("Canceled"), value: 0, color: "#FF5757" }
                            ]
                          } else if (result.status.pu_status === 0) {
                            valueofstatus = [{ label: t("Canceled"), value: result.status.pu_status, color: "#FF5757" }]
                          } else if (result.status.pu_status === 3) {
                            valueofstatus = [{ label: t("Completed"), value: result.status.pu_status, color: "#68B8F2" }]
                          }
                          return (
                            <React.Fragment key={key + "po_product_editList"}>
                              <tr id={`common-main-table-wrap-${result?.id}`} key={result?.id}>
                                <td className="fixed-side">
                                  {result?.createdAt ? result?.createdAt : "-"}
                                </td>
                                <td className="po-no">
                                  <Link to={result?.pu_no ? "/app/inventory/editPurchase" : ''} state={{ "product_id": result.id, "location_id": result?.location_id, "supplier_id": result?.supplier_id, "warehouse_id": result?.warehouse_id, "pu_no": result?.pu_no, "Voucher_id": result?.voucher_id, "transactiondate": result?.transaction_date_unformated, "deliverydate": result?.delivery_date_unformated, "purchasestatus": result.status.pu_status, printparmission: parmissiondatashow?.inventory_transaction_purchase?.Print }}>
                                    {result?.pu_no ? result?.pu_no : "-"}
                                  </Link>
                                </td>
                                {result?.po_order_count > 1 ?
                                  <td
                                    className="sku-col"
                                    onClick={() => setSubTablePU(result.id)}
                                    id={"table-btnpu_" + result.id}
                                  >
                                    <img
                                      className="sku-col-arrow"
                                      src={
                                        base_assets +
                                        "/images/icons/right_green_button.png"
                                      }
                                      alt=""
                                    />
                                    <span >{result?.po_order_count}</span>
                                  </td>
                                  :
                                  <td className="po-no">
                                    <Link to={result?.po_no ? "/app/inventory/edit-po-pos" : ''} state={{ posid: result?.po_order_id, po_status: 1, "transactiondate": result?.transaction_date_unformated, "deliverydate": result?.delivery_date_unformated }}>
                                      {result?.po_no ? result?.po_no : "-"}
                                    </Link>
                                  </td>
                                }
                                <td>
                                  {result?.supplier_name
                                    ? result?.supplier_name
                                    : "-"}
                                </td>
                                <td>
                                  {result?.location_name
                                    ? result?.location_name
                                    : "-"}
                                </td>
                                <td
                                  className="sku-col col-highlight"
                                  onClick={() => setSubTable(result.id)}
                                  id={"table-btn_" + result.id}
                                >
                                  <div className="td-icons-wrap">
                                    <div>
                                      <img
                                        className="sku-col-arrow"
                                        src={
                                          base_assets +
                                          "/images/icons/right_green_button.png"
                                        }
                                        alt=""
                                      />
                                      <span>{result?.sku ? result?.sku : "-"}</span>
                                    </div>
                                    {result.allocatable ? <div className="td-icons-wrap-link">
                                      <img
                                        className="img "
                                        src={
                                          base_assets +
                                          'images/icons/link-icon.svg'
                                        }
                                        alt=""
                                      />
                                    </div> : ""}
                                    <div className='tooltip-area-up'>
                                      {result?.pos_Qty !== 0 ? <div className="td-icons-wrap-user"><img
                                        className="img"
                                        src={
                                          base_assets +
                                          'images/icons/gray-user.png'
                                        }
                                        alt=""
                                      /> <span>{result?.pos_Qty}</span>
                                      </div> : ""}
                                      <span className="tooltip-area-up-text">{result?.pos?.customer_name}{result?.pos?.customer_name ? <br /> : ''}{result?.pos?.ref}<br />{formatDate(result?.pos?.delivery_date)}</span>
                                    </div>
                                  </div>
                                </td>
                                <td className="col-highlight">{result?.total_quantity ? result?.total_quantity : "0"}</td>
                                <td className="col-highlight">
                                  {result?.delivery_date
                                    ? result?.delivery_date
                                    : "-"}
                                </td>
                                <td className="col-highlight">
                                  {result?.due_days}
                                </td>
                                <td className="col-highlight">
                                  {result?.total_amount
                                    ? toFormatPrice(result?.total_amount, { addSymbol: true })
                                    : "-"}
                                </td>
                                <td className="col-highlight">
                                  <div
                                    className={
                                      "dropdown status-dropdown status-dropdown-select " +
                                      po_status_class_val
                                    }
                                  >
                                    <Select
                                      options={parmissiondatashow?.inventory_transaction_purchase?.Status ? colorsArray : []}
                                      value={valueofstatus}
                                      isDisabled={result.status.pu_status === 0 ? true : false}
                                      onChange={(event) =>
                                        select_po_status(key, event, result.id)
                                      }
                                      classNamePrefix="status-dropdown-select"
                                      styles={colorStyles}
                                      getOptionLabel={(e) => (
                                        <div className="">
                                          {e.label}
                                        </div>
                                      )}
                                      isSearchable={false}
                                    />
                                  </div>
                                </td>
                                <td className="col-highlight">
                                  <div className={"dropdown status-dropdown status-dropdown-select " + priority_class_val}>
                                    <Select
                                      options={priorityarray}
                                      value={valueofpriority}
                                      onChange={(event) =>
                                        Changepriority(key, event, result.id)
                                      }
                                      classNamePrefix="status-dropdown-select"
                                      styles={colorStyles}
                                      getOptionLabel={(e) => (
                                        <div className={e.class}>
                                          {e.label}
                                        </div>
                                      )}
                                      isSearchable={false}
                                    />
                                  </div>
                                </td>
                                <td className="col-highlight">{result?.note ? result?.note : "-"}</td>
                                <td className="col-highlight">
                                  {result?.user_actions?.created_by_name
                                    ? result?.user_actions?.created_by_name
                                    : "-"}
                                </td>
                                <td className="col-highlight">
                                  {result?.user_actions?.updated_by_name
                                    ? result?.user_actions?.updated_by_name
                                    : "-"}
                                </td>
                              </tr>
                              {
                                excuteTable ? (
                                  <>
                                    {typeof result.subTabledata == "undefined" ? (result.subTabledata = "") : result.subTabledata}
                                  </>
                                ) : null
                              }
                            </React.Fragment>
                          );
                        })
                        : ""}
                    </>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>{transpurchaselist
                        ? transpurchaselist?.reduce(
                          (interation, val) =>
                          (interation =
                            interation + parseInt(val.total_quantity)),
                          0
                        )
                        : ""}</td>
                      <td />
                      <td />
                      <td>
                        {toFormatPrice(
                          transpurchaselist
                            ? transpurchaselist?.reduce(
                              (interation, val) =>
                              (interation =
                                interation +
                                toUnformatPrice(val?.total_amount)),
                              0
                            )
                            : ""
                          , { addSymbol: true })}</td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tfoot>
                </table>
                {datalodaer && <TableLoader />}
              </div>
            </div>
            {transpurchaselist.length > 0 ? (
              <Pagination
                total={totalPages}
                current={currentPage}
                pagination={(crPage) => handlePaginations(crPage)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <SocialModal />
        <ProductModal data_product={Productmodaldata} setOneproduct={setProductmodaldata} editproduct_submit={''} />
        <ProductImageSliderModal
          main_id={imageid}
        ></ProductImageSliderModal>
        <TableColumnModal
          tablehead={transactionPurchasetablehead}
          tblName={"tbl_transcation_purchase"}
          calldatatable={Gettranscationpurchase}
          setnewcolumndata={setnewcolumndata}
        ></TableColumnModal>
        <ConfirmationModel
          statement={statement}
          handler_submit={statement === 'Change Status' ? handler_status : handler_priority}
          handler_dismiss={Gettranscationpurchase}
        ></ConfirmationModel>
        <button
          className="d-none"
          data-bs-toggle="modal"
          data-bs-target="#createPurchaseModal"
          id="status_query_select"
        ></button>
      </div>
    </React.Fragment>
  );
};

export default TransactionPurchase;
