import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import "../catalog.scss";
import CheckoutBlank from '../../checkout/checkoutBlank';
import CheckoutSell from '../../checkout/checkoutSell';
import CheckoutPayment from '../../checkout/checkoutPayment';
import { _Api } from "../../common/api/_call";
import { useSelector } from 'react-redux';
import Itemlist from "../Itemlist";
import CatalogNavbar from '../CatalogNavbar'
import { useTranslation } from 'react-i18next';
const CollectionList = () => {
  const { t } = useTranslation();
  const [isCrown, setCrown] = useState();
  const customer = useSelector((state) => state.app.customer)
  const { state } = useLocation();
  let navigate = useNavigate();
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL;
  const [Navid, setNavid] = useState();
  const [searchsku, setSearchsku] = useState();
  const [showcheckout, setShowcheckout] = useState(localStorage.getItem('showcheckout') || 'blank');
  const [, setdeletestatus] = useState(false)
  const [showpayment, setShowpayment] = useState(false);
  const [UpdatedCart, setUpdatedCart] = useState();
  let checksale = localStorage.getItem('showcheckout') === "sell" ? true : false
  const [searchproductstatus, setsearchproductstatus] = useState(checksale || false);
  const [searchlist, setSearchlist] = useState([]);
  const [Optiondetails, setOptiondetails] = useState(false);
  const crownClick = event => {
    setCrown(current => !current);
    setOptiondetails(current => !current);
  };
  useEffect(() => {
    setCrown(state?.filtertype);
    setOptiondetails(state?.filtertype);
    if (state?.parkid || state?.order_id) {
      GetCartdetailpark();
    }
    // eslint-disable-next-line
  }, [state?.filtertype, state?.parkid, state?.order_id]);
  const GetCartdetailpark = async () => {
    try {
      const postdata = {
        "park_id": state?.parkid,
        "order_id": state?.order_id,
        "order_edit": state?.order_edit,
        "order_type": state?.order_type
      }
      let res = await _Api(postdata, `api/v1/POS/customOrder/getCartItems`)
      if (res.code === 200) {
        setUpdatedCart(res?.data);
        setShowcheckout('sell');
      } else {
        navigate('/app/pos/menu');
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getsearchcatalog = async (search) => {
    try {
      const postdata = {
        "type": Optiondetails === true ? 'catalog' : 'inventory',
        "limit": 0,
        "skip": 0,
        "sale": "top_sale",
        "search": search ? search : "",
        "customer_id": customer?.id,
        "category_id ": Navid ? Navid : state?.navids
      }
      let res = await _Api(postdata, `api/v1/POS/home/search`)
      if (res.code === 200) {
        setSearchlist(res?.data);
      } else {
        setSearchlist([]);
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  useEffect(() => {
    if (searchsku) {
      getsearchcatalog(searchsku)
    }
    // eslint-disable-next-line
  }, [Optiondetails])
  const setproductdata = (productid, name, item) => {
    let data = {
      productid: productid,
      filter: Optiondetails ? true : false,
      itemname: name,
      itemid: item,
      NavId: Navid ? Navid : state?.navids,
      parkid: state?.parkid ? state?.parkid : "",
      order_id: state?.order_id ? state?.order_id : "",
      order_type: state?.order_type ? state?.order_type : "",
      order_edit: state?.order_edit ? state?.order_edit : false,
    }
    let stringify = JSON.stringify(data)
    localStorage.setItem('productdata', stringify);
  }
  useEffect(() => {
    localStorage.setItem('showcheckout', showcheckout);
  }, [showcheckout]);
  return (
    <>
      <div className="main-body">
        <div className="main-body-row">
          <div className="main-body-row-left-col p-0">
            <div className="catalog-area">
              <div className='catalog-area-header-area'>
                <CatalogNavbar setNavid={setNavid} navid={state?.navids} />
                <div className='catalog-area-header-area-control'>
                  <div className='catalog-area-header-area-control-left'>
                    <button className={isCrown ? 'catalog-area-header-area-control-left-btn warehouse' : 'catalog-area-header-area-control-left-btn'} onClick={crownClick}>
                      <img src={isCrown ? base_assets + "images/pos/icons/crown-outline.png" : base_assets + "images/pos/icons/warehouse.png"} alt="" />
                    </button>
                  </div>
                  <div className='catalog-area-header-area-control-right'>
                    <div className="com-search-area">
                      <button className='com-search-area-left-img'>
                        <img src={base_assets + "images/pos/icons/scan-icon.png"} alt="" />
                      </button>
                      <input type="text"
                        onChange={(e) => { setSearchsku(e.target.value); getsearchcatalog(e.target.value) }}
                        placeholder={t("Scan barcode, Search SKU")}
                        id="dropdownMenuButton1" data-bs-toggle="dropdown" autoComplete="off"
                      />
                      <ul className={`dropdown-menu custom-search-dropdown ${searchlist?.length ? 'show' : ''}`} aria-labelledby="dropdownMenuButton1">
                        {searchlist?.length ? searchlist.map((result, key) => {
                          return (
                            <Link
                              to="/app/pos/products/ProductDetails"
                              className="link-style"
                              onClick={() => setproductdata(result?._id, result?.name, result?.Item)}
                              key={key}
                            >
                              <li key={key} className="dropdown-item">
                                {result?.name}<span>{result?.SKU}{result?.stock_id ? '-' + result?.stock_id : ''}
                                </span>
                              </li>
                            </Link>

                          );
                        }) : ""}
                      </ul>
                      <img onClick={getsearchcatalog} className='com-search-area-img'
                        src={base_assets + "images/pos/icons/magnifying-glass.png"} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-content catalog-area-ct">
                <div className={state?.navids || Navid ? "h-100 tab-pane fade show active" : "h-100 tab-pane fade "} id="jalwary">
                  <Itemlist NavId={Navid ? Navid : state?.navids} Optiondetails={Optiondetails} parkid={state?.parkid} order_id={state?.order_id} order_edit={state?.order_edit} order_type={state?.order_type} />
                </div>
              </div>
            </div>
          </div>
          <div className="main-body-row-right-col">
            {showcheckout === 'sell' ? (
              <CheckoutSell
                setShowcheckout={setShowcheckout}
                setUpdatedCart={setUpdatedCart}
                UpdatedCart={UpdatedCart}
                setdeletestatus={setdeletestatus}
                setShowpayment={setShowpayment}
                showpayment={showpayment}
                ordertype={'pos_order'}
                setsearchproductstatus={setsearchproductstatus}
                searchproductstatus={searchproductstatus}
                parkid={state?.parkid}
                order_id={state?.order_id}
                order_edit={state?.order_edit}
                order_type={state?.order_type}
                service_labour_enable={state?.service_labour_enable}
              />
            ) : showcheckout === 'payment' ? (
              <CheckoutPayment UpdatedCart={UpdatedCart} setShowcheckout={setShowcheckout} order_id={state?.order_id} order_edit={state?.order_edit} order_type={state?.order_type} service_enable={state?.service_labour_enable} />
            ) : (
              <CheckoutBlank />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default CollectionList;