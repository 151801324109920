import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DateTime from '../DateTime';
import { _Apiauth } from "../../api/_call";
import { ToastContainer } from "react-toastify";
import "../../styleV3.scss";
import "../../rtlStyleV3.scss";
import "./header.scss";
import TableLoader from '../TableLoader';

const Header = (props) => {
  const [dataNotificationsData, setNotifications] = useState([]);
  const [LanguageList, setLanguageData] = useState(null);
  const [loginData, setLoginData] = useState({ fname: 'user', lname: '' });
  const base_assets = process.env.REACT_APP_BASE_ASSETS_URL
  const [CurrentLan_flg, setLanguageSelectData] = useState('US.png');
  const { t, i18n } = useTranslation();
  const [checkApiCall, setCheckApiCall] = useState(true);
  const [totalNotification, setTotalNotification] = useState(0);
  const [activeNotification, setActiveNotification] = useState('all');
  const dataFetched = useRef()

  useEffect(() => {
    // Function to be called every 10 seconds
    const interval = setInterval(() => {
      // Call your function here
      getNotifications();
    }, 40000); // 10000 milliseconds = 10 seconds

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs only once

  const getUserData = async () => {
    setCheckApiCall(false);
    const postdata = {};
    let profileData = await _Apiauth(postdata, `App/profile/get-auth`);
    if (profileData.code === 200) {
      setLoginData(profileData?.data);
      setLanguageData(profileData?.languageList);
    }

  }


  const getNotifications = useCallback(async () => {
    try {
      const postdata = {};
      let resNotification = await _Apiauth(postdata, `Inventory/common/getNotification`);
      if (resNotification.code === 200) {
        setNotifications(resNotification.data);
        countNotification(resNotification.data);
      }
    } catch (err) {
      console.error(err, 'err');
    }
  }, []);

  const updateNotifications = async (itemid) => {
    try {
      const postdata = {
        "id": itemid,
        "status": 1
      };
      let resNotification = await _Apiauth(postdata, `Inventory/common/updateNotification`);
      if (resNotification.code === 200) {
        getNotifications();
      }
    } catch (err) {
      console.error(err, 'err');
    }
  }

  const rtlView = useCallback((type, l) => {
    localStorage.setItem('UserLang', l);
    setLanguageSelectData(localStorage.getItem('UserLang'));
    let bodyid = document.querySelector('#body_id');
    if (type === 'rtl') {
      bodyid.classList.add('rtl');
    } else {
      bodyid.classList.remove('rtl');
    }
  }, [setLanguageSelectData]);

  useEffect(() => {
    const fetchData = async () => {
      if (checkApiCall) {
        await getUserData();
      }

      if (localStorage.getItem("UserLang")) {
        setLanguageSelectData(localStorage.getItem("UserLang"));
      } else {
        setLanguageSelectData('US.png');
      }

      if (localStorage.getItem("UserLang") === 'SA.png') {
        rtlView('rtl', localStorage.getItem("UserLang"));
      }
      if (dataFetched.current) return;
      dataFetched.current = true
      getNotifications();
    };

    fetchData();
    // eslint-disable-next-line
  }, [checkApiCall, setLanguageSelectData, rtlView]);

  const createMarkup = (html) => {
    return { __html: html };
  };
  const countNotification = (data) => {
    let totalCount = data.filter((item) => item.status === 0)
    setTotalNotification(totalCount.length)
  }

  const handleStoppingDropdownClose = (e) => {
    e.stopPropagation();
  }
  return (
    <>
      <ToastContainer></ToastContainer>
      <header className='main-v3-header d-flex align-items-center justify-content-between bg-white'>
        <div className='main-v3-header-left'>
          <span className='main-v3-header-left-name d-block name'>{t(loginData?.organization)}</span>
          <span className='main-v3-header-left-date d-block name-bottom-text'><DateTime></DateTime> </span>
        </div>
        <div className='main-v3-header-right d-flex align-items-center'>

          <div className="main-v3-header-right-country">
            <div className="dropdown">
              <button className="dropdown-toggle" type="button" id="headercountry" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={base_assets + 'images/flags/' + CurrentLan_flg} alt="icon" /> <span>{t("eu")}</span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="headercountry">
                {LanguageList?.map((LanguageCode, index) => (
                  <li key={index}>
                    <button
                      className="dropdown-item"
                      tabIndex="0"
                      onClick={() => {
                        i18n.changeLanguage(LanguageCode.Lan_value);
                        rtlView(
                          LanguageCode.Lan_value === 'arabic' ? 'rtl' : 'ltr',
                          LanguageCode.Lan_photo
                        );
                      }}
                    >
                      <img
                        src={base_assets + 'images/flags/' + LanguageCode.Lan_photo}
                        alt=""
                      />
                      <span>{LanguageCode.Lan_text}</span>
                    </button>
                  </li>
                ))}
              </ul>

            </div>
          </div>

          <div className=" common-dropdown notification-area">
            <button className="notification-area-btn" type="button" id="notify" onClick={() => getNotifications()} data-bs-toggle="dropdown" aria-expanded="false">
              <i className="fa fa-bell-o" aria-hidden="true"></i>
              {totalNotification > 0 ?
                <span className='notification-area-count'>
                  <span className='notification-area-count-num'>{totalNotification}</span>
                </span>
                : ""}
            </button>
            <div className="dropdown-menu" onClick={(e) => handleStoppingDropdownClose(e)}>
              <div className='notification-area-heading'>
                <div className='d-flex justify-content-between align-items-center'>
                  <span className='d-flex'>{t("Notificationss")} {totalNotification > 0 ? <div className='notification-area-heading-noti'>{t(`${totalNotification}`)}</div> : ""} </span>
                  <div className='notification-area-heading-menuicons cursor-pointer'>
                    <img src={base_assets + 'images/admin/icons/gray-ellipsis.png'} alt="" />
                  </div>
                </div>
              </div>
              <div className='tasksidebar-tabs'>
                <div
                  className="tasksidebar-tabs-inner"
                  role="tablist"
                >
                  <div className={`tasksidebar-tabs-inner-item cursor-pointer ${activeNotification === 'all' ? 'active' : ''}`} onClick={() => setActiveNotification('all')}>
                    <span className="tasksidebar-tabs-inner-item-text">
                      {t("All")}
                    </span>
                  </div>
                  <div
                    className={`tasksidebar-tabs-inner-item cursor-pointer ${activeNotification === 'mentions' ? 'active' : ''}`} onClick={() => setActiveNotification('mentions')}>
                    <span className="tasksidebar-tabs-inner-item-text">
                      {t("Mentions")}
                    </span>
                  </div>
                </div>
              </div>
              <ul className='notification-area-list'>
                {dataNotificationsData?.length ? dataNotificationsData.map((result, key) => {
                  return (
                    <li className={`notification-area-list-single ${result?.status === 0 ? 'unread' : ""}`} key={key} onClick={() => updateNotifications(result?.id)}>
                      <Link target={result?.url ? '_blank' : ""} to={result?.url ? result?.url : '#'} className='notification-area-list-single-a'>

                        <img className='notification-area-list-single-image' src={result?.image} alt="createdbyProfile" />
                        <div className='notification-area-list-single-wrapper'>
                          <span className='notification-area-list-single-text' dangerouslySetInnerHTML={createMarkup(result?.msg)}></span>
                          <div className='notification-area-list-single-foot'>
                            <span className='notification-area-list-single-foot-time'>{result?.time}</span>
                            <span className='notification-area-list-single-foot-user'>
                              {/* <i className="fa fa-user notification-area-list-single-foot-user-icon" aria-hidden="true"></i> */}
                              {result?.created_by}
                            </span>
                          </div>
                        </div>
                      </Link>
                      {result?.status === 0 ? <div className='notification-area-list-single-notificationmark'></div> : ""}
                    </li>
                  )

                }) : <div className='mt-4'><TableLoader /></div>}

              </ul>
              <div className='notification-area-list-bottom'>
                <div className='notification-area-list-bottom-left'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 12L11.9497 16.9497L22.5572 6.34326M2.0498 12.0503L6.99955 17M17.606 6.39355L12.3027 11.6969" stroke={`${totalNotification > 0 ? '#5CC7C1' : '#B3B3B3'}`} strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span className={`${totalNotification > 0 ? '' : 'disable'}`}>{t("Mark all as read")}</span>
                </div>
                <button className='notification-area-list-bottom-right'>
                  {t("View all notifications")}
                </button>
              </div>
            </div>
          </div>

          <div className="main-v3-header-right-user">
            <div className="dropdown">
              <button className="dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">

                <img src={loginData?.myProfile ? loginData?.myProfile : base_assets + "images/icons/user-img.png"} alt="" />
                <div className='text-start fw-normal'>
                  <span className="main-v3-header-right-user-name name d-block">{loginData?.fname + " " + loginData?.lname}</span>
                  <span className="main-v3-header-right-user-name name-bottom-text d-block">{t(loginData?.position)}</span>
                </div>
              </button>
              <ul className="dropdown-menu main-v3-header-right-user-dropdown">
                <li> <Link className='dropdown-menu-single' to="/app/admin/profile">
                  <div className="main-v3-header-right-user-dropdown-img">
                    <i className="fa fa-user-o" aria-hidden="true"></i>
                  </div>
                  <span>{t("Profile")}</span>
                </Link>
                </li>
                <li>
                  <Link className='dropdown-menu-single' to="/logout">
                    <div className="main-v3-header-right-user-dropdown-img">
                      <i className="fa fa-power-off" aria-hidden="true"></i>
                    </div>
                    <span>{t("logout")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>


    </>
  )
}

export default Header;