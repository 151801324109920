import React, { useEffect, useState, useCallback, useRef } from "react";
import { graphqlPost } from "../../api/_call";
import { useTranslation } from 'react-i18next';
import SelectSearch from "react-select-search";
import Select, { components } from "react-select";
const base_assets = process.env.REACT_APP_BASE_ASSETS_URL

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};
export const LocationList = (props) => {
  const { t } = useTranslation();

  const { setcommanSelected_loc, locationid, addclass, hidelocationid, setpo } = props;
  const [LocationList, setLocationList] = useState();
  const [LocationarrayList, setLocationarrayList] = useState([]);
  const getLocationList = useCallback(async () => {
    try {
      const postData = `{
        locations {
          id
          name  
          type         
        }
      }`;
      const response = await graphqlPost(postData);
      let locationArray = [];
      if (response.data?.locations) {
        const filteredLocations = hidelocationid
          ? response.data.locations.filter(item => item?.id !== hidelocationid)
          : response.data.locations;

        locationArray = filteredLocations.map(({ id, name, type }) => ({
          value: id,
          label: name,
          icon: <img src={base_assets + "images/admin/icons/" + type?.toLowerCase() + "-icon.png"} alt="" />,
        }));
      }
      setLocationarrayList(locationArray);
      if (locationid) {
        const selected = locationArray.filter(option => option.value === locationid);
        setLocationList({ label: selected[0]?.label, value: locationid });
        setcommanSelected_loc(locationid);
      }
    } catch (err) {
      console.error(err, "err");
    }
    // eslint-disable-next-line
  }, [locationid]);

  useEffect(() => {
    if (locationid) {
      getLocationList();
    } else {
      getLocationList();
    }
    // eslint-disable-next-line
  }, [locationid]);


  return (
    <React.Fragment>
      <div className={locationid !== 'null' ? addclass ? "placeholder-up-input row-inactive" : "placeholder-up-input" : 'placeholder-up-input'} >
        <Select
          className=" select-set select p-0 z-10"
          placeholder={t("Ship to")}
          options={LocationarrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={LocationList}

          onChange={setpo ? (e) => { setLocationList(e); setcommanSelected_loc(e?.value) } :
            (e) => { setLocationList(e); setcommanSelected_loc(e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(e) => (
            <div className="common-select-icon">{e.icon}
              <span>{e.label}</span></div>
          )}
          isSearchable={false}
        />
      </div>
    </React.Fragment>
  );
};

export const SupplierList = (props) => {
  const { t } = useTranslation();
  const dataFetched = useRef();
  const [SupplierList, setSupplierList] = useState();
  const [SupplierListarray, setSupplierListarray] = useState([]);
  const { setcommanSelected_sup, supplierid, addclass } = props;
  const getSupplierList = useCallback(async () => {
    try {
      const postdata = `{
        suppliers {
          id
          name 
          organization
        }
      }`;
      let responce = await graphqlPost(postdata);
      let supplierarray = [];
      if (responce.data?.suppliers) {
        responce.data?.suppliers?.map((result) => {
          return (
            supplierarray.push(
              {
                value: result?.id,
                label: result?.organization
              }
            )
          )
        });
      }
      setSupplierListarray(supplierarray);
      if (supplierid) {
        const selected = supplierarray.filter(option => option.value === supplierid);
        setSupplierList({ label: selected[0]?.label, value: supplierid });
        setcommanSelected_sup(supplierid);
      }
    } catch (err) {
      console.error(err, "err");
    }
  }, [supplierid, setSupplierListarray, setcommanSelected_sup]);

  useEffect(() => {
    if (supplierid) {
      getSupplierList();
    } else {
      if (dataFetched.current) return;
      dataFetched.current = true;
      getSupplierList();
    }
  }, [getSupplierList, supplierid]);


  return (
    <React.Fragment>
      <div className={addclass ? "placeholder-up-input row-inactive" : "placeholder-up-input"}>
        <Select
          className=" select-set select  p-0"
          placeholder={t("Supplier")}
          options={SupplierListarray}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={SupplierList}
          onChange={(e) => { setSupplierList(e); setcommanSelected_sup(e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={false}
        />
      </div>
    </React.Fragment>
  );
};

export const VoucherList = (props) => {
  const [VoucherList, setVoucherList] = useState([]);
  const [voucher_name, setVoucher_name] = useState();
  const dataFetched = useRef();
  const { setcommanSelected_vouc, group, voucherid, addclass } = props;
  const [voucher_id, setVoucher_id] = useState(voucherid);
  const getVoucherList = useCallback(async () => {
    try {
      const postdata = `{
        vouchers(group: "`+ group + `") {
          id
          name 
      }
        }`;
      let responce = await graphqlPost(postdata);
      var data_arr = [];
      responce.data.vouchers.forEach((result) => {
        var id = responce.data.vouchers[0].id ? responce.data.vouchers[0].id : '';
        if (id) {
          setcommanSelected_vouc(id);
        }
        setVoucher_name(responce.data.vouchers[0].name)
        data_arr.push({ 'name': result.name, 'value': result.id });
      })
      setVoucherList(data_arr);
    } catch (err) {
      console.log(err, "err");
    }
  }, [setcommanSelected_vouc, group]);
  const selectVoucher = (input) => {
    setcommanSelected_vouc(input);
  }
  useEffect(() => {
    if (voucherid) {
      getVoucherList();
    } else {
      if (dataFetched.current) return;
      dataFetched.current = true;
      getVoucherList();
    }
  }, [getVoucherList, voucherid]);
  useEffect(() => {
    setVoucher_id(voucherid);
  }, [voucherid]);
  return (
    <React.Fragment>
      <SelectSearch
        className={addclass ? "dmc-switch  dark-style row-inactive" : "dmc-switch  dark-style"}
        options={VoucherList}
        onChange={selectVoucher}
        value={voucher_id}
        name="language"
        placeholder={voucher_name ? voucher_name : 'no voucher!!'}
      />

    </React.Fragment>
  );
};
export const Saleperson = (props) => {
  const { t } = useTranslation();
  const dataFetched = useRef();
  const [SalesList, setSalesList] = useState();
  const [SalesarrayList, setSalesarrayList] = useState([]);
  const { setcommanSelected_sal } = props;
  const getSalesList = useCallback(async () => {
    try {
      const postdata = `{
          salespersons {
              id
              name 
          }
        }`;

      let responce = await graphqlPost(postdata);
      let Salesarray = [];
      if (responce.data?.salespersons) {
        responce.data?.salespersons?.forEach((result) => {
          Salesarray.push(
            {
              value: result?.id,
              label: result?.name

            }
          );
        })
      };
      setSalesarrayList(Salesarray);
    } catch (err) {
      console.log(err, "err");
    }
  }, [setSalesarrayList]);
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getSalesList();
  }, [getSalesList]);

  return (
    <React.Fragment>
      <div className="placeholder-up-input">
        <Select
          className=" select-set select  p-0"
          placeholder={t("Saleperson")}
          options={SalesarrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={SalesList}
          onChange={(e) => { setSalesList(e); setcommanSelected_sal(e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={false}
        />
      </div>
    </React.Fragment>
  );
};


export const WarehouseList = (props) => {
  const { t } = useTranslation();
  const dataFetched = useRef();
  const { setcommanSelected_warehouse, warehouseid } = props;
  const [warehouseList, setwarehouseList] = useState();
  const [warehousearrayList, setwarehousearrayList] = useState([]);
  const getLocationList = useCallback(async () => {
    try {
      const postdata = `{
            locations (type: "Warehouse") {
                id
                name 
            }
        }`;
      let responce = await graphqlPost(postdata);
      let warehousearray = [];
      if (responce.data?.locations) {
        responce.data?.locations?.forEach((result) => {
          warehousearray.push(
            {
              value: result?.id,
              label: result?.name
            }
          );
        })
      };
      setwarehousearrayList(warehousearray);
      if (warehouseid) {
        const selected = warehousearray.filter(option => option.value === warehouseid);
        setwarehouseList({ label: selected[0].label, value: warehouseid });
        setcommanSelected_warehouse(warehouseid);
      }

    } catch (err) {
      console.log(err, "err");
    }
  }, [setwarehouseList, setcommanSelected_warehouse, warehouseid]);
  useEffect(() => {
    if (warehouseid) {
      getLocationList();
    } else {
      if (dataFetched.current) return;
      dataFetched.current = true;
      getLocationList();
    }
  }, [warehouseid, getLocationList]);

  return (
    <React.Fragment>
      <div className="placeholder-up-input red-highlight-select">
        <Select
          className=" select-set select  p-0"
          placeholder={t("Warehouse")}
          options={warehousearrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={warehouseList}
          onChange={(e) => { setwarehouseList(e); setcommanSelected_warehouse(e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={false}
        />
      </div>
    </React.Fragment>
  );
};

export const StoresList = (props) => {
  const dataFetched = useRef();
  const { setcommanSelected_warehouse, warehouseid } = props;
  const [warehouseList, setwarehouseList] = useState();
  const [warehousearrayList, setwarehousearrayList] = useState([]);
  const getLocationList = useCallback(async () => {
    try {
      const postdata = `{
            locations (type: "Store") {
                id
                name 
            }
        }`;
      let responce = await graphqlPost(postdata);
      let warehousearray = [];
      if (responce.data?.locations) {
        responce.data?.locations?.forEach((result) => {
          warehousearray.push(
            {
              value: result?.id,
              label: result?.name

            }
          );
        })
      };
      setwarehousearrayList(warehousearray);
      if (warehouseid) {
        const selected = warehousearray.filter(option => option.value === warehouseid);
        setwarehouseList({ label: selected[0].label, value: warehouseid });
        setcommanSelected_warehouse(warehouseid);
      }

    } catch (err) {
      console.log(err, "err");
    }
  }, [setwarehouseList, setcommanSelected_warehouse, warehouseid]);


  useEffect(() => {
    if (warehouseid) {
      getLocationList();
    } else {
      if (dataFetched.current) return;
      dataFetched.current = true;
      getLocationList();
    }
  }, [warehouseid, getLocationList]);

  return (
    <React.Fragment>
      <div className="placeholder-up-input">
        <Select
          className=" select-set select  p-0"
          placeholder="Locations"
          options={warehousearrayList}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          value={warehouseList}
          onChange={(e) => { setwarehouseList(e); setcommanSelected_warehouse(e?.value) }}
          classNamePrefix="common-select"
          getOptionLabel={(opt) => opt.label}
          isSearchable={false}
        />
      </div>
    </React.Fragment>
  );
};

export const MultiLocationList = (props) => {
  const dataFetched = useRef();
  const { setMultisetcommanSelected_loc } = props;
  const [MultiLocationList, setMultiLocationList] = useState();
  const [multiLocationarrayList, setMultiLocationarrayList] = useState([]);
  const getLocationList = async () => {
    try {
      const postdata = `{
            locations {
                id
                name 
            }
        }`;
      let responce = await graphqlPost(postdata);
      let multilocationarray = [];
      if (responce.data?.locations) {
        responce.data?.locations?.forEach((result) => {
          multilocationarray.push(
            {
              value: result?.id,
              label: result?.name

            }
          );
        })
      };
      setMultiLocationarrayList(multilocationarray);
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    getLocationList();
  }, []);
  return (
    <React.Fragment>

      <Select
        placeholder="Select Store"
        options={multiLocationarrayList}
        value={MultiLocationList}
        onChange={(e) => { setMultiLocationList(e); setMultisetcommanSelected_loc(e) }}
        classNamePrefix="product-multi-select"
        getOptionLabel={(e) => (
          <div className="product-multi-select__multi-value__label-ct">
            <span>{e.label}</span>
          </div>
        )}
        isSearchable={false}
        isMulti={true}
      />
    </React.Fragment>
  );
}






