import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import RightIcon from '../../common/icons/sidebar/RightIcon';
import { _Api } from '../../api/_call';



export default function Accordion({ item, activeSubtask, index }) {
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState([]);
  const [getActive180, setActive180] = useState(false);
  const [currentRow, setCurrentRow] = useState();

  useEffect(() => {
    setExpandedIndex([0, 1, 2, 3, 4])
  }, []);

  useEffect(() => {
    if (activeSubtask === 'Collapse all') {
      // Closed 
    } else {
      // OPEN ALL  

    }

  }, [activeSubtask])

  const expanded = (row) => {
    let check = expandedRows.filter((item) => item?._id === row._id);
    if (check.length > 0) {
      return true
    } else {
      return false
    }
  }

  const handleCheckedOnchange = (e, row) => {
    setCurrentRow(prev=>prev=row)
      let check = expandedRows.filter((item) => item._id === row._id);
      if (check.length > 0) {
        let newArray = expandedRows.filter((item) => item._id !== row._id);
        setExpandedRows((prev) => [...newArray]);
        setActive180(false);
      } else {
        setExpandedRows((prev) => [...prev, row]);
        setActive180(true)
      }
  }

  const handleSelectAll = (e) => {
    // console.log(e, item.content, expandedRows, activeSubtask, "get your events")
    
    if (e.target.checked) {
      expandRowsSequentially(item.content, 0);
    }else{
      setExpandedRows([])
    }
    }

    const expandRowsSequentially = (rows, index) => {
      if (index < rows.length) {
        // Expand the current row
        handleCheckedOnchange(null, rows[index]);
    
        // Schedule the next row to be expanded after a delay
        setTimeout(() => {
          expandRowsSequentially(rows, index + 1);
        }, 500); // Adjust delay as needed
      }
    }


  const columns = [
    {
      name: <Checkbox checked={expandedRows.length > 0 ? true : false} onChange={(e) => handleSelectAll(e)} />,
      cell: row => (
        <Checkbox
          checked={expanded(row) ? true : false}
          onChange={(e, row) => handleCheckedOnchange(e, row)}
          row={row} // Pass the row data to Checkbox
        />
      ),
      style: {
        maxWidth: '48px',
        minWidth: '48px'
      },
      width: '48px'
    },
    {
      name: 'Order no.',
      selector: row => row.OrderNo,
      grow: 3,
      sortable: true,
      cell: (row) => (
        <div className='sku'>
          {row.OrderNo}
        </div>
      ),
      style: {
        maxWidth: '292px',
        minWidth: '292px'
      },
      minWidth: '292px'
    },
    {
      name: 'SKU',
      selector: row => row.SKU,
      sortable: true,
      expandableRows: true,
      cell: (row) => (
        <div>
          <span>{row.SKU}</span>{expanded(row) ? <span className='skuicons cursor-pointer' onClick={() => handleCheckedOnchange("", row)}> <RightIcon /> </span> : <span className='cursor-pointer rotate-180' onClick={() => handleExpand(row, true)}> <RightIcon /> </span>}
        </div>
      ),
      ignoreRowClick: true, // Prevent row click from triggering expand/collapse
      grow: 1,
      footerContent: (rows) => {
        return (
          <div>Footer Content</div>
        )
      },
    },
    {
      name: 'QTY',
      selector: row => row.QTY,
      footerContent: rows => {
        const total = rows?.reduce((acc, row) => acc + row.QTY, 0);
        return Math.round(total);
      },
      showFooter: true
    },
    {
      name: 'Sale person',
      selector: row => row.salesperson,
      cell: (row) => (
        <div className='saleperson'>
          {row.salesperson?.length > 0 ?
            <>
              <span><img src={row.salesperson?.length > 0 ? row.salesperson[0]?.logo : ''} alt='personimg' /></span>
              <span>{row.salesperson?.length > 0 ? row.salesperson[0]?.name : 'nodata'}</span>
            </>
            : ""
          }
        </div>
      ),
      grow: 2
    },
    {
      name: 'Customer by',
      selector: row => row.customer,
      cell: (row) => (
        <div className='customerby'>
          {row.customer?.length > 0 ?
            <>
              <span><img src={row.customer?.length > 0 ? row.customer[0]?.profile : ''} alt='personimg' /></span>
              <span>{row.customer?.length > 0 ? row.customer[0]?.name : 'nodata'}</span>
            </>
            : ""
          }
        </div>
      ),
      grow: 2
    },
    {
      name: 'Status',
      selector: row => row.status,
      grow: 2,
      cell: (row) => (
        <div style={{ backgroundColor: getBackgroundColor(row), color: '#fff', height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap' }}>
          <span>{getStatusTag(row)}</span>
        </div>
      ),
      style: {
        width: '128px',
        minWidth: '128px'
      },
      width: '128px',
      minWidth: '128px'
    },
    {
      name: 'Create Date',
      selector: row => row.Created_date
    },
    {
      name: 'Due Date',
      selector: row => row.Due_date
    },
    {
      name: 'Location',
      selector: row => row.location,
      grow: 2
    }
  ];

  const getdata = [
    {
      id: "",
      orderno: '',
      sku: '',
      qty: '',
      saleperson: '',
      customerby: '',
      status: '',
      created: '',
      due: '',
      location: ''

    }
  ]

  const getBackgroundColor = (row) => {

    const statuss = row.Status;
    let output = ''
    if (item.title === 'Custom Order' || item.title === 'Repair Order') {
      let statusColor = (status) => {
        switch (status) {
          case 1:
            return '#F9BC44';
          case 2:
            return '#FFC27B';
          case 3:
            return '#72D3CE';
          case 4:
            return '#97BFD9';
          case 5:
            return '#81C5F7';
          case 6:
            return '#F08585';
          case 9:
            return '#d9d9d9';
          default:
            return '';
        }
      }
      output = statusColor(statuss);
    }
    if (item.title === 'Purchase Order') {
      const statusColor = (status) => {
        switch (status) {
          case 0:
            return '#FF5757';
          case 1:
            return '#68b8f2';
          case 2:
            return '#F9BC44';
          case 3:
            return '#72D3CE';
          default:
            return '';
        }
      }
      output = statusColor(statuss);
    }
    if (item.title === 'Appointment') {
      const statusColor = (status) => {
        switch (status) {
          case 0:
            return '#5CC7C1';
          case 1:
            return '#F9BC44';
          case 2:
            return '#FF5757';
          default:
            return 'Unknown status';
        }
      }
      output = statusColor(statuss);
    }
    if (item.title === 'Reserved') {
      const statusColor = (status) => {
        switch (status) {
          case 0:
            return '#FFC27B';
          case 1:
            return '#d9d9d9';
          default:
            return 'Unknown status';
        }
      }
      output = statusColor(statuss);
    }
    return output
  }

  const getStatusTag = (row) => {
    const statuss = row.Status;
    let output = ''
    if (item.title === 'Custom Order' || item.title === 'Repair Order') {
      const statusTag = (status) => {
        switch (status) {
          case 1:
            return 'Open Order';
          case 2:
            return 'Production';
          case 3:
            return 'MGF Stock';
          case 4:
            return 'Transit';
          case 5:
            return 'Stock';
          case 6:
            return 'Sold Out';
          case 9:
            return 'Close Order';
          default:
            return '';
        }
      }
      output = statusTag(statuss);
    }
    if (item.title === 'Purchase Order') {
      const statusTag = (status) => {
        switch (status) {
          case 0:
            return 'Canceled';
          case 1:
            return 'Completed';
          case 2:
            return 'Pending';
          case 3:
            return 'Approved';
          default:
            return '';
        }
      }
      output = statusTag(statuss);
    }
    if (item.title === 'Appointment') {
      const statusTag = (status) => {
        switch (status) {
          case 0:
            return 'Open';
          case 1:
            return 'Close';
          case 2:
            return 'Cancel';
          default:
            return 'Unknown status';
        }
      }
      output = statusTag(statuss);
    }
    if (item.title === 'Reserved') {
      const statusTag = (status) => {
        switch (status) {
          case 0:
            return 'Reserve';
          case 1:
            return 'Close Order';
          default:
            return 'Unknown status';
        }
      }
      output = statusTag(statuss);
    }
    return output
  }

  const totals = item.content?.reduce((acc, val) => {
    acc.totalSKU += val.SKU;
    acc.totalQTY += val.QTY;
    return acc;
  }, { totalSKU: 0, totalQTY: 0 });

  const handleExpand = (row, active180) => {
    setCurrentRow((prev)=>prev=row)
    if (row) {
      // Check if the row is already in the expandedRows
      const isRowExpanded = expandedRows.some((item) => item._id === row._id);
      
      if (isRowExpanded) {
        // Remove the row from expandedRows
        setExpandedRows((prev) => prev.filter((item) => item._id !== row._id));
      } else {
        // Add the row to expandedRows
        setExpandedRows((prev) => [...prev, row]);
      }
      
      // Update active180
      setActive180(active180);
    }
  };

  const toggleItem = (index) => {
    const currentIndex = expandedIndex.indexOf(index);
    let newExpandedIndices = [...expandedIndex];

    if (currentIndex === -1) {
      // Item is not expanded, expand it
      newExpandedIndices.push(index);
    } else {
      // Item is expanded, collapse it
      newExpandedIndices.splice(currentIndex, 1);
      setExpandedRows([])
    }
    setExpandedIndex(newExpandedIndices);
  };

  const customStyles = {
    rows: {
      style: { // Default border color (transparent)
        marginBottom: '2px',
      },
      '&:hover': {
        backgroundColor: '#F6F6F6', // Highlight color on hover
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f0f0f0', // Override the head background color
      },
    },
    checkbox: {
      style: {
        border: '1px solid #E4E4E4',
        backgroundColor: '#3f51b5',
        '&:checked': {
          backgroundColor: '#3f51b5', // Example checkbox color when checked
        },
        '&:hover': {
          cursor: 'pointer', // Example cursor style on hover
        },
      },
    },
    columns: {
      fixedWidthColumn: {
        style: {
          width: '200px', // Fixed width for the specific column
        },
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.index === expandedIndex,
      style: (row) => ({
        borderLeft: `4px solid ${item.color}`,
        marginBottom: '2px'
      }),
    },
    {
      when: (row) => expandedRows.filter((item) => item._id === row._id).length > 0,
      style: (row) => ({
        backgroundColor: '#F6F6F6'
      })
    },
  ];

  const handleRowClicked = (row) => {
    // console.log(row);
  };

  const isRowExpanded = (row) => {
    let check = expandedRows.filter((item) => item._id === row._id);
    if (check.length > 0) {
      return true;
    } else {
      return false
      // if (activeSubtask === 'Collapse all') {
      //   // Closed 
      //   return false
      // } else {
      //   // OPEN ALL  
      //   return true
      // }
  }
  };

  const handleRowChanged = (event) => {
    // console.log(event, "gotcha")
    // const selected = event.selectedRows;
    // setExpandedRows((prev) => [...selected]);
  };

  const footerdata = [
    {
      Created_date: "", Due_date: "", OrderNo: "", QTY: totals?.totalQTY, SKU: totals?.totalSKU, Status: "", customer: [], location: "", salesperson: []
    }]

  return (
    <div className="calendarlist-container-accordion">
      <div key={index} className="calendarlist-container-accordion-accordionitem">
        <div
          className={`calendarlist-container-accordion-accordionitem-accordiontitle ${expandedIndex.includes(index) ? 'expanded' : ''}`}
          onClick={() => toggleItem(index)}
        >
          <div className='calendarlist-container-accordion-accordionitem-accordiontitle-content'><span className={`svg ${expandedIndex.includes(index) ? 'active' : ''}`}> <RightIcon color={item.color} /></span> <span className='title' style={{ color: item.color }}>{item.title}</span> <span className='tasks'>{item?.content?.length > 0 ? `${item?.content?.length} Tasks` : "No Tasks"}</span></div>
        </div>
        <div className={`calendarlist-container-accordion-accordionitem-accordioncontent ${expandedIndex.includes(index) ? 'expanded' : ''}`}>
          <div className={`calendarlist-container-accordion-accordionitem-accordioncontent-wrapper ${item?.content?.length > 0 ? '' : 'nodata'}`} style={{ borderLeft: `4px solid ${item.color}` }}>

            <DataTable
              columns={columns}
              data={item?.content?.length > 0 ? item?.content : getdata}
              expandableRows
              expandableRowExpanded={isRowExpanded}
              onRowClicked={(row)=>setCurrentRow((prev)=>prev=row)}
              expandableRowsComponentProps={{row:currentRow, activeSubtask: activeSubtask, item: item, getActive180: getActive180, expandedRows: expandedRows}}
              expandableRowsComponent={ExpandedComponent}
              expandableRowsHideExpander={true}
              responsive
              selectableRowsHighlight
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
              sortIcon={<GetSortIcon />}
              fixedHeader
              fixedHeaderScrollHeight={item?.content?.length > 0 ? "1000px" : null}
              showFooter
            />
            {/* custom footer */}
            {item?.content?.length > 0 ?
              <div className='footertable'>
                <DataTable
                  columns={columns}
                  data={footerdata}
                  noHeader={true}
                />
              </div>
              : ""}

          </div>
        </div>
      </div>
    </div>
  )
}

export function GetSortIcon() {
  return (
    <svg width="12" height="12" viewBox="-4 -4 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.1079 2.9717C0.885153 3.18826 0.880135 3.54438 1.09669 3.76712C1.31325 3.98986 1.66937 3.99488 1.89211 3.77833L1.1079 2.9717ZM4.2 0.750014H4.7625C4.7625 0.523941 4.62713 0.319844 4.41891 0.231861C4.21067 0.143879 3.96999 0.189119 3.8079 0.346701L4.2 0.750014ZM3.6375 11.25C3.6375 11.5607 3.88935 11.8125 4.2 11.8125C4.51065 11.8125 4.7625 11.5607 4.7625 11.25H3.6375ZM10.8921 9.02829C11.1149 8.81176 11.1199 8.45566 10.9033 8.23291C10.6868 8.01016 10.3307 8.00514 10.1079 8.22174L10.8921 9.02829ZM7.8 11.25H7.2375C7.2375 11.4761 7.37288 11.6802 7.58108 11.7682C7.78935 11.8562 8.03003 11.8109 8.1921 11.6533L7.8 11.25ZM8.3625 0.750014C8.3625 0.439356 8.11065 0.187514 7.8 0.187514C7.48935 0.187514 7.2375 0.439356 7.2375 0.750014H8.3625ZM1.89211 3.77833L4.5921 1.15333L3.8079 0.346701L1.1079 2.9717L1.89211 3.77833ZM3.6375 0.750014V11.25H4.7625V0.750014H3.6375ZM10.1079 8.22174L7.4079 10.8467L8.1921 11.6533L10.8921 9.02829L10.1079 8.22174ZM8.3625 11.25V0.750014H7.2375V11.25H8.3625Z" fill="#A4A4A4" />
    </svg>
  )
}

const ExpandedComponent = ({ row, item, activeSubtask, getActive180, expandedRows }) => {
  // console.log( row, item, "expanded component")
  const [subData, setSubData] = useState([]);
  const [totals, setTotals] = useState({ totalQTY: 0, totalAmount: 0, totalPrice: 0 });

  const getBackgroundColor = (row) => {
    const statuss = row.status;
    let output = ''
    if (item.title === 'Custom Order' || item.title === 'Repair Order') {
      let statusColor = (status) => {
        switch (status) {
          case 1:
            return '#F9BC44';
          case 2:
            return '#FFC27B';
          case 3:
            return '#72D3CE';
          case 4:
            return '#97BFD9';
          case 5:
            return '#81C5F7';
          case 6:
            return '#F08585';
          case 9:
            return '#d9d9d9';
          default:
            return '';
        }
      }
      output = statusColor(statuss);
    }
    if (item.title === 'Purchase Order') {
      const statusColor = (status) => {
        switch (status) {
          case 0:
            return '#FF5757';
          case 1:
            return '#68b8f2';
          case 2:
            return '#F9BC44';
          case 3:
            return '#72D3CE';
          default:
            return '';
        }
      }
      output = statusColor(statuss);
    }
    if (item.title === 'Appointment') {
      const statusColor = (status) => {
        switch (status) {
          case 0:
            return '#5CC7C1';
          case 1:
            return '#F9BC44';
          case 2:
            return '#FF5757';
          default:
            return 'Unknown status';
        }
      }
      output = statusColor(statuss);
    }
    if (item.title === 'Reserved') {
      const statusColor = (status) => {
        switch (status) {
          case 0:
            return '#FFC27B';
          case 1:
            return '#d9d9d9';
          default:
            return 'Unknown status';
        }
      }
      output = statusColor(statuss);
    }
    return output
  }

  const getStatusTag = (row) => {
    const statuss = row.status;
    let output = ''
    if (item.title === 'Custom Order' || item.title === 'Repair Order') {
      const statusTag = (status) => {
        switch (status) {
          case 1:
            return 'Open Order';
          case 2:
            return 'Production';
          case 3:
            return 'MGF Stock';
          case 4:
            return 'Transit';
          case 5:
            return 'Stock';
          case 6:
            return 'Sold Out';
          case 9:
            return 'Close Order';
          default:
            return '';
        }
      }
      output = statusTag(statuss);
    }
    if (item.title === 'Purchase Order') {
      const statusTag = (status) => {
        switch (status) {
          case 0:
            return 'Canceled';
          case 1:
            return 'Completed';
          case 2:
            return 'Pending';
          case 3:
            return 'Approved';
          default:
            return '';
        }
      }
      output = statusTag(statuss);
    }
    if (item.title === 'Appointment') {
      const statusTag = (status) => {
        switch (status) {
          case 0:
            return 'Open';
          case 1:
            return 'Close';
          case 2:
            return 'Cancel';
          default:
            return 'Unknown status';
        }
      }
      output = statusTag(statuss);
    }
    if (item.title === 'Reserved') {
      const statusTag = (status) => {
        switch (status) {
          case 0:
            return 'Reserve';
          case 1:
            return 'Close Order';
          default:
            return 'Unknown status';
        }
      }
      output = statusTag(statuss);
    }
    return output
  }

  useEffect(() => {
    if (activeSubtask === 'Expand all' || getActive180) {
      getData();
    }
    console.log("Call By REACTS");
    // eslint-disable-next-line
  }, [activeSubtask])

  const columns = [
    {
      name: 'No.',
      selector: (row, index) => index + 1,
      style: {
        width: '56px' // Set the width of the column here
      },
      grow: 0
    },
    {
      name: 'Imgae',
      selector: row => row.profile,
      cell: (row) => (
        <img className='subtableimg' src={row?.profile} alt='subtableimg' />
      ),
    },
    {
      name: 'Product Name',
      selector: row => row.ProductName,
      sortable: true,
      grow: 4
    },
    {
      name: 'SKU',
      selector: row => row.sku,
      sortable: true,
      grow: 2
    },
    {
      name: 'Stock ID',
      selector: row => row.stock_id,
    },
    {
      name: 'Metal',
      selector: row => row.metal
    },
    {
      name: 'Stone',
      selector: row => row.stone
    },
    {
      name: 'Size',
      selector: row => row.size
    },
    {
      name: 'QTY',
      selector: row => row.qty
    },
    {
      name: 'Price',
      selector: row => row.price
    },
    {
      name: 'Amont',
      selector: row => row.amount
    },
    {
      name: 'Status',
      selector: row => row.status,
      cell: (row) => (
        <div style={{ backgroundColor: getBackgroundColor(row), color: '#fff', height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span>{getStatusTag(row)}</span>
        </div>
      ),
      grow: 2,
    },
    {
      name: 'Location',
      selector: row => row.location,
      grow: 2,
    }
  ];

  const footercolumns = [
    {
      name: 'No.',
      style: {
        width: '56px' // Set the width of the column here
      },
      grow: 0
    },
    {
      name: 'Imgae',
      selector: row => row.profile,
      cell: (row) => (
        <div></div>
      ),
    },
    {
      name: 'Product Name',
      selector: row => row.ProductName,
      sortable: true,
      grow: 4
    },
    {
      name: 'SKU',
      selector: row => row.sku,
      sortable: true,
      grow: 2
    },
    {
      name: 'Stock ID',
      selector: row => row.stock_id,
    },
    {
      name: 'Metal',
      selector: row => row.metal
    },
    {
      name: 'Stone',
      selector: row => row.stone
    },
    {
      name: 'Size',
      selector: row => row.size
    },
    {
      name: 'QTY',
      selector: row => row.qty
    },
    {
      name: 'Price',
      selector: row => row.price
    },
    {
      name: 'Amont',
      selector: row => row.amount
    },
    {
      name: 'Status',
      selector: row => row.status,
      cell: (row) => (
        <div style={{ backgroundColor: getBackgroundColor(row), color: '#fff', height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span>{getStatusTag(row)}</span>
        </div>
      ),
      grow: 2,
    },
    {
      name: 'Location',
      selector: row => row.location,
      grow: 2,
    }
  ];

  const getData = async () => {
  
    if (row?.OrderNo) {
      let postdata = {
        "ordernumber": row?.OrderNo
      }
      try {
        let res = await _Api(postdata, `api/v1/App/calender/getCalender-orders-infoById`)
        if (res?.code === 200) {
          setSubData(res?.Data);
          const countdata = res?.Data[0]?.reduce((acc, val) => {
            acc.totalAmount += val.amount || 0;
            acc.totalQTY += val.qty || 0;
            acc.totalPrice += val.price || 0;
            return acc;
          }, { totalQTY: 0, totalAmount: 0, totalPrice: 0 });
          setTotals(countdata)
        }
      }
      catch (err) {
        console.log(err, "err")
      }
    }

  }

  const getdataaa = [
    {
      indexing: '',
      image: '',
      productname: '',
      sku: '',
      stock: '',
      metal: '',
      stone: '',
      size: '',
      qty: '',
      price: "",
      amount: '',
      status: '',
      location: ''
    }
  ]

  const footerdata = [
    {
      indexing: '',
      image: '',
      productname: '',
      sku: '',
      stock: '',
      metal: '',
      stone: '',
      size: '',
      qty: totals?.totalQTY,
      price: totals?.totalPrice,
      amount: totals?.totalAmount,
      status: '',
      location: ''
    }
  ]

  const customStyles = {
    header: {
      style: {
        minWidth: '56px !important',
      },

    },

  }

  return (
    <div className='subtable' key={row?._id}>
      {subData[0]?.length > 0 ?
        <>
          <DataTable
            columns={columns}
            data={subData[0]?.length > 0 ? subData[0] : getdataaa}
            fixedHeader // Optional: Message when no data is available
            customStyles={customStyles}
            fixedHeaderScrollHeight={item?.content?.length > 0 ? "2000px" : null}
            sortIcon={<GetSortIcon />}
          />
          <div className='subtablefooter'>
            <DataTable columns={footercolumns} data={footerdata} noHeader={true} />
          </div>
        </>
        :
        <div className='loader-holder'>
          <div id="dark_loader" className="mt-4">
            <div className="dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      }
    </div>

  )
}

const Checkbox = React.forwardRef(({ onChange, checked, row, ...rest }, ref) => {
  return (
    <>
      <input
        type="checkbox"
        className="form-check-input"
        checked={checked}
        onChange={e => onChange(e, row)}
        ref={ref}
        {...rest}
      />
    </>
  );
});