import React from 'react';

const RightArrow = () => {
  return (
    <>
      <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 11.5L6.5 6.5L1.5 1.5" stroke="#B3B3B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </>
  )
}

export default RightArrow;