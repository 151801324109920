import React, { useState } from 'react';
import { Route, Outlet } from 'react-router-dom';
import '../i18n.js'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { useFullScreenHandle } from "react-full-screen";
import Menu from '../pos/menu/menu';
import HeaderPOS from '../pos/common/header/header';
import DuplicateTabMessage from '../pos/menu/duplicateTabMessage';
import MenuCopy from '../pos/menu/MenuCopy';
import CollectionList from '../pos/catalog/collection/collectionList';
import Products from '../pos/catalog/products';
import ProductDetails from '../pos/catalog/productDetails';
import Itemlist from '../pos/catalog/Itemlist';
import CatalogDaimondDetails from '../pos/catalog/daimonddetails';
import CatalogDiamond from '../pos/catalog/catalogDiamond';
import Customer from '../pos/customer/customer';
import PaymentHistory from '../pos/customer/PaymentHistory';
import PurchaseHistory from '../pos/customer/PurchaseHistory';
import PartialPaymentHistory from '../pos/customer/PartialPaymentHistory';
import CreditNote from '../pos/customer/CreditNote';
import Custom from '../pos/Custom/custom';
import CreateYourOwnDetails from '../pos/Custom/createYourOwn/createyourowndetails';
import CustomOrderDelivered from '../pos/Custom/CustomOrderDelivered';
import CreateYourOwn from '../pos/Custom/createYourOwn/createYourOwn';
import CreateYourOwnSetting from '../pos/Custom/createYourOwn/createYourOwnSetting';
import MixAndMatch from '../pos/Custom/MixAndMatch/MixAndMatch';
import MixAndMatchProduct from '../pos/Custom/MixAndMatch/MixAndMatchProduct';
import Mixdiamondsetting from '../pos/Custom/MixAndMatch/Mixdiamondsetting';
import QuotationList from '../pos/Custom/QuotationList';
import DiamondDetailPos from '../pos/Custom/MixAndMatch/DiamondDetail';
import Repair from '../pos/Repair/Repair';
import RepairOrderDelivered from '../pos/Repair/RepairOrderDelivered';
import RepairOrderProcess from '../pos/Repair/RepairOrderProcess';
import Exchange from '../pos/Exchange/Exchange';
import Refund from '../pos/Refund/Refund';
import Reserve from '../pos/Reserve/reserve';
import Diamondreserve from '../pos/Reserve/Diamondreserve';
import ReserveListDiamond from '../pos/Reserve/ReserveListDiamond';
import ReserveProduct from "../pos/Reserve/ReserveProduct"
import ReserveOrder from "../pos/Reserve/ReserveOrder"
import DiamondDetails from "../pos/Reserve/diamonddetails";
import Receive from "../pos/Receive/Receive";
import ReceivePayment from "../pos/Receive/ReceivePayment";
import Deposit from "../pos/Receive/Deposit";
import GiftCard from '../pos/GiftCard/GiftCard';
import Wishlist from "../pos/Wishlist/Wishlist";
import Dailysalereport from "../pos/Report/Dailysalereport"
import Cashregisterreport from "../pos/Report/Cashregisterreport"
import Salepersonreport from "../pos/Report/Salepersonreport"
import Debitreport from "../pos/Report/Debitreport"
import Customerreport from "../pos/Report/Customerreport"
import Reservereport from "../pos/Report/Reservereport"
import Wishlistreport from "../pos/Report/Wishlistreport"
import Exchangereport from "../pos/Report/Exchangereport"
import Depositreport from "../pos/Report/Depositreport"
import CustomerDatails from "../pos/Report/CustomerDatails"
import Refundreport from "../pos/Report/Refundreport"
import Giftcardreport from "../pos/Report/Giftcardreport"
import Creditnotereport from "../pos/Report/Creditnotereport"
import Customorderreport from "../pos/Report/Customorderreport"
import Repairorderreport from "../pos/Report/Repairorderreport"
import Paymentreport from "../pos/Report/Paymentreport"
import Paymentreportdetails from "../pos/Report/Paymentreportdetails"
import Cashregister from "../pos/Report/cashregister/Cashregister"
import Quotation from "../pos/Quotation/Quotation";
import checkoutBlank from '../pos/checkout/checkoutBlank';
import CustomSwitch from '../CustomSwitch.js';
import PageNotFound from '../components/common/404.js';
import Appointment from '../pos/Appointment/Appointment.js';
import useTabMonitor from '../pos/useTabMonitor.js';
const POSReactActive = function ({ children }) {
    const { i18n } = useTranslation();
    const Fullhandle = useFullScreenHandle();
    const [openSidebar, setOpenSidebar] = useState(false);
    const openGISSidebar = () => {
        setOpenSidebar(!openSidebar);
    };
    return (
        <>
            <div className='main-layout-pos'>
                <HeaderPOS Fulhandle={Fullhandle} i18n={i18n} openGISSidebar={openGISSidebar} />
                {children ? children : <Outlet />}
            </div>
        </>
    );
}


export default function PosRoutes() {
    const [isDuplicateTab, setIsDuplicateTab] = useState(false);
    const handleDuplicateTab = () => {
        setIsDuplicateTab(true);
    };
    useTabMonitor(handleDuplicateTab);
    const parmissiondatashow = useSelector((state) => state.app.ApplyParmission);
    const setsettings = useSelector((state) => state.app.generalsettings)
    let generalsettings = setsettings ? setsettings : { data: { generalsetup: { productChoice: "1" } } }
    if (isDuplicateTab) {
        return (
            <POSReactActive>
                <DuplicateTabMessage />
            </POSReactActive>
        );
    } else {
        return (
            <CustomSwitch>
                <Route element={<POSReactActive />}>
                    <Route path='/' element={<MenuCopy />} />
                    <Route path='menu' element={<Menu />} > </Route>
                    {parmissiondatashow?.POS?.View &&
                        <>
                            <Route path='catalog/CollectionList/' element={<CollectionList />} > </Route>
                            {generalsettings?.data?.generalsetup?.productChoice === '1' &&
                                <>
                                    <Route path='catalog/diamonddetail/' element={<CatalogDaimondDetails />} > </Route>
                                    <Route path='products/CatalogDiamond' element={<CatalogDiamond />} > </Route>
                                </>
                            }
                            <Route path='products' element={<Products />} > </Route>
                            <Route path='products/ProductDetails' element={<ProductDetails />} > </Route>
                            <Route path='products/Itemlist' element={<Itemlist />} > </Route>

                        </>
                    }
                    {parmissiondatashow?.pos_customer_reports?.View &&
                        <>
                            <Route path='customer/customer' element={<Customer />}  > </Route>
                            <Route path='customer/paymentHistory' element={<PaymentHistory />} > </Route>
                            <Route path='customer/purchaseHistory' element={<PurchaseHistory />} > </Route>
                            <Route path='customer/PartialPaymentHistory' element={<PartialPaymentHistory />} > </Route>
                            <Route path='customer/creditNote' element={<CreditNote />} > </Route>
                        </>
                    }
                    {parmissiondatashow?.pos_custom_order?.View || parmissiondatashow?.pos_mix_and_match?.View ?
                        <Route path='custom/custom' element={<Custom />} > </Route>
                        : ""}

                    {parmissiondatashow?.pos_custom_order?.View ?
                        <>
                            <Route path='custom/createyourowndetails' element={<CreateYourOwnDetails />} > </Route>
                            <Route path='custom/quotationList' element={<QuotationList />} > </Route>
                            <Route path='custom/customOrderDelivered' element={<CustomOrderDelivered />} > </Route>
                            <Route path='custom/createYourOwn' element={<CreateYourOwn />} > </Route>
                            <Route path='custom/createYourOwnSetting' element={<CreateYourOwnSetting />} > </Route>
                        </>
                        : ""}
                    {parmissiondatashow?.pos_mix_and_match?.View && generalsettings?.data?.generalsetup?.productChoice === '1' &&
                        <>
                            <Route path='custom/mixAndMatch' element={<MixAndMatch />} > </Route>
                            <Route path='custom/mixAndMatchProduct' element={<MixAndMatchProduct />} > </Route>
                            <Route path='custom/mixdiamondsetting' element={<Mixdiamondsetting />} > </Route>
                            <Route path='custom/DiamondDetail' element={<DiamondDetailPos />} > </Route>
                        </>
                    }
                    {parmissiondatashow?.pos_repair_order?.View ?
                        <>
                            <Route path='repair' element={<Repair />} > </Route>
                            <Route path='repair/repairOrderProcess' element={<RepairOrderProcess />} > </Route>
                            <Route path='repair/repairOrderDelivered' element={<RepairOrderDelivered />} > </Route>
                        </>
                        : ""}
                    {parmissiondatashow?.pos_deposit?.View || parmissiondatashow?.Receive?.View ?
                        <Route path="receive" element={<Receive />}></Route>
                        : ""}
                    {parmissiondatashow?.Receive?.View ?
                        <Route path="receive/receivePayment" element={<ReceivePayment />}></Route>
                        : ""}
                    {parmissiondatashow?.pos_deposit?.View ?
                        <Route path="receive/deposit" element={<Deposit />}></Route>
                        : ""}
                    {parmissiondatashow?.pos_reserve?.View ?
                        <>
                            <Route path='reserve' element={<Reserve />} > </Route>
                            <Route path='reserveproduct' element={<ReserveProduct />} > </Route>
                            {generalsettings?.data?.generalsetup?.productChoice === '1' &&
                                <>
                                    <Route path='DiamondDetail' element={<DiamondDetails />} > </Route>
                                    <Route path='reserve/diamondreserve' element={<Diamondreserve />} > </Route>
                                    <Route path='reserve/ReserveListDiamond' element={<ReserveListDiamond />} > </Route>
                                </>}
                            <Route path='reserve/reserveorder' element={<ReserveOrder />} > </Route>
                        </>
                        : ""}
                    {parmissiondatashow?.pos_coupon?.View &&
                        <Route path="giftCard" element={<GiftCard />}></Route>
                    }
                    {parmissiondatashow?.pos_exchange?.View &&
                        <Route path='exchange' element={<Exchange />} > </Route>
                    }
                    {parmissiondatashow?.pos_refund?.View &&
                        <Route path='refund' element={<Refund />} > </Route>
                    }
                    {parmissiondatashow?.WishList?.View &&
                        <Route path="wishlist" element={<Wishlist />}></Route>
                    }
                    {parmissiondatashow?.pos_reports?.View &&
                        <>
                            <Route path="Dailysalereport" element={<Dailysalereport />}></Route>
                            <Route path="Cashregisterreport" element={<Cashregisterreport />}></Route>
                            <Route path="Salepersonreport" element={<Salepersonreport />}></Route>
                            <Route path="Debitreport" element={<Debitreport />}></Route>
                            <Route path="Customerreport" element={<Customerreport />}></Route>
                            <Route path="Reservereport" element={<Reservereport layout={checkoutBlank} />}></Route>
                            <Route path="Wishlistreport" element={<Wishlistreport />}></Route>
                            <Route path="Exchangereport" element={<Exchangereport />}></Route>
                            <Route path="Depositreport" element={<Depositreport />}></Route>
                            <Route path="CustomerDatails" element={<CustomerDatails />}></Route>
                            <Route path="Refundreport" element={<Refundreport />}></Route>
                            <Route path="Giftcardreport" element={<Giftcardreport />}></Route>
                            <Route path="Creditnotereport" element={<Creditnotereport />}></Route>
                            <Route path="Customorderreport" element={<Customorderreport />}></Route>
                            <Route path="Repairorderreport" element={<Repairorderreport />}></Route>
                            <Route path="Paymentreport" element={<Paymentreport />}></Route>
                            <Route path="Paymentreportdetails" element={<Paymentreportdetails />}></Route>
                        </>
                    }
                    {parmissiondatashow?.cash_register?.View &&
                        <Route path="Cashregister" element={<Cashregister />}></Route>
                    }
                    {parmissiondatashow?.Quotation?.View &&
                        <Route path="Quotation" element={<Quotation />}></Route>
                    }
                    <Route path="appointment" element={<Appointment />}></Route>
                    <Route path='*' element={<PageNotFound />} ></Route>

                </Route>
            </CustomSwitch>
        );
    }
}
